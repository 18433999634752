import React from "react";
import { Table } from "semantic-ui-react";
import Moment from "moment";
import { Link } from "react-router-dom";

const CheckClaimRow = props => {
   const { id, case_id, claim_id, created_at, claim_type } = props.claim;
   return (
      <Table.Row key={id}>
         <Table.Cell>
            <Link to={`/app/checknewclaims/claim/${claim_id}/${claim_type}`}>{claim_id}</Link>
         </Table.Cell>
         <Table.Cell>{case_id}</Table.Cell>
         <Table.Cell>{claim_type}</Table.Cell>
         <Table.Cell>{Moment(created_at).format("DD/MM/YYYY HH:mm")}</Table.Cell>
      </Table.Row>
   );
};

export default CheckClaimRow;
