import React, {Component} from 'react';
import { Grid, Segment, Header } from 'semantic-ui-react';

import FormsReportsTypeThree from '../../forms/reports/type_three';
// import ReportTypeThreeResultsDisplayItem from './results_display';

class ReportTypeThree extends Component {
    render() {
        return (
            <Grid.Row>
                <Grid.Column>
                    <Header attached="top" block as="h5">
                        KPI Report Options
                    </Header>
                    <Segment attached>
                        <FormsReportsTypeThree
                            submitGetReportThreeData={this.props.submitGetReportThreeData}
                            user={this.props.user}
                        />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        );
    }
}

export default ReportTypeThree;