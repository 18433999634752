import React from 'react';
import { Formik, Form, Field } from 'formik';

import FormsInputDropdown from '../../inputs/dropdown';
import { Button, Grid } from 'semantic-ui-react';
const statusOptions = [
    // { text: 'Rejected Pending Income (>$1,000)', value: -2 },
    // { text: 'Rejected', value: -1 },
    { text: 'New', value: 0 },
    { text: 'Pre Approved', value: 70 },
    { text: 'Approved (Proceed to Batch)', value: 2 },
    { text: 'Closed', value: 5 },
    // { text: 'Pending Documents', value: 3 },
    // { text: 'Pending Contact From Parents', value: 4 },
    // { text: 'Pending Income Rejection', value: 51 },
    // { text: 'Pending MHC Rejection', value: 52 },
    { text: 'Pre Rejected', value: 71 },
    { text: 'Rejected MHC Review', value: 54 },
    { text: 'Pending Document MHC Review', value: 53 },
];

const FormsClaimsStatus = (props) => {
    return (
        <Formik
            initialValues={{
                status: props.adjNewStatus || 0
            }}
            onSubmit={(values, actions) => {
                props.validateClaimStatusUpdate(values.status);
                props.setNewAjClaimStatus(values.status, props.claimId);
                actions.setSubmitting(false);
            }}
            render={({
                values,
                errors,
                status,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
            }) => (
                <Form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Field
                                    name="status"
                                    options={statusOptions}
                                    label=""
                                    secondaryLabel="Claim Status"
                                    component={FormsInputDropdown}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Button
                                    type="submit" primary disabled={isSubmitting} fluid loading={isSubmitting}
                                >
                                    Select Option and Review Next Steps Below
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            )}
        />
    );
}

export default FormsClaimsStatus;