import React, { Component } from "react";
import { Checkbox } from "semantic-ui-react";

import "react-datepicker/dist/react-datepicker.css";

class FormsInputsSelectAllCheckbox extends Component {
  state = {
    isSelectAll: this.props.field.value
  };

  handleChange() {
    //  console.log("check ---> ", this.props.targetList);
    this.props.form.setFieldValue(
      this.props.field.name,
      !this.props.field.value
    );

    this.props.targetList.forEach((row, index) => {
      if(!row.hasPaid) {
        this.props.form.setFieldValue(
          `${this.props.targetListName}[${index}].${this.props.targetListAttr}`,
          !this.props.field.value
        );
      }
    });
  }

  render() {
    const { name, label, value } = this.props;
    return (
      <Checkbox
        name={name}
        id={name}
        label={label}
        checked={value}
        onChange={this.handleChange.bind(this)}
      />
    );
  }
}

export default FormsInputsSelectAllCheckbox;
