import React, { Component } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import { Button, Grid, Message, Modal } from "semantic-ui-react";
import { Link } from "react-router-dom";

import FormsInputsText from "../../inputs/text";
import FormsInputsSingleCheckbox from "../../inputs/singlecheckbox";
import ReviewPendingRejectedFormSchema from "./validate";
import ClaimsStatusLabel from "../../../claims/status_label";

import Moment from "moment";
import _ from "lodash";

const getMissingContactCase = caseList => {
   const result = [];

   if (caseList) {
      console.log("getMissingContactCase...", caseList)
      caseList.forEach(each => {
         if (!_.isEmpty(each.parent_mobile_no) && _.isEmpty(each.parent_email)) {
            result.push({
               caseId: each.case_id,
               claimId: each.claim_id,
               parentMobileNo: each.parent_mobile_no,
               parentEmail: "" // Adjidicator to be input
            });
         }
      });
   }

   return result;
};

const getBatchClaimsInitialValue = batchClaims => {
   const results = [];
   batchClaims.forEach(claim => {
      const hasPaid = claim.payment_date ? true : false;

      results.push({
         isRejected: false,
         caseId: claim.case_id,
         claimId: claim.claim_id,
         status: claim.claim_status,
         remark: claim.status_remarks,
         moeRejectionReaons: claim.rejection_reasons ? claim.rejection_reasons : "N/A",
         mhcComment: "",
         paymentDate: hasPaid === true ? Moment(claim.payment_date) : null
      });
   });
   return results;
};

class FormsBatchPendingRejectedForm extends Component {
   state = {
      isSuccess: false,
      hasError: false,
      errorMessage: null,
      triggrModal: false,
      missingEmailData: [],
      toBeProcessData: [],
      isSubmitting: false,
      isDisabled: false
   };
   renderFormSubmissionResultMessage = () => {
      if (this.state.isSuccess === true) {
         return <Message positive>Processed Successfully. Please refresh this page to see the changes.</Message>;
      }
      if (this.state.hasError === true) {
         return <Message negative>There was an error processing. {this.state.errorMessage}</Message>;
      }
      return null;
   };
   render() {
      return (
         <React.Fragment>
            <Modal open={this.state.triggrModal}>
               <Modal.Header>Update Parent Email</Modal.Header>
               <Modal.Content image>
                  <Modal.Description>
                     <Formik
                        initialValues={{ missingEmailCaseData: getMissingContactCase(this.props.caseList) }}
                        onSubmit={(values, actions) => {
                           const { accessToken } = this.props.user;
                           this.props.submitPendingRejectedResults(
                              {
                                 updateBatchClaimsData: this.state.toBeProcessData["updateBatchClaimsData"],
                                 missingEmailCaseData: values["missingEmailCaseData"],
                                 accessToken
                              },
                              (result, error) => {
                                 if (result) {
                                    this.setState({
                                       isSuccess: true,
                                       hasError: false,
                                       errorMessage: null
                                    });
                                 }
                                 if (error) {
                                    console.log(error);
                                    actions.setSubmitting(false);
                                    this.setState({
                                       isSuccess: false,
                                       hasError: true,
                                       errorMessage: error.message
                                    });
                                 }
                              }
                           );
                           this.setState({ triggrModal: false });
                        }}
                        render={({
                           values,
                           errors,
                           status,
                           touched,
                           handleBlur,
                           handleChange,
                           handleSubmit,
                           isSubmitting
                        }) => (
                           <Form>
                              <Grid>
                                 <Grid.Row>
                                    <Grid.Column width={4}>Case Id</Grid.Column>
                                    <Grid.Column width={4}>Mobile No.</Grid.Column>
                                    <Grid.Column width={4}>Email</Grid.Column>
                                 </Grid.Row>
                              </Grid>
                              <FieldArray
                                 name="missingEmailCaseData"
                                 render={arrayHelpers => (
                                    <Grid>
                                       {getMissingContactCase(this.props.caseList).map((each, index) => (
                                          <Grid.Row key={index} verticalAlign="middle">
                                             <Grid.Column width={4}>{each.caseId}</Grid.Column>
                                             <Grid.Column width={4}>{each.parentMobileNo}</Grid.Column>
                                             <Grid.Column width={4}>
                                                <Field
                                                   name={`missingEmailCaseData[${index}].parentEmail`}
                                                   label=""
                                                   component={FormsInputsText}
                                                   secondaryLabel=""
                                                />
                                             </Grid.Column>
                                          </Grid.Row>
                                       ))}
                                    </Grid>
                                 )}
                              />
                              <Grid columns={1}>
                                 <Grid.Column>
                                    <Button type="submit">Process</Button>
                                    <Button
                                       onClick={() => {
                                          this.setState({ triggrModal: false, isSubmitting: false });
                                       }}
                                    >
                                       Cancel
                                    </Button>
                                 </Grid.Column>
                              </Grid>
                           </Form>
                        )}
                     />
                  </Modal.Description>
               </Modal.Content>
            </Modal>

            <Formik
               initialValues={{
                  updateBatchClaimsData: getBatchClaimsInitialValue(this.props.batchClaims)
               }}
               isSubmitting={this.state.isSubmitting}
               onSubmit={(values, actions) => {
                  console.log("Pending / Reject", values);
                  this.setState({
                     isSuccess: false,
                     hasError: false,
                     isSubmitting: true,
                     errorMessage: null
                  });

                  if (getMissingContactCase(this.props.caseList).length > 0) {
                     this.setState({ triggrModal: true, toBeProcessData: values });
                  } else {
                     const { accessToken } = this.props.user;
                     this.props.submitPendingRejectedResults(
                        {
                           updateBatchClaimsData: values["updateBatchClaimsData"],
                           missingEmailCaseData: null,
                           accessToken
                        },
                        (result, error) => {
                           if (result) {
                              this.setState({
                                 isSuccess: true,
                                 hasError: false,
                                 errorMessage: null
                              });
                           }
                           if (error) {
                              console.log(error);
                              let errMsg = error.errorMessage || error.message;
                              if (errMsg && typeof errMsg === 'string' && errMsg.toLowerCase().includes('unable to send e-mail')) {
                                 errMsg = errMsg + ' Please make sure the email is correct and active.';
                              }
                              actions.setSubmitting(false);
                              this.setState({
                                 isSuccess: false,
                                 hasError: true,
                                 errorMessage: errMsg
                              });
                           }
                        }
                     );
                  }
               }}
               validationSchema={ReviewPendingRejectedFormSchema}
               render={({ values, errors, status, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
                  <Form>
                     <Grid>
                        <Grid.Row>
                           <Grid.Column width={1} textAlign="center">
                              Reject
                           </Grid.Column>
                           <Grid.Column width={1}>Case Id</Grid.Column>
                           <Grid.Column width={1}>Claim Id</Grid.Column>
                           <Grid.Column width={2}>Status</Grid.Column>
                           <Grid.Column width={1}>Ignore</Grid.Column>
                           <Grid.Column width={4}>Remark</Grid.Column>
                           <Grid.Column width={2}>MOE Remark</Grid.Column>
                           <Grid.Column width={4}>MHC Comment</Grid.Column>
                        </Grid.Row>
                     </Grid>
                     <FieldArray
                        name="updateBatchClaimsData"
                        render={arrayHelpers => (
                           <Grid>
                              {values.updateBatchClaimsData.map((claim, index) => (
                                 <Grid.Row key={index} verticalAlign="middle">
                                    <Grid.Column width={1} textAlign="center">
                                       <Field
                                          name={`updateBatchClaimsData[${index}].isRejected`}
                                          label=""
                                          component={FormsInputsSingleCheckbox}
                                          secondaryLabel=""
                                       />
                                    </Grid.Column>
                                    <Grid.Column width={1}>
                                       <Link to={`/app/database/case/${claim.caseId}`} target="_blank">
                                          {claim.caseId}
                                       </Link>
                                    </Grid.Column>
                                    <Grid.Column width={1}>
                                       <Link to={`/app/database/claim/${claim.claimId}`} target="_blank">
                                          {claim.claimId}
                                       </Link>
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                       <ClaimsStatusLabel status={claim.status} />
                                    </Grid.Column>
                                    <Grid.Column width={1} textAlign="center">
                                       <Field
                                          name={`updateBatchClaimsData[${index}].isDoNotTouch`}
                                          label=""
                                          component={FormsInputsSingleCheckbox}
                                          secondaryLabel=""
                                       />
                                    </Grid.Column>
                                    <Grid.Column width={4}>{claim.remark}</Grid.Column>
                                    <Grid.Column width={2}>{claim.moeRejectionReaons}</Grid.Column>
                                    <Grid.Column width={4}>
                                       <Field
                                          name={`updateBatchClaimsData[${index}].mhcComment`}
                                          label=""
                                          component={FormsInputsText}
                                          secondaryLabel=""
                                          disabled={!claim.isRejected && !claim.isDoNotTouch}
                                       />
                                    </Grid.Column>
                                 </Grid.Row>
                              ))}
                           </Grid>
                        )}
                     />
                     <Grid columns={1}>
                        <Grid.Column>
                           <Button
                              type="submit"
                              color="red"
                              fluid
                              disabled={this.state.isSubmitting}
                              loading={this.state.isSubmitting}
                           >
                              Process Pending / Rejected Claims
                           </Button>
                        </Grid.Column>
                        <Grid.Column>{this.renderFormSubmissionResultMessage()}</Grid.Column>
                     </Grid>
                  </Form>
               )}
            />
         </React.Fragment>
      );
   }
}

export default FormsBatchPendingRejectedForm;
