import React from 'react';
import _ from 'lodash';
import { Button, List, Grid, Tab } from 'semantic-ui-react';

const EobPaymentItemsControl = (props) => {
    return (
        <Tab.Pane>
            <Grid>
                <Grid.Column>
                    <List divided verticalAlign='middle'>
                        {
                            _.map(props.adjPaymentItems, (item) => {
                                return (
                                    <List.Item key={item.uuid} style={{padding: '3% 0'}}>
                                        <List.Content floated='right'>
                                            <Button
                                                onClick={() => props.removePaymentEobItemLocally(item.uuid, props.claimId)}
                                            >
                                                Remove
                                            </Button>
                                        </List.Content>
                                        <List.Content>
                                            <b>{(item.schemeData) ? item.schemeData.description : item.paymentItemId}</b>
                                            <p>
                                                Amount: ${item.amount}
                                                <br />
                                                Remark: {item.remarks}
                                            </p>
                                        </List.Content>
                                    </List.Item>
                                );
                            })
                        }
                    </List>
                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
}

export default EobPaymentItemsControl;