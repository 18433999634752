import React, { Component } from "react";
import { Formik, Form, FastField, FieldArray, ErrorMessage } from "formik";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
   Header,
   Segment,
   Grid,
   Button,
   Input,
   Accordion,
   Icon,
   Item,
   Label,
   Dropdown,
   Message,
   List,
   Image,
   Divider
} from "semantic-ui-react";
import _ from "lodash";
import moment from "moment";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

import FormsInputsText from "../../inputs/text";
import FormsInputsDropdownSearch from "../../inputs/dropdownsearch";
import FormsInputDate from "../../inputs/date";
import CheckNewClaimFormSchema from "./validate";
import { getBanks } from "./constant";

const MySwal = withReactContent(Swal);

const getReceipts = receipts => {
   let result = [];
   receipts.forEach(receipt => {
      result.push({
         id: receipt.id,
         claimId: receipt.claim_id,
         caseId: receipt.case_id,
         hospital: receipt.hospital_name,
         receiptNo: receipt.receipt_no,
         receiptAmount: receipt.receipt_amount,
         visitType: receipt.visit_type,
         dateIncurred: receipt.date_incurred,
         dateIncurredTo: receipt.date_incurred_to,
         receiptAmountCheck: "",
         dateIncurredCheck: null,
         dateIncurredToCheck: null,
         fileName: receipt.file_name,
         // filePath: receipt.file_path,
         attachmentToken: receipt.attachment_token,
         viewUrl: receipt.viewUrl
      });
   });

   return result;
};

class ClaimDetailsForm extends Component {
   state = { activeIndex: 0, activeReceiptIndex: this.props.claimData.receipts[0].id, isSubmitting: false };

   componentDidMount() {
      console.log("sssss: ", this.props.supportDocuments);
   }

   handleClick = (e, titleProps) => {
      const { index } = titleProps;
      const { activeIndex } = this.state;
      const newIndex = activeIndex === index ? -1 : index;

      this.setState({ activeIndex: newIndex });
   };

   handleReceiptClick = (e, titleProps) => {
      const { index } = titleProps;
      const { activeReceiptIndex } = this.state;
      const newIndex = activeReceiptIndex === index ? -1 : index;

      this.setState({ activeReceiptIndex: newIndex });
   };

   handleSubmit = (values, action) => {
      this.setState({ isSubmitting: true }, () => {
         console.log("handleSubmit...", values);
         const newClaimType = this.props.claimType === "New";
         values.newClaimType = newClaimType;
         values.validClaim = this.validateNewClaimDetails(newClaimType, values);
         console.log("validClaim: ", values.validClaim);

         this.props.submitCheckClaim({ values }).then(() => {
            this.setState({ isSubmitting: false });
            this.props.history.push("/app/checknewclaims");
         });
      });
   };

   populateEndorsement(name) {
      const { claimData, claimType } = this.props;

      if (claimType !== "New") {
         return claimData[name] || "";
      } else {
         return "";
      }
   }

   isNotEqualDetails(detail1, detail2) {
      console.log("isNotEqualDetails: ", detail1, detail2);
      if (!detail1) {
         return true;
      }
      if (!detail2) {
         return true;
      }
      return detail1.trim().toLowerCase() !== detail2.trim().toLowerCase();
   }

   validateNewClaimDetails(newClaimType, values) {
      const claimData = this.props.claimData;
      const particularOfInsured = this.props.claimData.particularOfInsured[0];

      if (this.isNotEqualDetails(values.insuredName, particularOfInsured.name)) {
         return false;
      } else if (this.isNotEqualDetails(values.insuredNRIC, particularOfInsured.nric)) {
         return false;
      } else if (newClaimType && moment(values.insuredDOB).format("YYYY-MM-DD") !== particularOfInsured.dob) {
         return false;
      } else if (this.isNotEqualDetails(values.payeeName, claimData.payee_name)) {
         return false;
      } else if (this.isNotEqualDetails(values.payeeNRIC, claimData.payee_nric)) {
         return false;
      } else if (newClaimType && this.isNotEqualDetails(values.school, particularOfInsured.school)) {
         return false;
      } else if (newClaimType && this.isNotEqualDetails(values.postcode, particularOfInsured.postcode)) {
         return false;
      } else if (newClaimType && this.isNotEqualDetails(values.houseNo, particularOfInsured.house_no)) {
         return false;
      } else if (newClaimType && this.isNotEqualDetails(values.streetName, particularOfInsured.road_name)) {
         return false;
      } else if (newClaimType && this.isNotEqualDetails(values.buildingName, particularOfInsured.building_name)) {
         return false;
      } else if (
         newClaimType &&
         particularOfInsured.parent_email &&
         values.parentEmail !== particularOfInsured.parent_email
      ) {
         return false;
      } else if (
         values.paymentMode === "Direct credit to bank account (GIRO)" &&
         this.isNotEqualDetails(values.accountNo, particularOfInsured.account_no)
      ) {
         return false;
      } else if (
         newClaimType &&
         values.radioEndorseDecline !== "0" &&
         this.isNotEqualDetails(values.adminName, claimData.certified_by_admin)
      ) {
         return false;
      } else if (
         newClaimType &&
         values.radioEndorseDecline !== "0" &&
         this.isNotEqualDetails(values.adminEmail, claimData.certified_by_admin_email)
      ) {
         return false;
      } else if (
         newClaimType &&
         values.radioEndorseDecline !== "0" &&
         this.isNotEqualDetails(values.adminMobileNo, claimData.certified_by_admin_mobile_no)
      ) {
         return false;
      } else if (this.receiptDetailsMismatch(values.receipts)) {
         return false;
      } else {
         return true;
      }
   }

   receiptDetailsMismatch(receipts) {
      const receiptCheck = receipts.some(receipt => {
         const dateIncurred = moment(receipt.dateIncurred).toDate();
         const dateIncurredCheck = moment(receipt.dateIncurredCheck).toDate();

         const dateIncurredTo = moment(receipt.dateIncurredTo).toDate();
         const dateIncurredToCheck = moment(receipt.dateIncurredToCheck).toDate();

         dateIncurred.setHours(0, 0, 0, 0);
         dateIncurredCheck.setHours(0, 0, 0, 0);
         dateIncurredTo.setHours(0, 0, 0, 0);
         dateIncurredToCheck.setHours(0, 0, 0, 0);

         return (
            dateIncurred.getTime() !== dateIncurredCheck.getTime() ||
            dateIncurredTo.getTime() !== dateIncurredToCheck.getTime() ||
            parseInt(receipt.receiptAmount) !== parseInt(receipt.receiptAmountCheck)
         );
      });

      return receiptCheck;
   }

   prepareSchoolDropdownSelection = list => {
      return _.map(list, item => {
         return { text: item.school_name, value: item.school_name };
      });
   };
   getBankNameByBankCode = bankCode => {
      return _.filter(getBanks(), bank => {
         return bank.value === bankCode;
      }).map(bank => {
         return bank.text;
      });
   };
   getBankBranchByBankCode = bankCode => {
      return _.filter(getBanks(), bank => {
         return bank.value === bankCode;
      }).map(bank => {
         return bank.branch || null;
      });
   };
   getSchoolNameFromSchool = schoolName => {
      return _.filter(this.props.schools, school => {
         return school.school_name === schoolName;
      }).map(school => {
         return school.school_name;
      });
   };
   getAddressFromSchool = schoolName => {
      return _.filter(this.props.schools, school => {
         return school.school_name === schoolName;
      }).map(school => {
         return school.address_1 + school.address_2 + school.address_3;
      });
   };
   getZoneFromSchool = schoolName => {
      return _.filter(this.props.schools, school => {
         return school.school_name === schoolName;
      }).map(school => {
         return school.zone;
      });
   };
   getAdminNameBySchool = schoolName => {
      return _.filter(this.props.schools, school => {
         return school.school_name === schoolName;
      }).map(school => {
         return school.main_person_incharge;
      });
   };
   getAdminEmailBySchool = schoolName => {
      return _.filter(this.props.schools, school => {
         return school.school_name === schoolName;
      }).map(school => {
         return school.main_email;
      });
   };
   getAdminMobileNoBySchool = schoolName => {
      return _.filter(this.props.schools, school => {
         return school.school_name === schoolName;
      }).map(school => {
         return school.main_contact_no;
      });
   };
   populateAddress(form, postcode) {
      if (postcode.length === 6) {
         let url =
            "https://developers.onemap.sg/commonapi/search?searchVal=" + postcode + "&returnGeom=Y&getAddrDetails=Y";
         fetch(url)
            .then(res => res.json())
            .then(res => {
               if (res.found > 0) {
                  let filterHDB_BLK_NO = res.results[0].BLK_NO.includes("HDB-")
                     ? res.results[0].BLK_NO.split("HDB-")[1]
                     : res.results[0].BLK_NO;

                  let filterHDB_ROAD_NAME = res.results[0].ROAD_NAME.includes("HDB-")
                     ? res.results[0].ROAD_NAME.split("HDB-")[1]
                     : res.results[0].ROAD_NAME;

                  let filterHDB_BUILDING =
                     res.results[0].BUILDING === "NIL"
                        ? ""
                        : res.results[0].BUILDING.includes("HDB-")
                        ? res.results[0].BUILDING.split("HDB-")[1]
                        : res.results[0].BUILDING;

                  form.setFieldValue("houseNo", filterHDB_BLK_NO);
                  form.setFieldValue("streetName", filterHDB_ROAD_NAME);
                  form.setFieldValue("buildingName", filterHDB_BUILDING);
               } else {
                  form.setFieldValue("houseNo", "");
                  form.setFieldValue("streetName", "");
                  form.setFieldValue("buildingName", "");
               }
            })
            .catch(err => console.log(err));
      } else {
         form.setFieldValue("houseNo", "");
         form.setFieldValue("streetName", "");
         form.setFieldValue("buildingName", "");
      }
   }
   render() {
      const { activeIndex, activeReceiptIndex } = this.state;
      const schools = this.prepareSchoolDropdownSelection(this.props.schools);
      const banks = getBanks();

      let initialValues = {
         claimType: this.props.claimType,
         caseId: this.props.caseId,
         claimId: this.props.claimId,
         insuredName: "",
         insuredNRIC: "",
         insuredDOB: null,
         school: "",
         postcode: "",
         houseNo: "",
         streetName: "",
         unitNo: "",
         buildingName: "",
         parentEmail: "",
         paymentMode: this.props.claimData.payment_mode,
         bankName: "",
         bankCode: "",
         accountNo: "",
         bankBranch: "",
         payeeName: "",
         payeeNRIC: "",
         schoolName: "",
         schoolAddress: "",
         schoolZone: "",
         adminName: "",
         adminEmail: "",
         adminMobileNo: "",
         radioEndorseDecline: this.props.claimData.endorsement_status,
         receipts: getReceipts(this.props.receipts),
         createdBy: this.props.user.id
      };

      return (
         <Formik
            initialValues={initialValues}
            onSubmit={this.handleSubmit}
            validationSchema={CheckNewClaimFormSchema}
            validateOnChange={true}
            validateOnBlur={true}
            render={({
               form,
               values,
               errors,
               status,
               touched,
               handleBlur,
               handleChange,
               handleSubmit,
               isSubmitting
            }) => (
               <div>
                  <Form>
                     <Header as="h3" block attached="top">
                        {values.claimType === "New" ? "New Claim " : "Follow Up Claim"} Details
                     </Header>
                     <Segment attached>
                        <Accordion fluid styled>
                           <React.Fragment>
                              <Accordion.Title
                                 className="text-center"
                                 active={activeIndex === 0}
                                 index={0}
                                 onClick={this.handleClick}
                              >
                                 <Header>
                                    <Icon name="dropdown" />
                                    Claim Details
                                 </Header>
                              </Accordion.Title>
                              <Accordion.Content active={activeIndex === 0}>
                                 <Grid columns="equal">
                                    <Grid.Row>
                                       <Grid.Column>
                                          <FastField
                                             name="insuredName"
                                             label=""
                                             component={FormsInputsText}
                                             secondaryLabel="Insured Name"
                                          />
                                       </Grid.Column>
                                       <Grid.Column>
                                          <FastField
                                             name="insuredNRIC"
                                             label=""
                                             component={FormsInputsText}
                                             secondaryLabel="Insured NRIC, FIN or Passport Number"
                                          />
                                       </Grid.Column>
                                       {values.claimType === "New" ? (
                                          <Grid.Column>
                                             <FastField
                                                name="insuredDOB"
                                                label=""
                                                secondaryLabel="Insured Date of Birth"
                                                component={FormsInputDate}
                                             />
                                          </Grid.Column>
                                       ) : null}
                                    </Grid.Row>

                                    <Grid.Row>
                                       {values.claimType === "New" ? (
                                          <Grid.Column>
                                             <FastField
                                                name="school"
                                                label=""
                                                component={FormsInputsDropdownSearch}
                                                secondaryLabel="Name of School/Centre"
                                                options={schools}
                                             />
                                          </Grid.Column>
                                       ) : null}
                                       <Grid.Column>
                                          <FastField
                                             name="parentEmail"
                                             label=""
                                             component={FormsInputsText}
                                             secondaryLabel="Email (Parent/Guardian)"
                                          />
                                       </Grid.Column>
                                       <Grid.Column />
                                    </Grid.Row>
                                    <Divider horizontal>Address</Divider>
                                    <Grid.Row>
                                       <Grid.Column>
                                          <FastField
                                             name="postcode"
                                             render={({ field, form }) => (
                                                <div className="form-input-group">
                                                   <div>
                                                      <Header.Subheader>Postal Code</Header.Subheader>
                                                   </div>
                                                   <Input
                                                      fluid
                                                      {...field}
                                                      onChange={(e, value) => {
                                                         form.setFieldValue("postcode", value.value);
                                                         this.populateAddress(form, value.value);
                                                      }}
                                                   />
                                                   {form.errors[field.name] && form.touched[field.name] && (
                                                      <Message negative>{errors[field.name]}</Message>
                                                   )}
                                                </div>
                                             )}
                                          />
                                       </Grid.Column>
                                       <Grid.Column>
                                          <FastField
                                             name="houseNo"
                                             label=""
                                             component={FormsInputsText}
                                             secondaryLabel="Block / House Number"
                                          />
                                       </Grid.Column>
                                       <Grid.Column>
                                          <FastField
                                             name="streetName"
                                             label=""
                                             component={FormsInputsText}
                                             secondaryLabel="Street Name"
                                          />
                                       </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                       <Grid.Column>
                                          <FastField
                                             name="unitNo"
                                             label=""
                                             component={FormsInputsText}
                                             secondaryLabel="Unit No."
                                          />
                                       </Grid.Column>
                                       <Grid.Column>
                                          <FastField
                                             name="buildingName"
                                             label=""
                                             component={FormsInputsText}
                                             secondaryLabel="Building Name"
                                          />
                                       </Grid.Column>
                                       <Grid.Column />
                                    </Grid.Row>
                                    <Divider horizontal>Payment Details</Divider>
                                    {values.paymentMode === "Direct credit to bank account (GIRO)" ? (
                                       <Grid.Row>
                                          <Grid.Column>
                                             <FastField
                                                name="bankCode"
                                                value={values.bankCode}
                                                render={({ field, form }) => (
                                                   <React.Fragment>
                                                      <div>
                                                         <Header.Subheader>Name of Bank</Header.Subheader>
                                                      </div>
                                                      <Dropdown
                                                         {...field}
                                                         fluid
                                                         search
                                                         selection
                                                         options={banks}
                                                         value={field.value}
                                                         onBlur={(e, action) => {
                                                            form.setFieldValue("bankCode", action.value);
                                                            form.setFieldValue(
                                                               "bankName",
                                                               this.getBankNameByBankCode(action.value)
                                                            );
                                                            form.setFieldValue(
                                                               "bankBranch",
                                                               this.getBankBranchByBankCode(action.value)
                                                            );
                                                         }}
                                                         onChange={(e, action) => {
                                                            form.setFieldValue("bankCode", action.value);
                                                            form.setFieldValue(
                                                               "bankName",
                                                               this.getBankNameByBankCode(action.value)
                                                            );
                                                            form.setFieldValue(
                                                               "bankBranch",
                                                               this.getBankBranchByBankCode(action.value)
                                                            );
                                                         }}
                                                      />
                                                      {form.errors[field.name] && form.touched[field.name] && (
                                                         <Message negative>{errors[field.name]}</Message>
                                                      )}
                                                   </React.Fragment>
                                                )}
                                             />
                                          </Grid.Column>
                                          <Grid.Column>
                                             <FastField
                                                name="accountNo"
                                                label=""
                                                component={FormsInputsText}
                                                secondaryLabel="Account No."
                                             />
                                          </Grid.Column>
                                          <Grid.Column>
                                             <FastField
                                                name="bankBranch"
                                                label=""
                                                component={FormsInputsText}
                                                secondaryLabel="Branch Code"
                                             />
                                          </Grid.Column>
                                       </Grid.Row>
                                    ) : null}
                                    <Grid.Row>
                                       <Grid.Column>
                                          <FastField
                                             name="payeeName"
                                             label=""
                                             component={FormsInputsText}
                                             secondaryLabel="Name of Payee"
                                          />
                                       </Grid.Column>
                                       <Grid.Column>
                                          <FastField
                                             name="payeeNRIC"
                                             label=""
                                             component={FormsInputsText}
                                             secondaryLabel="Payee NRIC, FIN or Passport Number"
                                          />
                                       </Grid.Column>
                                       <Grid.Column />
                                    </Grid.Row>
                                    {values.radioEndorseDecline !== "0" && values.claimType === "New" ? (
                                       <React.Fragment>
                                          <Divider horizontal>Endorsed School Details</Divider>
                                          <Grid.Row>
                                             <Grid.Column>
                                                <FastField
                                                   name="schoolName"
                                                   value={values.schoolName}
                                                   render={({ field, form }) => (
                                                      <React.Fragment>
                                                         <div>
                                                            <Header.Subheader>Name of School/Centre</Header.Subheader>
                                                         </div>
                                                         <Dropdown
                                                            {...field}
                                                            fluid
                                                            search
                                                            selection
                                                            options={schools}
                                                            value={field.value}
                                                            onBlur={(e, action) => {
                                                               form.setFieldValue(
                                                                  "schoolName",
                                                                  this.getSchoolNameFromSchool(action.value)[0]
                                                               );
                                                               form.setFieldValue(
                                                                  "adminName",
                                                                  this.getAdminNameBySchool(action.value)[0]
                                                               );
                                                               form.setFieldValue(
                                                                  "adminEmail",
                                                                  this.getAdminEmailBySchool(action.value)[0]
                                                               );
                                                               form.setFieldValue(
                                                                  "adminMobileNo",
                                                                  this.getAdminMobileNoBySchool(action.value)[0]
                                                               );
                                                            }}
                                                            onChange={(e, action) => {
                                                               form.setFieldValue("schoolName", action.value);
                                                               form.setFieldValue(
                                                                  "adminName",
                                                                  this.getAdminNameBySchool(action.value)[0]
                                                               );
                                                               form.setFieldValue(
                                                                  "adminEmail",
                                                                  this.getAdminEmailBySchool(action.value)[0]
                                                               );
                                                               form.setFieldValue(
                                                                  "adminMobileNo",
                                                                  this.getAdminMobileNoBySchool(action.value)[0]
                                                               );
                                                            }}
                                                         />
                                                      </React.Fragment>
                                                   )}
                                                />
                                             </Grid.Column>

                                             <Grid.Column>
                                                <div>
                                                   <Header.Subheader>Address of School/Centre</Header.Subheader>
                                                </div>
                                                <Input
                                                   disabled
                                                   value={this.getAddressFromSchool(values.schoolName)}
                                                   className="w-100"
                                                />
                                             </Grid.Column>
                                             <Grid.Column>
                                                <div>
                                                   <Header.Subheader>Zone</Header.Subheader>
                                                </div>
                                                <Input
                                                   disabled
                                                   value={this.getZoneFromSchool(values.schoolName)}
                                                   className="w-100"
                                                />
                                             </Grid.Column>
                                          </Grid.Row>
                                          <Grid.Row>
                                             <Grid.Column>
                                                <FastField
                                                   name="adminName"
                                                   label=""
                                                   component={FormsInputsText}
                                                   secondaryLabel="Name of Authorised Staff of School/Centre"
                                                />
                                             </Grid.Column>
                                             <Grid.Column>
                                                <FastField
                                                   name="adminEmail"
                                                   label=""
                                                   component={FormsInputsText}
                                                   secondaryLabel="Email"
                                                />
                                             </Grid.Column>
                                             <Grid.Column>
                                                <FastField
                                                   name="adminMobileNo"
                                                   label=""
                                                   component={FormsInputsText}
                                                   secondaryLabel="Mobile No."
                                                />
                                             </Grid.Column>
                                          </Grid.Row>
                                       </React.Fragment>
                                    ) : null}
                                 </Grid>
                              </Accordion.Content>
                           </React.Fragment>
                        </Accordion>
                     </Segment>

                     <Header as="h3" block attached="top">
                        Supporting Documents
                     </Header>
                     <Segment attached>
                        <Grid.Row>
                           <Grid.Column width={6}>
                              {this.props.supportDocuments.length > 0
                                 ? _.map(this.props.supportDocuments, document => {
                                      const { id, file_name, viewUrl, claim_id } = document;
                                      if (claim_id.toString() === this.props.claimId.toString()) {
                                         return (
                                            <List.Item key={id}>
                                               <List.Content floated="right">
                                                  {viewUrl ?
                                                      (
                                                          <a href={viewUrl} target="_blank" rel="noopener noreferrer">
                                                             Open File
                                                          </a>
                                                      )
                                                      :
                                                      (
                                                          <a
                                                              href="#"
                                                              onClick={(e) => {
                                                                 e.preventDefault();
                                                                 MySwal.fire({
                                                                    icon: "error",
                                                                    title: "Error",
                                                                    text: "File not found!",
                                                                 });
                                                              }}
                                                          >
                                                             Open File
                                                          </a>
                                                      )
                                                  }
                                               </List.Content>
                                               <Image avatar src={viewUrl} />
                                               <List.Content>
                                                  <List.Header>{file_name}</List.Header>
                                               </List.Content>
                                            </List.Item>
                                         );
                                      }
                                   })
                                 : "No Supporting Documents To Display"}
                           </Grid.Column>
                        </Grid.Row>
                     </Segment>

                     <Header as="h3" block attached="top">
                        Internal Remarks
                     </Header>
                     <Segment attached>
                        <Grid.Row>
                           <Grid.Column width={6}>
                              {this.props.internalRemarks.length > 0
                                 ? _.map(this.props.internalRemarks, remark => {
                                      const { id, remarks, viewUrl, claim_id } = remark;
                                      if (claim_id === this.props.claimId) {
                                         return (
                                            <List.Item key={id}>
                                               <List.Content floated="right">
                                                  {viewUrl ?
                                                      (
                                                          <a href={viewUrl} target="_blank" rel="noopener noreferrer">
                                                             Open File
                                                          </a>
                                                      )
                                                      :
                                                      (
                                                          <a
                                                              href="#"
                                                              onClick={(e) => {
                                                                 e.preventDefault();
                                                                 MySwal.fire({
                                                                    icon: "error",
                                                                    title: "Error",
                                                                    text: "File not found!",
                                                                 });
                                                              }}
                                                          >
                                                             Open File
                                                          </a>
                                                      )
                                                  }
                                               </List.Content>
                                               <Image avatar src={viewUrl} />
                                               <List.Content>
                                                  <List.Header>{remarks}</List.Header>
                                               </List.Content>
                                            </List.Item>
                                         );
                                      }
                                   })
                                 : "No Internal Remarks To Display"}
                           </Grid.Column>
                        </Grid.Row>
                     </Segment>

                     <Header as="h3" block attached="top">
                        Receipts {this.props.claimData.claim_id}
                     </Header>
                     <Segment attached>
                        <FieldArray
                           name="receipts"
                           render={arrayHelpers => (
                              <Accordion fluid styled>
                                 {values.receipts.map((receipt, index) => (
                                    <React.Fragment key={receipt.id}>
                                       <Accordion.Title
                                          active={activeReceiptIndex === receipt.id}
                                          index={receipt.id}
                                          onClick={this.handleReceiptClick}
                                       >
                                          <Icon name="dropdown" />
                                          {`${receipt.claimId} - ${index + 1} - ${receipt.receiptNo}`}
                                       </Accordion.Title>
                                       <Accordion.Content active={activeReceiptIndex === receipt.id}>
                                          <Item>
                                             <Item.Content>
                                                <Item.Header as="a">
                                                   {receipt.hospital} <Label color="blue">{receipt.visitType}</Label>
                                                </Item.Header>
                                                <Item.Description>
                                                   <Grid columns="equal">
                                                      <Grid.Row>
                                                         <Grid.Column>
                                                            {receipt.viewUrl ?
                                                                (
                                                                    <a href={receipt.viewUrl} target="_blank" rel="noopener noreferrer">
                                                                       Open Receipt
                                                                    </a>
                                                                )
                                                                :
                                                                (
                                                                    <a
                                                                        href="#"
                                                                        onClick={(e) => {
                                                                           e.preventDefault();
                                                                           MySwal.fire({
                                                                              icon: "error",
                                                                              title: "Error",
                                                                              text: "File not found!",
                                                                           });
                                                                        }}
                                                                    >
                                                                       Open Receipt
                                                                    </a>
                                                                )
                                                            }
                                                         </Grid.Column>
                                                      </Grid.Row>
                                                      <Grid.Row>
                                                         <Grid.Column>
                                                            <FastField
                                                               name={`receipts[${index}].dateIncurredCheck`}
                                                               label=""
                                                               secondaryLabel="Date Incurred"
                                                               component={FormsInputDate}
                                                            />
                                                            <ErrorMessage
                                                               name={`receipts[${index}].dateIncurredCheck`}
                                                               render={msg => (
                                                                  <div className="message negative ui">
                                                                     Date incurred is required field
                                                                  </div>
                                                               )}
                                                            />
                                                         </Grid.Column>
                                                         <Grid.Column>
                                                            <FastField
                                                               name={`receipts[${index}].dateIncurredToCheck`}
                                                               label=""
                                                               secondaryLabel="Date Incurred To"
                                                               component={FormsInputDate}
                                                            />
                                                            <ErrorMessage
                                                               name={`receipts[${index}].dateIncurredToCheck`}
                                                               render={msg => (
                                                                  <div className="message negative ui">
                                                                     Date incurred to is required field
                                                                  </div>
                                                               )}
                                                            />
                                                         </Grid.Column>
                                                         <Grid.Column>
                                                            <FastField
                                                               name={`receipts[${index}].receiptAmountCheck`}
                                                               label=""
                                                               component={FormsInputsText}
                                                               secondaryLabel="Receipt Amount"
                                                            />
                                                            <ErrorMessage
                                                               name={`receipts[${index}].receiptAmountCheck`}
                                                               render={msg => (
                                                                  <div className="message negative ui">{msg}</div>
                                                               )}
                                                            />
                                                         </Grid.Column>
                                                      </Grid.Row>
                                                   </Grid>
                                                </Item.Description>
                                             </Item.Content>
                                          </Item>
                                       </Accordion.Content>
                                    </React.Fragment>
                                 ))}
                              </Accordion>
                           )}
                        />
                     </Segment>
                     <Grid.Row>
                        <Grid.Column>
                           <Button fluid color="black" type="submit" loading={this.state.isSubmitting}>
                              Submit
                           </Button>
                        </Grid.Column>
                     </Grid.Row>
                  </Form>
               </div>
            )}
         />
      );
   }
}

const mapStateToProps = state => {
   return {
      countries: state.checkclaim.countries,
      schools: state.checkclaim.schools
   };
};

export default withRouter(connect(mapStateToProps)(ClaimDetailsForm));
