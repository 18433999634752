import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, Grid } from 'semantic-ui-react'

import FormsInputsText from '../../inputs/text';
import FormsInputDropdown from '../../inputs/dropdown';
import BatchSearchSchema from './validate';

class FormsBatchSearchForm extends Component {
    render() {
        const searchTypeOptions = [
            { text: 'Batch Name', value: 'batchName' },
            // { text: 'Claim ID', value: 1 },
            // { text: 'NRIC', value: 2 },
        ];
        return (
            <Formik
                initialValues={{ 
                    searchType: 'batchName',
                    searchValue: ''
                }}
                onSubmit={(values, actions) => {
                    console.log(values, actions);
                    const { accessToken } = this.props.user;
                    this.props.getSearchBatchList({data: values, accessToken}, (result, error) => {
                        actions.setSubmitting(false);
                    });
                }}
                validationSchema={BatchSearchSchema}
                render={({
                    values,
                    errors,
                    status,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <Form>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={4}>
                                    <Field
                                        name="searchType" 
                                        options={searchTypeOptions} 
                                        label="" 
                                        secondaryLabel="Search Type"
                                        component={FormsInputDropdown}
                                    />
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Field name="searchValue" label="" component={FormsInputsText} secondaryLabel="Search For Value"/>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Button 
                                        type="submit" 
                                        secondary 
                                        disabled={isSubmitting} 
                                        fluid 
                                        loading={isSubmitting}
                                        size="massive"
                                    >
                                        Search Batch
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                )}
            />
        );
    }
}

export default FormsBatchSearchForm;