import React from 'react';
import { Header, Segment, Table, Message } from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';

import ClaimsStatusLabel from '../../claims/status_label';

const RecordClaimViewClaimStatusLog = (props) => {
    return (
        <div>
            <Header as='h3' block attached="top">
                Status Log
            </Header>
            <Segment attached>
                <Table basic compact="very">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>ID</Table.HeaderCell>
                            <Table.HeaderCell>From Status</Table.HeaderCell>
                            <Table.HeaderCell>To Status</Table.HeaderCell>
                            <Table.HeaderCell>Remarks</Table.HeaderCell>
                            <Table.HeaderCell>Changed By</Table.HeaderCell>
                            <Table.HeaderCell>Changed Date</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {
                        _.map(props.statusChangeItems, (item, index) => {
                            const {
                                by_username, remark, from_status, to_status, created_at
                            } = item;
                            return (
                                <Table.Row key={index}>
                                    <Table.Cell>
                                        {index}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <ClaimsStatusLabel status={from_status} />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <ClaimsStatusLabel status={to_status} />
                                    </Table.Cell>
                                    <Table.Cell>
                                        {remark}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {by_username}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {moment(created_at).format('DD/MM/YYYY hh:mm')}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })
                    }
                    {
                        (props.statusChangeItems.length === 0) ?
                        (
                            <Table.Row>
                                <Table.Cell colSpan={6}>
                                    <Message info>
                                        There are no status log items to display.
                                    </Message>
                                </Table.Cell>
                            </Table.Row>
                        )
                        : null
                    }
                    </Table.Body>
                </Table>
            </Segment>
        </div>
    );
}

export default RecordClaimViewClaimStatusLog;