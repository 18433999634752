import React, { Component } from 'react';
import {Segment} from 'semantic-ui-react';

import FormsClaimsStatus from '../../../forms/claim/status';

const ClaimStatusUpdateControl = (props) => {
    return (
        <Segment>
            <FormsClaimsStatus
                validateClaimStatusUpdate={props.validateClaimStatusUpdate}
                setNewAjClaimStatus={props.setNewAjClaimStatus}
                adjNewStatus={props.adjNewStatus}
                claimId={props.claimId}
            />
        </Segment>
    );
}

export default ClaimStatusUpdateControl;