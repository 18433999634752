import React, { Component } from 'react';
import { Container, Grid, Segment, Loader, Table, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Moment from 'moment';
import _ from 'lodash';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

import BatchUpdateClaimsTableMhcCheck from '../../component/batch/update_claims_table/mhc_check';
import BatchUpdateClaimsTableIncomeCheck from '../../component/batch/update_claims_table/income_check';
import BatchViewClaimsListTable from '../../component/batch/view_claims_table';
import BatchUpdateClaimsTablePaymentComplete from '../../component/batch/update_claims_table/payment_complete';
import { imageBolb } from "../../utils/get_image_blob";

import {
    getBatchDetail,
    submitMhcReviewBatchResults,
    submitIncomeReviewBatchResults,
    submitPaidClaimsResults,
    getEobFilesListV2,
    regeneratePaymentFiles,
    attachmentMsGetFileUrl
} from '../../actions';

const MySwal = withReactContent(Swal);

class BatchViewPage extends Component {
    componentDidMount() {
        const {batchId} = this.props.match.params;
        const { accessToken } = this.props.user;
        this.props.getBatchDetail({accessToken, batchId});
    }

    downloadFile(fileName, attachmentToken) {
        if (!attachmentToken) {
            console.error('downloadFile Error: There is no attachment token');
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: "File not found!",
            });
        } else {
            if (fileName === "" || fileName === null) {
                console.log('fileName : ', fileName);
                console.error('downloadFile Error: There is no fileName');
                MySwal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Unable to download file!",
                });
            } else {
                const { accessToken } = this.props.user;

                MySwal.fire({
                    text: 'Loading...',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    onBeforeOpen: () => {
                        MySwal.showLoading();
                        console.log("running...")
                        console.log("accessToken : ", accessToken)
                        this.props.attachmentMsGetFileUrl({accessToken, attachmentToken}, (error, response) => {
                            if (error) {
                                MySwal.close();
                                console.error("attachmentMsGetFileUrl Error: ", error)
                                MySwal.fire({
                                    icon: "error",
                                    title: "Error",
                                    text: "Unable to download file!",
                                });
                            }

                            if (response) {
                                imageBolb('GET', response, fileName)
                                    .then( imageBolbResponse => {
                                        let link = document.createElement("a");
                                        let linkURL = window.URL.createObjectURL(imageBolbResponse.blob);
                                        link.href = linkURL;
                                        link.download = imageBolbResponse.fileName;
                                        document.body.appendChild(link);
                                        link.click();
                                        window.URL.revokeObjectURL(linkURL);
                                        MySwal.close();
                                    })
                                    .catch( imageBolbError => {
                                        MySwal.close();
                                        console.error("imageBolb Error: ", imageBolbError)
                                        MySwal.fire({
                                            icon: "error",
                                            title: "Error",
                                            text: "Unable to download file!",
                                        });
                                    })
                            }
                        })
                    }
                });
            }
        }
    }

    render () {
        const ROOT_URL = process.env.REACT_APP_API_ROOT_URL;
        if (!this.props.batchData || this.props.batchData.id != this.props.match.params.batchId || Moment().diff(Moment(this.props.viewDataToProcessDateTimeStamp).add(8, 'h'), 'seconds') > 15) { return <Loader active />; }
        const {
            id,
            batch_name,
            created_at,
            num_of_claims,
            files,
            claims,
            status,
        } = this.props.batchData;
        return (
            <Container>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment>
                                Batch View - {batch_name}
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment>
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>ID</Table.HeaderCell>
                                            <Table.HeaderCell>Batch Name</Table.HeaderCell>
                                            <Table.HeaderCell>Run Date Time</Table.HeaderCell>
                                            <Table.HeaderCell>Number of Claims Processed</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>
                                                {id}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {batch_name}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {Moment(created_at).format('DD/MM/YYYY HH:mm')}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {num_of_claims}
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    {
                        (status === 'mhc_check') ?
                        (
                            <BatchUpdateClaimsTableMhcCheck
                                batchClaims={claims}
                                user={this.props.user}
                                batchId={id}
                                batchName={batch_name}
                                submitMhcReviewBatchResults={this.props.submitMhcReviewBatchResults}
                                getEobFilesList={this.props.getEobFilesListV2}
                            />
                        )
                        : null
                    }
                    {
                        (status === 'income_check') ?
                        (
                            <BatchUpdateClaimsTableIncomeCheck
                                batchClaims={claims}
                                user={this.props.user}
                                batchId={id}
                                batchName={batch_name}
                                submitIncomeReviewBatchResults={this.props.submitIncomeReviewBatchResults}
                                getEobFilesList={this.props.getEobFilesListV2}
                            />
                        )
                        : null
                    }
                    {
                        (status === 'closed') ?
                        (
                            <BatchUpdateClaimsTablePaymentComplete
                                batchClaims={claims}
                                user={this.props.user}
                                batchId={id}
                                batchName={batch_name}
                                createdAt={created_at}
                                submitPaidClaimsResults={this.props.submitPaidClaimsResults}
                                getEobFilesList={this.props.getEobFilesListV2}
                                regeneratePaymentFiles={this.props.regeneratePaymentFiles}
                                getBatchDetail={this.props.getBatchDetail}
                            />
                        )
                        : null
                    }
                    <Grid.Row>
                        <Grid.Column>
                            <Header attached="top" block as="h4">
                                Files
                            </Header>
                            <Segment attached>
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>ID</Table.HeaderCell>
                                            <Table.HeaderCell>File Name</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            _.map(files, (file) => {
                                                const {
                                                    id,
                                                    file_name,
                                                    file_url,
                                                    attachment_token
                                                } = file;
                                                return (
                                                    <Table.Row key={id}>
                                                        <Table.Cell>
                                                            {id}
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            {attachment_token ? (
                                                                <a
                                                                    href="#"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.downloadFile(file_name, attachment_token);
                                                                    }}
                                                                >
                                                                    {file_name}
                                                                </a>
                                                            ) : (
                                                                <a href={`${ROOT_URL}/batch/downloadfile/${file_name}`} target="_blank">
                                                                    {file_name}
                                                                </a>
                                                            )}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                );
                                            })
                                        }
                                        {
                                            (files.length === 0) ?
                                            (
                                                <Table.Row><Table.Cell colSpan={2}>No Batch Files To Download</Table.Cell></Table.Row>
                                            )
                                            :
                                            null
                                        }
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        batchData: state.batch.viewData,
        viewDataToProcessDateTimeStamp: state.batch.viewDataToProcessDateTimeStamp,
    };
}

export default connect(mapStateToProps, {
    getBatchDetail,
    submitMhcReviewBatchResults,
    submitIncomeReviewBatchResults,
    submitPaidClaimsResults,
    getEobFilesListV2,
    regeneratePaymentFiles,
    attachmentMsGetFileUrl
})(BatchViewPage);

/*
{
    (status === 'closed') ?
    (
        <BatchViewClaimsListTable
            batchClaims={claims}
        />
    )
    : null
}
*/