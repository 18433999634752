import React from "react";
import { TextArea, Header, Message } from "semantic-ui-react";

const FormsInputsTextArea = ({ field, form: { touched, errors }, ...props }) => {
   const hasError = errors[field.name] && touched[field.name] ? true : false;
   return (
      <div className="form-input-group">
         <div>
            <Header sub>{props.label}</Header>
            {props.secondaryLabel ? <Header.Subheader>{props.secondaryLabel}</Header.Subheader> : ""}
         </div>

         <TextArea rows={3} {...field} disabled={props.disabled ? props.disabled : false} className="w-100 textarea" />

         {hasError && <Message negative>{errors[field.name]}</Message>}
      </div>
   );
};

export default FormsInputsTextArea;
