import React from 'react';
import { List } from 'semantic-ui-react';
import _ from 'lodash';

const RecordCaseViewCaseLimitsSummary = (props) => {

    const renderBenefitList = (benefitScheme) => {
        const renderOutput = [];
        _.forOwn(benefitScheme, (benefit, key) => {
            // console.log(benefit, key);
            const {
                id,
                subType,
                parent_id,
                description,
                displayEntitlement
            } = benefit;
            const benefitLimitsAggregatedData = props.benefitLimitsAggregated[id];
            const {
                aggPayableQuantity,
                aggPayableUnitPrice,
                aggQuantity,
                aggTotalIncurredAmount,
                aggTotalPayableAmount,
                aggAmountDeclined
            } = benefitLimitsAggregatedData;
            renderOutput.push(
                <List.Item key={id}>
                    <List.Icon name='caret right' />
                    <List.Content>
                        <List.Header>{description} ({displayEntitlement})</List.Header>
                        <List.Description>
                            Tot.Incurr Amt: {aggTotalIncurredAmount} | Tot.Payable Amt: {aggTotalPayableAmount} | Tot.Amt Dec: {aggAmountDeclined}
                        </List.Description>
                        <List.List>
                            {
                                (subType) ? renderBenefitList(subType) : null
                            }
                        </List.List>
                    </List.Content>
                </List.Item>
            );
        });
        return renderOutput;
    }

    console.log(props.benefitLimitsAggregated);

    return (
        <List>
            { renderBenefitList(props.benefitScheme) }
        </List>
    );
}

export default RecordCaseViewCaseLimitsSummary;

/*
const combinedBenefitItemByType = _.reduce(props.eobItems, (accumulator, eobItem) => {
    const {
        id, 
        parent_id, 
        payableQuantity,
        payableUnitPrice,
        quantity,
        totalIncurredAmount,
        totalPayableAmount,
        amountDeclined
    } = eobItem;
    if (!accumulator[id]) { 
        accumulator[id] = {}; 
        accumulator[id].id = id;
        accumulator[id].parent_id = parent_id;
        accumulator[id].payableQuantity = payableQuantity;
        accumulator[id].payableUnitPrice = payableUnitPrice;
        accumulator[id].quantity = quantity;
        accumulator[id].totalIncurredAmount = totalIncurredAmount;
        accumulator[id].totalPayableAmount = totalPayableAmount;
        accumulator[id].amountDeclined = amountDeclined;
    } else {
        accumulator[id].payableQuantity += payableQuantity;
        accumulator[id].payableUnitPrice += payableUnitPrice;
        accumulator[id].quantity += quantity;
        accumulator[id].totalIncurredAmount += totalIncurredAmount;
        accumulator[id].totalPayableAmount += totalPayableAmount;
        accumulator[id].amountDeclined += amountDeclined;
    }
    return accumulator;
}, {});

console.log('props', props);

console.log('combined results', combinedBenefitItemByType);

let aggregatedKeyResults = {};

for (let i = 0; i < props.benefitSchemeItems.length; i++) {
    let schemeItem = props.benefitSchemeItems[i];
    const {
        id
    } = schemeItem;
    let tempRootArray = [];
    let secondarykeyArray = [];
    let thirdkeyArray = [];
    for (let y = 0; y < props.benefitSchemeItems.length; y++) {
        if (props.benefitSchemeItems[y].parent_id === id) {
            secondarykeyArray.push(props.benefitSchemeItems[y].id);
        }
    }
    for (let y = 0; y < props.benefitSchemeItems.length; y++) {
        if (secondarykeyArray.indexOf(props.benefitSchemeItems[y].parent_id) > -1) {
            thirdkeyArray.push(props.benefitSchemeItems[y].id);
        }
    }
    tempRootArray = [...secondarykeyArray, ...thirdkeyArray, id];
    aggregatedKeyResults[id] = tempRootArray;
}

console.log('combined results 2', aggregatedKeyResults);

const aggregatedAmountResults = _.reduce(aggregatedKeyResults, (accumulator, keyArray, objKey) => {
    let tempAggPayableQuantity = 0;
    let tempAggPayableUnitPrice = 0;
    let tempAggQuantity = 0;
    let tempAggTotalIncurredAmount = 0;
    let tempAggTotalPayableAmount = 0;
    let tempAggAmountDeclined = 0;

    for (let i = 0; i < keyArray.length; i++) {
        if (combinedBenefitItemByType[keyArray[i]]) {
            tempAggPayableQuantity += combinedBenefitItemByType[keyArray[i]].payableQuantity;
            tempAggPayableUnitPrice += combinedBenefitItemByType[keyArray[i]].payableUnitPrice;
            tempAggQuantity += combinedBenefitItemByType[keyArray[i]].quantity;
            tempAggTotalIncurredAmount += combinedBenefitItemByType[keyArray[i]].totalIncurredAmount;
            tempAggTotalPayableAmount += combinedBenefitItemByType[keyArray[i]].totalPayableAmount;
            tempAggAmountDeclined += combinedBenefitItemByType[keyArray[i]].amountDeclined;
        }
    }

    accumulator[objKey] = {
        aggPayableQuantity: tempAggPayableQuantity,
        aggPayableUnitPrice: tempAggPayableUnitPrice,
        aggQuantity: tempAggQuantity,
        aggTotalIncurredAmount: tempAggTotalIncurredAmount,
        aggTotalPayableAmount: tempAggTotalPayableAmount,
        aggAmountDeclined: tempAggAmountDeclined
    };
    return accumulator;
}, {});

console.log('combined results 3', aggregatedAmountResults);
*/