import React from 'react';
import {
    Header,
    Segment,
    Table,
} from 'semantic-ui-react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import ClaimsStatusLabel from '../../claims/status_label';

const RecordCaseViewClaimListItem = (props) => {
    const {
        id,
        claim_id,
        total_amount,
        claim_status,
        status_remarks,
        endorsement_status,
        endorse_date,
        certified_by_school_name,
        claim_type,
        internal_claim_remarks,
        claim_received_date,
        claim_processing_start_date,
        claim_processing_end_date,
        payment_date,
        ineligible_amount,
        pay_medisave,
        pay_medishield,
        cash_reimbursed,
        total_amount_paid_by_insurer,
        patient_payable_amount,
        batch_name,
    } = props.data;
    return (
        <Table.Row>
            <Table.Cell>
                <Link to={`/app/database/claim/${claim_id}`}>
                    {claim_id}
                </Link>
            </Table.Cell>
            <Table.Cell>
                <ClaimsStatusLabel status={claim_status} />
            </Table.Cell>
            <Table.Cell>
                {endorsement_status}
            </Table.Cell>
            <Table.Cell>
                {status_remarks}
            </Table.Cell>
        </Table.Row>
    );
}

export default RecordCaseViewClaimListItem;