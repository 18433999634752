import React, { Component } from "react";
import {
  Header,
  Segment,
  Button,
  Grid,
  Message,
  Modal,
  Icon
} from "semantic-ui-react";
import { PERMISSION_RESET_CLAIM_ADJUDICATION_DATA, PERMISSION_REGENERATE_EOB } from '../../../utils/constant_permission_list';

class RecordClaimViewClaimUpdateClaim extends Component {
  state = {
    isRegeneratingEOB: false,
    isSuccessRegenerateEOB: false,
    regenerateEOBerrorMessage: null,
    isSubmitting: false,
    isSuccess: false,
    errorMessage: null,
    type: null,
    triggerResetModal: false
  };
  renderSubmissionResult() {
    if (this.state.isSuccess === true) {
      if (this.state.type === "paid") {
        return (
          <Message positive>
            Claim has been updated to "PAID" status successfully.
            <p>Please refresh this page for changes to take effect.</p>
          </Message>
        );
      } else {
        return (
          <Message positive>
            Claim adjudication data has been deleted. Status is now "NEW"
            <p>Please refresh this page for changes to take effect.</p>
          </Message>
        );
      }
    } else if (this.state.errorMessage) {
      return <Message negative>Error! {this.state.errorMessage}</Message>;
    }
  }
  renderRegenerateEOBResult() {
    if (this.state.isSuccessRegenerateEOB) {
      return (
        <Message positive>
          Successfully regenerate EOB.
          <p>Please refresh this page for changes to take effect.</p>
        </Message>
      );
    } else if (this.state.regenerateEOBerrorMessage) {
      return (
        <Message negative>
          Unable to regenerate EOB. {this.state.regenerateEOBerrorMessage}
        </Message>
      );
    }
  }
  submitFormToProcess = (type, resetEndorsement) => {
    this.setState({
      type,
      isSubmitting: true,
      isSuccess: false,
      errorMessage: null
    });
    const { case_id, claim_id } = this.props.claimData;
    const { accessToken } = this.props.user;
    if (type === "paid") {
      this.props.submitRecordClaimUpdatePaidStatus(
        {
          accessToken,
          data: { caseId: case_id, claimId: claim_id }
        },
        (result, error) => {
          console.log(result, error);
          if (result) {
            this.setState({
              isSubmitting: true,
              isSuccess: true,
              errorMessage: null
            });
          }
          if (error) {
            this.setState({
              isSubmitting: false,
              errorMessage: error.errorMessage
            });
          }
        }
      );
    } else if (type === "reset") {
      this.triggerOpenResetModal();
      this.props.submitRecordClaimResetAdjData(
        {
          accessToken,
          data: { caseId: case_id, claimId: claim_id, resetEndorsement }
        },
        (result, error) => {
          console.log(result, error);
          if (result) {
            this.setState({
              isSubmitting: true,
              isSuccess: true,
              errorMessage: null
            });
          }
          if (error) {
            this.setState({
              isSubmitting: false,
              errorMessage: error.errorMessage
            });
          }
        }
      );
    }
  };
  regenerateEOBData = () => {
    this.setState({ isRegeneratingEOB: true }, () => {
      const { case_id, claim_id } = this.props.claimData;
      const { accessToken } = this.props.user;
      this.props.regenerateEOBData(
        {
          accessToken,
          data: { caseId: case_id, claimId: claim_id }
        },
        (result, error) => {
          console.log(result, error);
          if (result) {
            this.setState({
              isRegeneratingEOB: false,
              isSuccessRegenerateEOB: true,
              regenerateEOBerrorMessage: null
            });
          }
          if (error) {
            this.setState({
              isRegeneratingEOB: false,
              regenerateEOBerrorMessage: error.errorMessage
            });
          }
        }
      );
    });
  };
  triggerOpenResetModal = () => {
    this.setState({ triggerResetModal: !this.state.triggerResetModal });
  }
  confirmationResetAdjudicationData = () => {
    return (
      <Modal
        basic
        open={this.state.triggerResetModal}
        size="small"
      >
        <Header icon>
          <Icon name="exclamation" />
          Would you like to reset the Endorsement as well ?
        </Header>
        <Modal.Actions>
          <Button basic color='yellow' inverted onClick={() => this.triggerOpenResetModal()}>
            <Icon name='arrow left' /> Cancel
          </Button>
          <Button color='red' inverted onClick={() => this.submitFormToProcess("reset", false)}>
            <Icon name='remove' /> No
          </Button>
          <Button color='green' inverted onClick={() => this.submitFormToProcess("reset", true)}>
            <Icon name='checkmark' /> Yes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
  renderResetAdjudicationData = isAllowedToUpdate => {
    if (isAllowedToUpdate === true && (this.props.user.type === "ADJ" || this.props.user.permission.map(p => p).includes(PERMISSION_RESET_CLAIM_ADJUDICATION_DATA))) {
      return (
        <Button
          fluid
          color="red"
          onClick={() => this.triggerOpenResetModal()}
          loading={this.state.isSubmitting}
          disabled={this.state.isSubmitting}
        >
          Reset Adjudication Data And Set Claim Status to "NEW"
        </Button>
      );
    }
    return (
      <Message negative>
        {this.props.user.type === "ADJ" ?
        `Claim must be in "Processing (Wait for Batch)" status to be able to
        reset adjudication data.`
        : "Not allow to reset adjudication data."}
      </Message>
    );
  };
  renderRegenerateEOB = isAllowedToRegenerateEOB => {
    if (isAllowedToRegenerateEOB === true&& (this.props.user.type === "ADJ" || this.props.user.permission.map(p => p).includes(PERMISSION_REGENERATE_EOB))) {
      return (
        <Button
          fluid
          color="green"
          onClick={() => this.regenerateEOBData()}
          loading={this.state.isRegeneratingEOB}
          disabled={this.state.isRegeneratingEOB}
        >
          Regenerate EOB
        </Button>
      );
    }
    return (
      <Message negative>
        {this.props.user.type === "ADJ" ?
        ` Claim must be in "Paid" status to be able to regenerate EOB.`
        : "Not allow to regenerate EOB."}
      </Message>
    );
  };
  renderUpdateClaimStatusToPaid = isAllowedToUpdate => {
    if (isAllowedToUpdate === true) {
      return (
        <Button
          fluid
          primary
          onClick={() => this.submitFormToProcess("paid")}
          loading={this.state.isSubmitting}
          disabled={this.state.isSubmitting}
        >
          Update Claim Status to "PAID"
        </Button>
      );
    }
    return (
      <Message negative>
        Claim must be in "Pending Payment" status to update to "Paid" status.
      </Message>
    );
  };
  render() {
    const { claim_status } = this.props.claimData;
    const isAllowedToUpdate =
      // claim_status === "2" || claim_status === "-1" || claim_status === "7" || claim_status === "5"
      claim_status === "2" || claim_status === "-1" || claim_status === "5"
        ? true
        : false;
    const isAllowedToRegenerateEOB = claim_status === "7" ? true : false;

    return (
      <Grid columns={1}>
        <Grid.Column>{this.confirmationResetAdjudicationData()}</Grid.Column>
        <Grid.Column>{this.renderSubmissionResult()}</Grid.Column>
        <Grid.Column>{this.renderRegenerateEOBResult()}</Grid.Column>
        <Grid.Column>
          <Header as="h4" attached="top" block>
            Reset Adjudication Data
          </Header>
          <Segment attached>
            {this.renderResetAdjudicationData(isAllowedToUpdate)}
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Header as="h4" attached="top" block>
            Regenerate EOB
          </Header>
          <Segment attached>
            {this.renderRegenerateEOB(isAllowedToRegenerateEOB)}
          </Segment>
        </Grid.Column>
        {/* <Grid.Column>
                    <Header as='h4' attached="top" block>
                        Update Claim Status To Paid
                    </Header>
                    <Segment attached>
                        {this.renderUpdateClaimStatusToPaid(isAllowedToUpdate)}
                    </Segment>
                </Grid.Column> */}
      </Grid>
    );
  }
}

export default RecordClaimViewClaimUpdateClaim;
