import React, { Component } from 'react';
import {Grid, Segment, Header} from 'semantic-ui-react';
import {connect} from 'react-redux';

import FormsLogin from '../../component/forms/login';
import {
    doLogin,
    doOTPLogin,
    sendOTP
} from '../../actions'

class LoginPage extends Component {
    componentDidMount() {
        if (this.props.user) {
            this.props.history.push('/app');
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.user) {
            this.props.history.push('/app');
        }
    }
    render() {
        return (
            <Grid container centered>
                <Grid.Column style={{paddingTop: 150}} width={8}>
                    <Header attached="top" block as="h1" textAlign="center">
                        Project MIGHTY System v.02
                    </Header>
                    <Segment attached>
                        <FormsLogin
                            doLogin={this.props.doLogin}
                            doOTPLogin={this.props.doOTPLogin}
                            sendOTP={this.props.sendOTP}
                        />
                    </Segment>
                </Grid.Column>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.data
    }
}

export default connect(mapStateToProps, { doLogin, doOTPLogin, sendOTP })(LoginPage);