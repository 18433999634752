import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Grid, Loader, Segment } from "semantic-ui-react";

import { getRecordClaimDetails, submitCheckClaim, getCountries, getSchools, getCheckClaims } from "../../actions";
import ClaimDetailsForm from "../../component/forms/checkclaims/claim_details";

class CheckNewClaimViewPage extends Component {
   submitMyForm = null;

   state = { receipts: null };

   componentDidMount() {
      const { claimId } = this.props.match.params;
      this.props.getRecordClaimDetails({ claimId }).then(() => {
         this.setState({ receipts: this.props.claimData.receipts });
      });
      this.props.getCountries();
      this.props.getSchools();
   }

   render() {
      const { claimId, claimType } = this.props.match.params;
      if (!this.props.claimData || !this.props.user || !this.state.receipts) {
         return <Loader active />;
      }
      const { supportDocuments, internalRemarks, case_id } = this.props.claimData;

      return (
         <Container>
            <Grid>
               <Grid.Row>
                  <Grid.Column>
                     <Segment raised>
                        <h3>Check New Claim Details For # {claimId}</h3>
                     </Segment>
                  </Grid.Column>
               </Grid.Row>
               <Grid.Row>
                  <Grid.Column>
                     <ClaimDetailsForm
                        caseId={case_id}
                        claimId={claimId}
                        user={this.props.user}
                        submitCheckClaim={this.props.submitCheckClaim}
                        getCheckClaims={this.props.getCheckClaims}
                        claimType={claimType}
                        claimData={this.props.claimData}
                        receipts={this.state.receipts}
                        supportDocuments={supportDocuments}
                        internalRemarks={internalRemarks}
                     />
                  </Grid.Column>
               </Grid.Row>
            </Grid>
         </Container>
      );
   }
}

const mapStateToProps = state => {
   return {
      user: state.user.data,
      claimData: state.records.selectedClaim
   };
};

export default connect(
   mapStateToProps,
   { getRecordClaimDetails, submitCheckClaim, getCountries, getSchools, getCheckClaims }
)(CheckNewClaimViewPage);
