import * as Yup from "yup";

const CheckNewClaimFormSchema = Yup.object().shape({
   claimType: Yup.string(),
   insuredName: Yup.string().required(),
   insuredNRIC: Yup.string().required(),
   insuredDOB: Yup.date().when("claimType", {
      is: "New",
      then: Yup.date(),
      otherwise: Yup.date().nullable()
   }),
   payeeName: Yup.string().required(),
   payeeNRIC: Yup.string().required(),
   school: Yup.string().when("claimType", {
      is: "New",
      then: Yup.string().required(),
      otherwise: Yup.string()
   }),
   parentEmail: Yup.string().required(),
   postcode: Yup.string().required(),
   houseNo: Yup.string().required(),
   streetName: Yup.string().required(),
   buildingName: Yup.string().required(),
   paymentMode: Yup.string(),
   bankCode: Yup.string().when("paymentMode", {
      is: value => value === "Direct credit to bank account (GIRO)",
      then: Yup.string().required("bankName is a required field"),
      otherwise: Yup.string()
   }),
   accountNo: Yup.string().when("paymentMode", {
      is: value => value === "Direct credit to bank account (GIRO)",
      then: Yup.string().required(),
      otherwise: Yup.string()
   }),
   radioEndorseDecline: Yup.string().required(),
   adminName: Yup.string().when("radioEndorseDecline", {
      is: "0",
      then: Yup.string(),
      otherwise: Yup.string().required()
   }),
   adminEmail: Yup.string().when("radioEndorseDecline", {
      is: "0",
      then: Yup.string(),
      otherwise: Yup.string().required()
   }),
   adminMobileNo: Yup.string().when("radioEndorseDecline", {
      is: "0",
      then: Yup.string(),
      otherwise: Yup.string().required()
   }),
   receipts: Yup.array()
      .of(
         Yup.object().shape({
            dateIncurredCheck: Yup.date().required(),
            dateIncurredToCheck: Yup.date().required(),
            receiptAmountCheck: Yup.string().required("Receipt amount is required field")
         })
      )
});

export default CheckNewClaimFormSchema;
