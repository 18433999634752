import * as Yup from 'yup';

const NewUserSchema = Yup.object().shape({
    username: Yup.string()
        .email('Email address is not valid.')
        .required('Email address is required.'),
    person_name: Yup.string()
        .required('Name is required'),
    password: Yup.string()
        .trim()
        .min(8, 'Too short minimum 8 characters')
        .max(30, 'Too long maximum 30 characters')
        .matches(/^(?=.*[0-9]+.*)(?=.*[a-zA-Z]+.*)[0-9a-zA-Z]{8,30}$/, 'Password must contain at least one letter and at least one number')
        .required('New password is required'),
});

export default NewUserSchema;