import React from 'react';
import { Input, Header, Message } from 'semantic-ui-react'

const FormsInputsText = ({field, form: {touched, errors}, ...props}) => {
    const hasError = (errors[field.name] && touched[field.name]) ? true : false;
    return (
        <div className="form-input-group">
            <div>
                <Header sub>{props.label}</Header>
                {(props.secondaryLabel) ? <Header.Subheader>{props.secondaryLabel}</Header.Subheader>: ''}
            </div>
            <Input fluid {...field} label={props.inputLabel} disabled={(props.disabled) ? props.disabled : false} error={props.invalid} />
            {
                hasError &&
                (
                    <Message negative>
                        {errors[field.name]}
                    </Message>
                )
            }
        </div>
    );
}

export default FormsInputsText;