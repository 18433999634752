import React, {Component} from 'react';
import { Grid, List, Header, Segment, Image, Button } from 'semantic-ui-react';
import _ from 'lodash';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

class RecordClaimViewClaimDocuments extends Component {
    state = {
        selectedImageId: null,
        selectedImageViewUrl: (this.props.supportDocuments[0]) ? this.props.supportDocuments[0].viewUrl : null,
    }
    updateSelectedImage = (selectedImageViewUrl) => {
        this.setState({selectedImageViewUrl});
    }
    render() {
        const {
            supportDocuments
        } = this.props;
        return (
            <div>
                <Header as='h3' block attached="top">
                    Supporting Documents
                </Header>
                <Segment attached>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={6}>
                                <List divided verticalAlign='middle'>
                                    {
                                        _.map(supportDocuments, (document) => {
                                            const {
                                                id,
                                                file_name,
                                                viewUrl,
                                            } = document;
                                            return (
                                                <List.Item key={id}>
                                                    <List.Content floated='right'>
                                                        {viewUrl ?
                                                            (
                                                                <a href={viewUrl} target="_blank">
                                                                    <Button
                                                                        size="tiny"
                                                                    >
                                                                        Open File
                                                                    </Button>
                                                                </a>
                                                            )
                                                            :
                                                            (
                                                                <Button
                                                                    size="tiny"
                                                                    onClick={() => {
                                                                        MySwal.fire({
                                                                            icon: "error",
                                                                            title: "Error",
                                                                            text: "File not found!",
                                                                        });
                                                                    }}
                                                                >
                                                                    Open File
                                                                </Button>
                                                            )
                                                        }

                                                        <Button
                                                            onClick={() => this.updateSelectedImage(viewUrl)}
                                                            size="tiny"
                                                        >
                                                            View
                                                        </Button>
                                                    </List.Content>
                                                    <Image avatar src={viewUrl} />
                                                    <List.Content>
                                                        <List.Header>{file_name}</List.Header>
                                                    </List.Content>
                                                </List.Item>
                                            );
                                        })
                                    }
                                </List>
                            </Grid.Column>
                            <Grid.Column width={10}>
                                {this.state.selectedImageViewUrl ?
                                    (<Image src={this.state.selectedImageViewUrl} fluid />)
                                    :
                                    (
                                        <div style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "100%"
                                        }}>
                                            <p style={{
                                                color: "#FF0000"
                                            }}>File not found!</p>
                                        </div>
                                    )
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </div>
        );
    }
}

export default RecordClaimViewClaimDocuments;