import React, { Component } from "react";
import { Container, Grid } from "semantic-ui-react";
import { connect } from "react-redux";

import ReportTypeSelection from "../../component/reports/report_type_selection";
import ReportTypeTwo from "../../component/reports/report_type_two";
import { submitGetReportTwoData } from "../../actions";

class BillingReportsPage extends Component {
  state = {
    reportTypeId: 2
  };
  handleReportTypeChange = (e, { value }) => {
    if(value === this.state.reportTypeId) {
        this.setState({ reportTypeId: value });
      } else {
        if(value === 1) {
          this.props.history.push('/app/reports/statuschangereport')
        } else if(value === 3) {
          this.props.history.push('/app/reports/kpireport')
        }
      }
}

  render() {
    return (
      <Container>
        <Grid columns={1}>
          <Grid.Row>
            <Grid.Column>
              <ReportTypeSelection
                user={this.props.user}
                handleReportTypeChange={this.handleReportTypeChange}
                reportTypeId={this.state.reportTypeId}
              />
            </Grid.Column>
          </Grid.Row>
          {this.state.reportTypeId === 2 ? (
            <ReportTypeTwo
              submitGetReportTwoData={this.props.submitGetReportTwoData}
              user={this.props.user}
              reportData={this.props.reportData}
            />
          ) : null}
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.data,
    reportData: state.report.reportData
  };
};

export default connect(
  mapStateToProps,
  {
    submitGetReportTwoData
  }
)(BillingReportsPage);
