import React from 'react';
import {Header, Segment, Table} from 'semantic-ui-react';
import Moment from 'moment';
import _ from 'lodash';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const RecordCaseViewCaseEobFiles = (props) => {
    return (
        <div>
            <Header as='h3' block attached="top">
                Explanation Of Benefit Files
            </Header>
            <Segment attached>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                Case ID
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Claim ID
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                View File
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Created At
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            _.map(props.eobFiles, (file) => {
                                const {
                                    id,
                                    case_id,
                                    claim_id,
                                    created_at,
                                    viewUrl
                                } = file;
                                return (
                                    <Table.Row key={id}>
                                        <Table.Cell>
                                            {case_id}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {claim_id}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {viewUrl ?
                                                (
                                                    <a href={viewUrl} target="_blank">
                                                        View File
                                                    </a>
                                                )
                                                :
                                                (
                                                    <a
                                                        href="#"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            MySwal.fire({
                                                                icon: "error",
                                                                title: "Error",
                                                                text: "File not found!",
                                                            });
                                                        }}
                                                    >
                                                        View File
                                                    </a>
                                                )
                                            }
                                        </Table.Cell>
                                        <Table.Cell>
                                            {Moment(created_at).format('DD/MM//YYYY hh:mm:ss A')}
                                        </Table.Cell>                                        
                                    </Table.Row>
                                );
                            })
                        }
                    </Table.Body>
                </Table>
            </Segment>
        </div>
    );
}

export default RecordCaseViewCaseEobFiles;