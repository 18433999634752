import React from 'react';
import { Table } from 'semantic-ui-react'
import Moment from 'moment';
import { Link } from 'react-router-dom';

import ClaimsStatusLabel from '../../claims/status_label';

const AdjudicateClaimRow = (props) => {
    const {
        id,
        case_id,
        claim_id,
        created_at,
        claim_status,
        visit_type
    } = props.claim;
    return (
        <Table.Row key={id}>
            <Table.Cell width={1}>
                {props.index + 1}.
            </Table.Cell>
            <Table.Cell>
                <Link to={`/app/adjudicate/${case_id}/${claim_id}`}>
                    {claim_id}
                </Link>
            </Table.Cell>
            <Table.Cell>
                {case_id}
            </Table.Cell>
            <Table.Cell>
                <ClaimsStatusLabel status={claim_status} />
            </Table.Cell>
            <Table.Cell>
                {visit_type && visit_type.includes('Inpatient') ? 'Inpatient' : 'Outpatient'}
            </Table.Cell>
            <Table.Cell>
                {Moment(created_at).format('DD/MM/YYYY HH:mm')}
            </Table.Cell>
        </Table.Row>
    );
}

export default AdjudicateClaimRow;