const PERMISSION_PROCESS_BATCH ="adj_process_batch";
const PERMISSION_PROCESS_ADJUDICATION ="adj_process_adjudication";
const PERMISSION_EDIT_CLAIM_REMARKS ="adj_edit_claim_remarks";
const PERMISSION_RESET_CLAIM_ADJUDICATION_DATA ="adj_reset_claim_adjudication_data";
const PERMISSION_REGENERATE_EOB ="adj_regenerate_eob";
const PERMISSION_VIEW_KPI_REPORTS ="view_kpi_reports";


module.exports = {
    PERMISSION_PROCESS_BATCH,
    PERMISSION_PROCESS_ADJUDICATION,
    PERMISSION_EDIT_CLAIM_REMARKS,
    PERMISSION_RESET_CLAIM_ADJUDICATION_DATA,
    PERMISSION_REGENERATE_EOB,
    PERMISSION_VIEW_KPI_REPORTS
};