import React, { Component } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';

import ReportTypeSelection from '../../component/reports/report_type_selection';
import ReportTypeOne from '../../component/reports/report_type_one';
import {
    submitGetReportOneData
} from '../../actions';

class AdjucationStatusChangeReportsPage extends Component {
    state = {
        reportTypeId: 1
    }
    handleReportTypeChange = (e, { value }) => {
        if(value === this.state.reportTypeId) {
            this.setState({ reportTypeId: value });
          } else {
            if(value === 2) {
              this.props.history.push('/app/reports/billingreport')
            } else if(value === 3) {
              this.props.history.push('/app/reports/kpireport')
            }
          }
    }

    render() {
        return (
            <Container>
                <Grid columns={1}>
                    <Grid.Row>
                        <Grid.Column>
                            <ReportTypeSelection
                                user={this.props.user}
                                handleReportTypeChange={this.handleReportTypeChange}
                                reportTypeId={this.state.reportTypeId}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {
                        (this.state.reportTypeId === 1) ? (
                            <ReportTypeOne
                                submitGetReportOneData={this.props.submitGetReportOneData}
                                user={this.props.user}
                                reportData={this.props.reportData}
                            />
                        )
                        :
                        null
                    }
                </Grid>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        reportData: state.report.reportData
    }
}

export default connect(mapStateToProps, {
    submitGetReportOneData
})(AdjucationStatusChangeReportsPage);