import { 
    SUBMIT_REPORT_TYPE_ONE_SUCCESS,
    // SUBMIT_REPORT_TYPE_ONE_ERROR,
} from '../actions';

const initialState = {
    reportData: null,
};

export default function(state = initialState, action) {
    switch(action.type) {
        case SUBMIT_REPORT_TYPE_ONE_SUCCESS:
            return Object.assign({}, state, {reportData: action.payload.data.reportResult});
        // case GET_CFG_BENEFIT_SCHEME_SUCCESS:
        //     return Object.assign({}, state, {benefitScheme: action.payload.data.benefitScheme});
        default:
            return state;
    }
}