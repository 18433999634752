import React from 'react';
import {Header, Segment} from 'semantic-ui-react';

import RecordCaseViewCaseLimitsTable from './case_limits_table';
import RecordCaseViewCaseLimitsSummary from './case_limits_summary';

const RecordCaseViewCaseLimits = (props) => {
    return (
        <div>
            <Header as='h3' block attached="top">
                Benefit Limits Summary
            </Header>
            <Segment attached>
                <RecordCaseViewCaseLimitsSummary 
                    eobItems={props.eobItems}
                    benefitSchemeItems={props.benefitSchemeItems}
                    benefitScheme={props.benefitScheme}
                    benefitLimitsAggregated={props.benefitLimitsAggregated}
                />
            </Segment>
            <Header as='h3' block attached="top">
                Benefit Limits Item
            </Header>
            <Segment attached>
                <RecordCaseViewCaseLimitsTable eobItems={props.eobItems}/>
            </Segment>
        </div>
    );
}

export default RecordCaseViewCaseLimits;