import React from 'react';
import { Header, Segment } from 'semantic-ui-react';

import RecordClaimViewClaimDetailsEndorsement from './claim_details_endorsement';
import RecordClaimViewClaimDetailsClaim from './claim_details_claim';
import RecordClaimViewClaimDetailsOI from './claim_details_oi';

const RecordClaimViewClaimDetails = (props) => {
    // console.log('RecordClaimViewClaimDetails', props)
    return (
        <div>
            <Header as='h3' block attached="top">
                Claim Details
            </Header>
            <Segment attached>
                <RecordClaimViewClaimDetailsClaim claimData={props.claimData} />
                <RecordClaimViewClaimDetailsEndorsement claimData={props.claimData} />
                <RecordClaimViewClaimDetailsOI claimData={props.claimData} />
            </Segment>
        </div>
    );
}

export default RecordClaimViewClaimDetails;