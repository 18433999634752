import React, { Component } from 'react';
import { Container, Grid, Segment, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Moment from 'moment';

import {
    getBatchPendingRejectClaimsToProcessList,
    submitPendingRejectedResults,
    getBatchAllMissingEmailCase
} from '../../actions';
import BatchUpdateClaimsTablePendingRejected from '../../component/batch/update_claims_table/pending_rejected';

class BatchPendingRejectedListPage extends Component {
    componentDidMount() {
        const { accessToken } = this.props.user;
        this.props.getBatchPendingRejectClaimsToProcessList(accessToken).then(() => {
            const caseList = this.props.claimsToProcess.map(claim => {
                return claim.case_id
            })
            if(caseList.length > 0){
                this.props.getBatchAllMissingEmailCase(caseList, accessToken);
            }

        });
    }
    render() {
        if (!this.props.claimsToProcessDateTimeStamp || Moment().diff(Moment(this.props.claimsToProcessDateTimeStamp).add(8, 'h'), 'seconds') > 15) {
            return <Loader active />;
        }
        return (
            <Container fluid style={{paddingLeft: 20, paddingRight: 20}}>
                <Grid>
                    <BatchUpdateClaimsTablePendingRejected
                        user={this.props.user}
                        claimsToProcess={this.props.claimsToProcess}
                        caseList={this.props.caseList}
                        submitPendingRejectedResults={this.props.submitPendingRejectedResults}
                    />
                </Grid>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        claimsToProcess: state.batch.claimsToProcess,
        claimsToProcessDateTimeStamp: state.batch.claimsToProcessDateTimeStamp,
        caseList: state.batch.caseList,
    };
}

export default connect(mapStateToProps, {
    getBatchPendingRejectClaimsToProcessList,
    submitPendingRejectedResults,
    getBatchAllMissingEmailCase
})(BatchPendingRejectedListPage);