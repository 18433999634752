import {
    // DO_LOGIN_ERROR,
    DO_LOGIN_SUCCESS,
    DO_OTP_LOGIN_SUCCESS,
    SUBMIT_GET_USER_LIST_SUCCESS,
} from '../actions';

const initialState = {
    data: null,
    error: false,
    errorObject: null,
    adminUserList: [],
};

export default function(state = initialState, action) {
    switch(action.type) {
        case SUBMIT_GET_USER_LIST_SUCCESS:
            return Object.assign({}, state, {adminUserList: action.payload.data.users});
        case DO_LOGIN_SUCCESS:
            return Object.assign({}, state, {data: action.payload.data.user});
        case DO_OTP_LOGIN_SUCCESS:
                return Object.assign({}, state, {data: action.payload.data.user});
        default:
            return state;
    }
}