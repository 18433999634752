import * as Yup from 'yup';

const ClaimRejectedStatusSchema = Yup.object().shape({
    rejectionReason: Yup.string(),
    // rejectionReasonFreeText: Yup.string(),
    rejectionReasonFreeText: Yup.string().when(
        'rejectionReason', {
            is: (value) => (value && value.length === 0),
            then: Yup.string().required(),
            else: Yup.string()
        }
    ),
});

export default ClaimRejectedStatusSchema;