import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage'

import VersionsReducer from './reducer_versions';
import UserReducer from './reducer_user';
import AdjudicateReducer from './reducer_adjudicate';
import ConfigReducer from './reducer_config';
import RecordsReducer from './reducer_records';
import BatchReducer from './reducer_batch';
import ReportReducer from './reducer_report';
import CheckClaimReducer from './reducer_checkclaim';
// import ClaimsReducer from './reducer_claim';
// import ClaimoptionsReducer from './reducer_claimoptions';
// import ClinicReducer from './reducer_clinic';

const appReducer = combineReducers({
    // state: (state = {}) => state
    versions: VersionsReducer,
    user: UserReducer,
    adjudicate: AdjudicateReducer,
    config: ConfigReducer,
    records: RecordsReducer,
    batch: BatchReducer,
    report: ReportReducer,
    checkclaim: CheckClaimReducer
});

const rootReducer = (state, action) => {

    if (action.type === 'USER_LOGOUT') {
        Object.keys(state).forEach(key => {
            storage.removeItem(`persist:${key}`);
        });
        state = undefined;
    }

    return appReducer(state, action);
}

export default rootReducer;
