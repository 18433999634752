import * as Yup from 'yup';

const ClaimPendingDocumentStatusSchema = Yup.object().shape({
    pendingReason: Yup.string().when(
        'pendingReasons', {
            is: (value) => value.length === 0,
            then: Yup.string().required(),
            else: Yup.string()
        }
    ),
    pendingReasons: Yup.array(Yup.string().required())
    // .required('Pending Reasons is required')
    // .min(0, 'Pending Reasons is required')
    .max(4, 'Pending Reasons maximum only 4')
});

export default ClaimPendingDocumentStatusSchema;