import React from 'react';
import { Table } from 'semantic-ui-react'
import _ from 'lodash';

import RecordResultListItem from './result_list_item';

const RecordResultListTable = (props) => {
    return (
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Claim ID</Table.HeaderCell>
                    <Table.HeaderCell>Case ID</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Created At</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
            {
                _.map(props.records, (record) => {
                    return <RecordResultListItem claim={record} key={record.id} />
                })
            }
            {
                (props.records.length === 0) 
                ?
                (
                    <Table.Row><Table.Cell colSpan={4}>No Matching Records To Display</Table.Cell></Table.Row>
                )
                :
                null
            }
            </Table.Body>
        </Table>
    );
}

export default RecordResultListTable;