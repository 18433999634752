import React from 'react';
import { Segment, Grid, Message } from 'semantic-ui-react';

import FormsClaimsPendingDocument from '../../../forms/claim/pending_document';

const ClaimPendingDocActionControl = (props) => {
    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Segment>
                        <Message warning>
                            <p>
                                The claim is going to be put on hold and an email will be sent to the claimant.
                            </p>
                            <p>
                                Reminders will be sent in this order:
                            </p>
                            <ol>
                                <li>D+00: First Email (Today)</li>
                                <li>D+10: First Reminder</li>
                                <li>D+20: Second Reminder</li>
                                <li>D+30: Withdrawal</li>
                            </ol>
                            <p>
                                Should there be no response 30 days from today, this claim will be automatically be updated to closed status.
                            </p>
                        </Message>
                        <FormsClaimsPendingDocument {...props} />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default ClaimPendingDocActionControl;
