import React from 'react';
import {Table} from 'semantic-ui-react';
import Moment from 'moment';

const ClaimDetailPaneOI = (props) => {
    const {
        claim_from_other,
        status_remarks,
        payment_mode,
        relationship_to_insured,
        payee_name,
        payee_nric,
        payee_dob,
        payee_gender,
        payee_country_birth
    } = props.claimData;
    return (
        <Table basic compact="very" fixed>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        Claim from Other Insurer
                    </Table.Cell>
                    <Table.Cell>
                        {claim_from_other}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Remarks
                    </Table.Cell>
                    <Table.Cell>
                        {status_remarks}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Payment Mode
                    </Table.Cell>
                    <Table.Cell>
                        {payment_mode}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>                    
                    <Table.Cell>
                        Relationship to the Insured
                    </Table.Cell>
                    <Table.Cell>
                        {relationship_to_insured}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Name of Payee
                    </Table.Cell>
                    <Table.Cell>
                        {payee_name}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>                    
                    <Table.Cell>
                        Payee NRIC, FIN or Passport#
                    </Table.Cell>
                    <Table.Cell>
                        {payee_nric}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Payee Date of Birth
                    </Table.Cell>
                    <Table.Cell>
                        {Moment(payee_dob).format('DD/MM/YYYY')}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>                    
                    <Table.Cell>
                        Payee Gender
                    </Table.Cell>
                    <Table.Cell>
                        {payee_gender}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Payee Nationality
                    </Table.Cell>
                    <Table.Cell>
                        {payee_country_birth}
                    </Table.Cell>
                </Table.Row>                
            </Table.Body>
        </Table>
    );
}

export default ClaimDetailPaneOI;