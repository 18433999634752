import React from 'react';
import { Segment, Grid, Message } from 'semantic-ui-react';

import FormsClaimsClosed from '../../../forms/claim/closed';

const ClaimClosedActionControl = (props) => {
    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Segment>
                        <Message warning>
                            <p>
                                This claim will be updated to CLOSED Status
                            </p>
                            <p>
                                No emails will be sent to the claimant regarding this status
                            </p>
                        </Message>
                        <FormsClaimsClosed {...props} />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default ClaimClosedActionControl;
