import React from 'react'
import { Grid, Segment, Header, Tab } from 'semantic-ui-react'

import ClaimDetailPaneDetails from './claim_detail_pane_details';
import ClaimDetailPaneEndorsement from './claim_detail_endorsement';
import ClaimDetailPaneOI from './claim_detail_pane_oi';

const ClaimDetailPane = (props) => {
    return (
        <Tab.Pane>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Header as='h4' attached='top'>
                            Claim Information
                        </Header>
                        <Segment attached>
                            <ClaimDetailPaneDetails claimData={props.claimData} />
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Header as='h4' attached='top'>
                            Endorsement Information
                        </Header>
                        <Segment attached>
                            <ClaimDetailPaneEndorsement claimData={props.claimData} />
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Header as='h4' attached='top'>
                            Other Information
                        </Header>
                        <Segment attached>
                            <ClaimDetailPaneOI claimData={props.claimData} />
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Tab.Pane>
    );
}

export default ClaimDetailPane;