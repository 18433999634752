import { 
    GET_CFG_PAYMENT_OPTIONS_SUCCESS,
    // GET_CFG_PAYMENT_OPTIONS_ERROR,
    GET_CFG_REMARK_OPTIONS_SUCCESS,
    // GET_CFG_REMARK_OPTIONS_ERROR,
    GET_CFG_BENEFIT_ITEMS_SUCCESS,
    // GET_CFG_BENEFIT_ITEMS_ERROR,
    GET_CFG_STATUS_REMARK_OPTIONS_SUCCESS,
    // GET_CFG_STATUS_REMARK_OPTIONS_ERROR,
    GET_CFG_BENEFIT_SCHEME_SUCCESS,
    // GET_CFG_BENEFIT_SCHEME_ERROR,
    GET_CFG_CPF_HOSPITAL_LIST_SUCCESS,
    // GET_CFG_CPF_HOSPITAL_LIST_ERROR,
    GET_CFG_SCHOOL_PAYMENT_OPTIONS_SUCCESS,
    // GET_CFG_SCHOOL_PAYMENT_OPTIONS_ERROR,
} from '../actions';

const initialState = {
    paymentOptions: [],
    remarkOptions: [],
    benefitSchemeItems: [],
    benefitScheme: null,
    statusRemarkOptions: {},
    cpfHospitalListOptions: [],
    schoolPaymentOptions: [],
    error: false,
    errorObject: null
};

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_CFG_BENEFIT_SCHEME_SUCCESS:
            return Object.assign({}, state, {benefitScheme: action.payload.data.benefitScheme});
        case GET_CFG_STATUS_REMARK_OPTIONS_SUCCESS:
            return Object.assign({}, state, {statusRemarkOptions: action.payload.data.statusRemarkOptions});
        case GET_CFG_PAYMENT_OPTIONS_SUCCESS:
            return Object.assign({}, state, {paymentOptions: action.payload.data.paymentOptions});
        case GET_CFG_REMARK_OPTIONS_SUCCESS:
            return Object.assign({}, state, {remarkOptions: action.payload.data.remarkOptions});
        case GET_CFG_BENEFIT_ITEMS_SUCCESS:
            return Object.assign({}, state, {benefitSchemeItems: action.payload.data.benefitSchemeItems});
        case GET_CFG_CPF_HOSPITAL_LIST_SUCCESS:
            return Object.assign({}, state, {cpfHospitalListOptions: action.payload.data.cpfHospitalListOptions});
        case GET_CFG_SCHOOL_PAYMENT_OPTIONS_SUCCESS:
            return Object.assign({}, state, {schoolPaymentOptions: action.payload.data.schoolPaymentOptions});
        default:
            return state;
    }
}