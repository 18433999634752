import React from 'react';
import { Table } from 'semantic-ui-react'
import _ from 'lodash';

import BatchResultListItem from './result_list_item';

const BatchResultListTable = (props) => {
    return (
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>ID</Table.HeaderCell>
                    <Table.HeaderCell>Batch Name</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Run Date Time</Table.HeaderCell>
                    <Table.HeaderCell>Number of Claims Processed</Table.HeaderCell>
                    <Table.HeaderCell>Generate DBS Payment File (For Testing)</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
            {
                _.map(props.batchList, (batch) => {
                    return <BatchResultListItem batch={batch} key={batch.id} generatingId={props.generatingId} generatingDBSPaymentFiles={props.generatingDBSPaymentFiles} generateDBSPaymentFile={() => props.generateDBSPaymentFile(batch)}/>
                })
            }
            {
                (props.batchList.length === 0)
                ?
                (
                    <Table.Row><Table.Cell colSpan={5}>No Batch Records To Display</Table.Cell></Table.Row>
                )
                :
                null
            }
            </Table.Body>
        </Table>
    );
}

export default BatchResultListTable;