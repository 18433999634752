import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, Grid, Divider, Message } from 'semantic-ui-react'

import FormsInputsText from '../inputs/text/index';
import NewUserSchema from './validation';

class FormsUserAdd extends Component {
    state = {
        error: false,
        errorMessage: null,
        success: false
    }
    renderSubmissionResult() {
        if (this.state.success === true) {
            return (
                <Message positive>
                    <Message.Header>User Created Successfully!</Message.Header>
                </Message>
            );
        }
        if (this.state.error === true) {
            return (
                <Message negative>
                    <Message.Header>We're sorry - there was an issue</Message.Header>
                    <p>
                        { this.state.errorMessage }
                    </p>
                </Message>
            );
        }
    }
    render() {
        return (
            <Formik
                initialValues={{ 
                    person_name: '',
                    username: '',
                    password: '',
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={NewUserSchema}
                onSubmit={(values, actions) => {
                    console.log(values, actions);
                    this.setState({error: false, errorMessage: null, success: false});
                    this.props.submitCreateNewUser(
                        {accessToken: this.props.user.accessToken, data: values},
                        (result, error) => {
                            if (result) {
                                this.setState({error: false, errorMessage: null, success: true});
                            }
                            if (error) {
                                actions.setSubmitting(false);
                                this.setState({error: true, errorMessage: error.errorMessage});
                            }
                        }
                    );
                }}
                render={({
                    values,
                    errors,
                    status,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <Form style={{padding: 20}}>
                        <Grid container columns={2}>
                            <Grid.Column width={16}>
                                <Divider horizontal>
                                    Company Basic Information
                                </Divider>
                            </Grid.Column>
                            <Grid.Column>
                                <Field 
                                    name="person_name" 
                                    label="Name" 
                                    component={FormsInputsText} 
                                    secondaryLabel=""
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Field 
                                    name="username" 
                                    label="Email" 
                                    component={FormsInputsText} 
                                    secondaryLabel=""
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Field 
                                    name="password" 
                                    label="Initial Password" 
                                    component={FormsInputsText} 
                                    secondaryLabel=""
                                />
                            </Grid.Column>
                            <Grid.Column width={16}>
                                <Button 
                                    loading={isSubmitting}
                                    type="submit" 
                                    primary 
                                    disabled={isSubmitting}
                                    fluid
                                    size="big"
                                >
                                    Create New User
                                </Button>
                            </Grid.Column>
                            <Grid.Column width={16}>
                                {this.renderSubmissionResult()}
                            </Grid.Column>
                        </Grid>
                    </Form>
                )}
            />
        );
    }
}

export default FormsUserAdd;