import React from "react";
import _ from "lodash";
import { Button, List, Grid, Tab } from "semantic-ui-react";
import moment from "moment";

const EobBenefitItemsControl = (props) => {
  return (
    <Tab.Pane>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <List divided verticalAlign="middle">
              {_.map(props.adjEobItems, (item) => {
                return (
                  <List.Item key={item.uuid} style={{ padding: "3% 0" }}>
                    <List.Content style={{display: 'flex', justifyContent: 'space-between'}}>
                      <div style={{width: '100%'}}>
                        <b>{item.schemeData.description}</b>
                        <div style={{ marginTop: "1%", marginBottom: "2%" }}>
                          {moment(item.fromDate).format("DD/MM/YYYY")} -{" "}
                          {moment(item.toDate).format("DD/MM/YYYY")} ( Q:
                          {item.quantity}, {item.payableQuantity} )
                        </div>
                        <div
                          style={{
                            marginBottom: "2%",
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <div>(I){" "}{item.totalIncurredAmount}</div>
                          <div>
                            (P){" "}<b>{item.totalPayableAmount}</b>
                          </div>
                          <div>
                            (R){" "}{item.amountDeclined}
                          </div>
                        </div>

                        <div style={{ marginBottom: "2%" }}>{item.remark}</div>
                      </div>
                      <div>
                        <Button
                          onClick={() =>
                            props.removeEobItemLocally(item.uuid, props.claimId)
                          }
                        >
                          Remove
                        </Button>
                      </div>
                    </List.Content>
                  </List.Item>
                );
              })}
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Tab.Pane>
  );
};

export default EobBenefitItemsControl;
