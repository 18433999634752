import * as Yup from 'yup';
import { yupToFormErrors } from 'formik';

const ReviewPendingRejectedFormSchema = Yup.object().shape({
    updateBatchClaimsData: Yup.array().of(
        Yup.object().shape({
            isRejected: Yup.boolean(),
            caseId: Yup.string().required(),
            claimId: Yup.string().required(),
            mhcComment: Yup.string().when(
                'isRejected', {
                    is: true,
                    then: Yup.string().trim().required(),
                    else: Yup.string().max(0)
                }
            )
        })
    ).required(),
});

export default ReviewPendingRejectedFormSchema;