import React, { Component } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { Button, Grid, Message } from "semantic-ui-react";
import { Link } from "react-router-dom";
import * as JSZip from "jszip";
import FS from "file-saver";
import _ from "lodash";

import FormsInputsText from "../../inputs/text";
import FormsInputsSingleCheckbox from "../../inputs/singlecheckbox";
import MhcReviewFormSchema from "./validate";
import { asyncForEach } from "../../../../utils/async_for_each";
import { imageBolb } from "../../../../utils/get_image_blob";

const getBatchClaimsInitialValue = batchClaims => {
  const results = [];

  batchClaims.forEach(claim => {
    if (claim.include_in_batch === 1)
      results.push({
        isRejected: false,
        caseId: claim.case_id,
        claimId: claim.claim_id,
        mhcComment: ""
      });
  });
  return results;
};

const getPaidClaims = batchClaims => {
  return batchClaims.filter(claim => {
    console.log(claim);
    return claim.claim_status === "7";
  });
};

class FormsBatchIncomeReviewForm extends Component {
  state = {
    isDownloading: false,
    isDownloadSuccess: false,
    hasDownloadError: false,
    isSuccess: false,
    hasError: false,
    errorMessage: null
  };

  renderFormSubmissionResultMessage = () => {
    if (this.state.isSuccess === true) {
      return (
        <Message positive>
          Processed Successfully. Please refresh this page to see the changes.
        </Message>
      );
    }
    if (this.state.hasError === true) {
      return (
        <Message negative>
          There was an error processing. {this.state.errorMessage}
        </Message>
      );
    }
    return null;
  };

  renderDownloadEOBFilesMessage = () => {
    if (this.state.isDownloadSuccess === true) {
      return <Message positive>Download Successfully.</Message>;
    }
    if (this.state.hasDownloadError === true) {
      return <Message negative>Unable to download file.</Message>;
    }
    return null;
  };

  downloadEOB = (paidClaims, e) => {
    e.preventDefault();

    let zipFile: JSZip = new JSZip();

    this.setState({ isDownloading: true }, () => {
      this.props.getEobFilesList(paidClaims, (res, err) => {
        if (res) {
          const start = async () => {
            await asyncForEach(res.data.fileURLs, async each => {
              await imageBolb("GET", each.fileUrl, each.claimId).then(
                function(response) {
                  zipFile.file(response.fileName, response.blob, {
                    binary: true
                  });
                },
                function(e) {
                  console.log(e);
                }
              );
            });
            this.setState({ isDownloading: false }, () => {
              if (!_.isEmpty(zipFile.files)) {
                this.setState({ isDownloadSuccess: true });
                zipFile.generateAsync({ type: "blob" }).then(
                  function callback(blob) {
                    FS.saveAs(blob, "EOB.zip");
                  },
                  function(e) {
                    console.log(e);
                  }
                );
              } else {
                this.setState({
                  hasDownloadError: true
                });
              }
            });
          };
          start();
        }
      });
    });
  };

  render() {
    return (
      <Formik
        initialValues={{
          paidClaims: getPaidClaims(this.props.batchClaims),
          updateBatchClaimsData: getBatchClaimsInitialValue(
            this.props.batchClaims
          )
        }}
        onSubmit={(values, actions) => {
          this.setState({
            isSuccess: false,
            hasError: false,
            errorMessage: null
          });
          // console.log(values, actions);
          values.batchName = this.props.batchName;
          values.batchId = this.props.batchId;
          const { accessToken } = this.props.user;
          this.props.submitIncomeReviewBatchResults(
            { data: values, accessToken },
            (result, error) => {
              console.log(result, error);
              if (result) {
                this.setState({
                  isSuccess: true,
                  hasError: false,
                  errorMessage: null
                });
              }
              if (error) {
                console.log(error);
                actions.setSubmitting(false);
                this.setState({
                  isSuccess: false,
                  hasError: true,
                  errorMessage: error.message
                });
              }
            }
          );
        }}
        validationSchema={MhcReviewFormSchema}
        render={({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => (
          <Form>
            <Grid>
              <Grid.Row>
                <Grid.Column width={1}>Reject</Grid.Column>
                <Grid.Column width={2}>Case Id</Grid.Column>
                <Grid.Column width={2}>Claim Id</Grid.Column>
                <Grid.Column width={11}>Income Comment (If Reject)</Grid.Column>
              </Grid.Row>
            </Grid>
            <FieldArray
              name="updateBatchClaimsData"
              render={arrayHelpers => (
                <Grid>
                  {values.updateBatchClaimsData.map((claim, index) => (
                    <Grid.Row key={index} verticalAlign="middle">
                      <Grid.Column width={1} textAlign="center">
                        <Field
                          name={`updateBatchClaimsData[${index}].isRejected`}
                          label=""
                          component={FormsInputsSingleCheckbox}
                          secondaryLabel=""
                        />
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Link
                          to={`/app/database/case/${claim.caseId}`}
                          target="_blank"
                        >
                          {claim.caseId}
                        </Link>
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Link
                          to={`/app/database/claim/${claim.claimId}`}
                          target="_blank"
                        >
                          {claim.claimId}
                        </Link>
                      </Grid.Column>
                      <Grid.Column width={11}>
                        <Field
                          name={`updateBatchClaimsData[${index}].mhcComment`}
                          label=""
                          component={FormsInputsText}
                          secondaryLabel=""
                          disabled={!claim.isRejected}
                        />
                        <ErrorMessage name={`updateBatchClaimsData[${index}].mhcComment`} render={(error) => <div className="text-red" >{error}</div>} />
                      </Grid.Column>
                    </Grid.Row>
                  ))}
                </Grid>
              )}
            />

            {values.paidClaims.length > 0 ? (
              <Grid columns={1}>
                <Grid.Column>
                  <Button
                    color="green"
                    disabled={this.state.isDownloading}
                    fluid
                    loading={this.state.isDownloading}
                    onClick={this.downloadEOB.bind(this, values.paidClaims)}
                  >
                    Download all EOBs of Paid Claims with Paid Status
                  </Button>
                </Grid.Column>
                <Grid.Column>
                  {this.renderDownloadEOBFilesMessage()}
                </Grid.Column>
              </Grid>
            ) : null}

            <Grid columns={1}>
              <Grid.Column>
                <Button
                  type="submit"
                  color="red"
                  disabled={isSubmitting}
                  fluid
                  loading={isSubmitting}
                >
                  Promote to Pay
                </Button>
              </Grid.Column>
              <Grid.Column>
                {this.renderFormSubmissionResultMessage()}
              </Grid.Column>
            </Grid>
          </Form>
        )}
      />
    );
  }
}

export default FormsBatchIncomeReviewForm;
