import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Grid, Segment, Header, Statistic, Button } from "semantic-ui-react";

import { getCheckClaims, getTotalCheckClaims } from "../../actions";
import CheckClaimTable from "../../component/checkclaims/list/check_claim_table";

class CheckNewClaimPage extends Component {
   constructor(props) {
      super(props);

      this.state = {
         activeTab: 0
      };
   }

   componentDidMount() {
      const { accessToken } = this.props.user;
      this.props.getCheckClaims({ accessToken, claimType: "New,Follow-Up".split(",") });
      this.props.getTotalCheckClaims({ accessToken, claimType: "New,Follow-Up".split(",") });
   }

   render() {
      return (
         <Container>
            <Grid>
               <Grid.Row>
                  <Grid.Column>
                     <Header attached="top">Quick Statistics</Header>
                     <Segment attached>
                        <Grid columns={4}>
                           <Grid.Column>
                              <Statistic>
                                 <Statistic.Value>{this.props.claimCount}</Statistic.Value>
                                 <Statistic.Label>Claims To Be Check</Statistic.Label>
                              </Statistic>
                           </Grid.Column>
                        </Grid>
                     </Segment>
                  </Grid.Column>
               </Grid.Row>
               <Grid.Row>
                  <Grid.Column>
                     <Button.Group basic>
                        <Button
                           active={this.state.activeTab === 0}
                           onClick={() => {
                              this.setState({ activeTab: 0 });
                              const { accessToken } = this.props.user;
                              this.props.getCheckClaims({
                                 accessToken,
                                 claimType: "New,Follow-Up".split(",")
                              });
                           }}
                        >
                           All
                        </Button>
                        <Button
                           active={this.state.activeTab === 1}
                           onClick={() => {
                              this.setState({ activeTab: 1 });
                              const { accessToken } = this.props.user;
                              this.props.getCheckClaims({
                                 accessToken,
                                 claimType: "New"
                              });
                           }}
                        >
                           New Claim ({this.props.allClaims.filter(claim => claim.claim_type === "New").length})
                        </Button>
                        <Button
                           active={this.state.activeTab === 2}
                           onClick={() => {
                              this.setState({ activeTab: 2 });
                              const { accessToken } = this.props.user;
                              this.props.getCheckClaims({
                                 accessToken,
                                 claimType: "Follow-Up"
                              });
                           }}
                        >
                           Follow Up Claim (
                           {this.props.allClaims.filter(claim => claim.claim_type === "Follow-Up").length})
                        </Button>
                     </Button.Group>
                  </Grid.Column>
               </Grid.Row>
               <Grid.Row>
                  <Grid.Column>
                     <CheckClaimTable claims={this.props.claims} />
                  </Grid.Column>
               </Grid.Row>
            </Grid>
         </Container>
      );
   }
}

const mapStateToProps = state => {
   return {
      user: state.user.data,
      claims: state.checkclaim.claims,
      allClaims: state.checkclaim.allClaims,
      claimCount: state.checkclaim.claimCount
   };
};

export default connect(
   mapStateToProps,
   { getCheckClaims, getTotalCheckClaims }
)(CheckNewClaimPage);
