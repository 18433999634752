import React from 'react';
import {Table} from 'semantic-ui-react';
import Moment from 'moment';
import {Link} from 'react-router-dom';

import ClaimsStatusLabel from '../../../claims/status_label';

const ClaimDetailPaneDetails = (props) => {
    const {
        case_id,
        claim_id,
        claim_processing_end_date,
        claim_processing_start_date,
        claim_received_date,
        claim_status,
        claim_type,
        total_amount,
        created_at,
        updated_at,
        internal_claim_remarks,
    } = props.claimData;
    return (
        <Table basic compact="very" fixed>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        Claim ID
                    </Table.Cell>
                    <Table.Cell>
                        <Link to={`/app/database/claim/${claim_id}`} target="_blank">
                            {claim_id}
                        </Link>
                    </Table.Cell>
                </Table.Row>
                
                <Table.Row>                    
                    <Table.Cell>
                        Case ID
                    </Table.Cell>
                    <Table.Cell>
                        <Link to={`/app/database/case/${case_id}`} target="_blank">
                            {case_id}
                        </Link>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Internal Remarks
                    </Table.Cell>
                    <Table.Cell>
                        {internal_claim_remarks}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Claim Status
                    </Table.Cell>
                    <Table.Cell>
                        <ClaimsStatusLabel status={claim_status} />
                    </Table.Cell>
                </Table.Row>
                <Table.Row>                    
                    <Table.Cell>
                        Claim Received Date
                    </Table.Cell>
                    <Table.Cell>
                        {Moment(claim_received_date).format('DD/MM/YYYY')}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Claim Processing Start Date
                    </Table.Cell>
                    <Table.Cell>
                        {Moment(claim_processing_start_date).format('DD/MM/YYYY')}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>                    
                    <Table.Cell>
                        Claim Processing End Date
                    </Table.Cell>
                    <Table.Cell>
                        {(claim_processing_end_date) ? Moment(claim_processing_end_date).format('DD/MM/YYYY') : 'N/A'}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Claim Type
                    </Table.Cell>
                    <Table.Cell>
                        {claim_type}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Total Amount
                    </Table.Cell>
                    <Table.Cell>
                        {total_amount}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Created At
                    </Table.Cell>
                    <Table.Cell>
                        {Moment(created_at).format('DD/MM/YYYY hh:mm:ss A')}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>                    
                    <Table.Cell>
                        Updated At
                    </Table.Cell>
                    <Table.Cell>
                        {Moment(updated_at).format('DD/MM/YYYY hh:mm:ss A')}
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
}

export default ClaimDetailPaneDetails;