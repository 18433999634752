import React, { Component } from 'react';
import { Container, Grid, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

import {
    getBatchAllList,
    getSearchBatchList,
    generateDBSPaymentFile
} from '../../actions';
import BatchResultListTable from '../../component/batch/result_list_table';
import RecordSearchBar from '../../component/batch/search_bar';

const MySwal = withReactContent(Swal);

class BatchListPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            generatingId: null,
            generatingDBSPaymentFiles: false
        };
    }
    componentDidMount() {
        const { accessToken } = this.props.user;
        this.props.getBatchAllList(accessToken);
    }
    generateDBSPaymentFile(batch) {
        this.setState({generatingDBSPaymentFiles: true, generatingId: batch.id}, () => {
            const { accessToken } = this.props.user;
            this.props.generateDBSPaymentFile({accessToken, data: {
                batchId: batch.id,
                batchName: batch.batch_name
            }}, async (result, error) => {
                if(error) {
                    console.log("generateDBSPaymentFile error : ", error);
                    this.setState({generatingDBSPaymentFiles: false}, () => {
                        MySwal.fire({
                            icon: "error",
                            title: "Error",
                            text: "Failed to generate DBS payment file, please try again later!",
                        });
                    });
                }
                if(result) {
                    let fileData = result && result.data && result.data.DBSPaymentFile && result.data.DBSPaymentFile.fileBuffer && result.data.DBSPaymentFile.fileBuffer.data ? result.data.DBSPaymentFile.fileBuffer.data : "";
                    if (!fileData) {
                        this.setState({generatingDBSPaymentFiles: false}, () => {
                            MySwal.fire({
                                icon: "error",
                                title: "Error",
                                text: "Failed to generate DBS payment file, please try again later!",
                            });
                        });
                    } else {
                        const fileName = result.data.DBSPaymentFile.fileName || `DBS_Payment_File_${batch.batch_name}.csv`;

                        let json = JSON.stringify(fileData);
                        let buffer = Buffer.from(JSON.parse(json));
                        let blob = new Blob([buffer], { type: 'application/vnd.ms-excel' });

                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = fileName;

                        link.click();
                        this.setState({ generatingDBSPaymentFiles: false });
                    }
                }
            })
        })

    }
    render() {
        return (
            <Container>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment>
                                <RecordSearchBar
                                    getSearchBatchList={this.props.getSearchBatchList}
                                    user={this.props.user}
                                />
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment>
                                <BatchResultListTable batchList={this.props.batchList} generatingId={this.state.generatingId} generatingDBSPaymentFiles={this.state.generatingDBSPaymentFiles} generateDBSPaymentFile={this.generateDBSPaymentFile.bind(this)}/>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        batchList: state.batch.listData,
        DBSPaymentFile: state.batch.DBSPaymentFile,
    }
}

export default connect(mapStateToProps, {
    getBatchAllList,
    getSearchBatchList,
    generateDBSPaymentFile
})(BatchListPage);