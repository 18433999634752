import React, { Component } from 'react';
import { Dropdown, Header, Message } from 'semantic-ui-react'

class FormsInputsDropdownAddition extends Component {
    state = { 
        options: this.props.options,
        selectedValue: this.props.value || ""
    }
    handleOnChange= (e, {value}) => {
        this.props.form.setFieldValue(this.props.field.name, value)
    }
    handleAddItem = (e, {value}) => {
        this.setState({
            options: [{ text: value, value }, ...this.state.options]
        })
        this.props.form.setFieldValue(this.props.field.name, value)
    }
    render() {
        const { label, secondaryLabel, form } = this.props;
        const { value, name } = this.props.field;
        const { options } = this.state;
        return (
            <div className="form-input-group">
                <div>
                    <Header sub>{label}</Header>
                    {(secondaryLabel) ? <Header.Subheader>{secondaryLabel}</Header.Subheader>: ''}
                </div>
                <Dropdown 
                    value={value}
                    fluid 
                    search
                    selection 
                    allowAdditions 
                    options={options} 
                    onAddItem={this.handleAddItem}
                    onChange={this.handleOnChange}
                    disabled={this.props.disabled}
                />
                {
                    (form.errors[name]) && 
                    (
                        <Message negative>
                            {form.errors[name]}
                        </Message>
                    )
                }
            </div>
        );
    }
}

export default FormsInputsDropdownAddition;