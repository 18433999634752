import React from 'react';
import { Header, Segment, Table } from 'semantic-ui-react';
import Moment from 'moment';

import ClaimsStatusLabel from '../../claims/status_label';

const RecordClaimViewClaimDetailsClaim = (props) => {
    const {
        case_id,
        claim_id,
        claim_processing_end_date,
        claim_processing_start_date,
        claim_received_date,
        claim_status,
        claim_type,
        total_amount,
        created_at,
        updated_at,
        status_remarks,
        internal_claim_remarks,
    } = props.claimData;
    return (
        <div>
            <Header as='h4' attached="top">
                Claim Information
            </Header>
            <Segment attached>
                <Table basic compact="very" fixed>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                Claim ID
                            </Table.Cell>
                            <Table.Cell>
                                {claim_id}
                            </Table.Cell>
                            <Table.Cell>
                                Case ID
                            </Table.Cell>
                            <Table.Cell>
                                {case_id}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Claim Status
                            </Table.Cell>
                            <Table.Cell>
                                <ClaimsStatusLabel status={claim_status} />
                            </Table.Cell>
                            <Table.Cell>
                                Claim Received Date
                            </Table.Cell>
                            <Table.Cell>
                                {Moment(claim_received_date).format('DD/MM/YYYY')}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Claim Processing Start Date
                            </Table.Cell>
                            <Table.Cell>
                                {Moment(claim_processing_start_date).format('DD/MM/YYYY')}
                            </Table.Cell>
                            <Table.Cell>
                                Claim Processing End Date
                            </Table.Cell>
                            <Table.Cell>
                                {(claim_processing_end_date) ? Moment(claim_processing_end_date).format('DD/MM/YYYY') : 'N/A'}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Claim Type
                            </Table.Cell>
                            <Table.Cell>
                                {claim_type}
                            </Table.Cell>
                            <Table.Cell>
                                Total Amount
                            </Table.Cell>
                            <Table.Cell>
                                {total_amount}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Created At
                            </Table.Cell>
                            <Table.Cell>
                                {Moment(created_at).format('DD/MM/YYYY hh:mm:ss A')}
                            </Table.Cell>
                            <Table.Cell>
                                Updated At
                            </Table.Cell>
                            <Table.Cell>
                                {Moment(updated_at).format('DD/MM/YYYY hh:mm:ss A')}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Remarks
                            </Table.Cell>
                            <Table.Cell>
                                {status_remarks}
                            </Table.Cell>
                            <Table.Cell>
                                Internal Remarks
                            </Table.Cell>
                            <Table.Cell>
                                {internal_claim_remarks}
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
        </div>
    );
}

export default RecordClaimViewClaimDetailsClaim;