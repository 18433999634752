import React, {Component} from 'react';
import { Grid, Segment, Header } from 'semantic-ui-react';

import FormsReportsTypeTwo from '../../forms/reports/type_two';
import ReportTypeTwoResultsDisplayItem from './results_display';

class ReportTypeTwo extends Component {
    render() {
        return (
            <Grid.Row>
                <Grid.Column>
                    <Header attached="top" block as="h5">
                        Billing Report Options
                    </Header>
                    <Segment attached>
                        <FormsReportsTypeTwo
                            submitGetReportTwoData={this.props.submitGetReportTwoData}
                            user={this.props.user}
                        />
                    </Segment>
                </Grid.Column>
                {/* Hide by Cer Tong 4/4/2019 (in case user want to display on screen) */}
                {/* <Grid.Column>
                    <Header attached="top" block as="h5">
                        Results
                    </Header>
                    <Segment attached>
                        <ReportTypeTwoResultsDisplayItem
                            reportData={this.props.reportData}
                        />
                    </Segment>
                </Grid.Column> */}
            </Grid.Row>
        );
    }
}

export default ReportTypeTwo;