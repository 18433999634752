import React, { Component } from 'react';
import { Container, Grid, Segment, Message } from 'semantic-ui-react';

class MainPage extends Component {
    render() {
        return (
            <Container>
                <Grid>
                    <Grid.Column>
                        <Segment textAlign="center">
                            <Message positive>
                                Welcome to MIGHTY-Adj System
                            </Message>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Container>
        );
    }
}

export default MainPage;