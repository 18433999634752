import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Grid,
  Segment,
  Header,
  Statistic,
  Button,
  Pagination,
  Loader,
  Dimmer,
} from "semantic-ui-react";

import {
  getAjudicateClaims,
  getAjudicateClaimsZoneSummary,
  getEobPaymentOptions,
  getEobRemarkOptions,
  getBenefitSchemeItems,
  getStatusRemarkOptions,
  getSchoolPaymentOptions,
  resetAdjudicationList,
} from "../../actions";
import AdjudicateClaimTable from "../../component/adjudicate/list/adjudicate_claim_table";

class AdjudicateRecordPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
      activePage: 1,
      isLoading: true,
    };
  }

  onPageChange = (event, data) => {
    const { accessToken } = this.props.user;
    const offSet = data.activePage - 1;

    this.setState({ activePage: data.activePage }, () => {
      this.props.getAjudicateClaims({
        accessToken,
        greenZone: this.state.activeTab === 1,
        orangeZone: this.state.activeTab === 2,
        amberZone: this.state.activeTab === 3,
        redZone: this.state.activeTab === 4,
        offSet: offSet * 10,
      });
    });
  };

  componentDidMount() {
    const { accessToken } = this.props.user;
    this.props.resetAdjudicationList();
    this.props.getAjudicateClaims({ accessToken });
    this.props.getAjudicateClaimsZoneSummary({ accessToken });
    // to redo at main page later
    this.props.getEobPaymentOptions({ accessToken });
    this.props.getEobRemarkOptions({ accessToken });
    this.props.getBenefitSchemeItems({ accessToken });
    this.props.getStatusRemarkOptions({ accessToken });
    this.props.getSchoolPaymentOptions({ accessToken });
  }
  render() {
    const totalClaim =
      this.props.totalClaim &&
      this.props.totalClaim.length > 0 &&
      this.props.totalClaim[0].total;

    // console.log("[this.props.totalClaim]", this.props.totalClaim);
    return (
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header attached="top">Quick Statistics</Header>
              <Segment attached>
                <Grid columns={4}>
                  <Grid.Column>
                    {this.props.totalClaim &&
                    this.props.totalClaim.length > 0 ? (
                      <Statistic>
                        <Statistic.Value>{totalClaim}</Statistic.Value>
                        <Statistic.Label>
                          Claims To Be Processed
                        </Statistic.Label>
                      </Statistic>
                    ) : (
                      <div style={{ minHeight: "80px" }}>
                        <Dimmer active inverted>
                          <Loader inverted>Loading</Loader>
                        </Dimmer>
                      </div>
                    )}
                  </Grid.Column>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button.Group basic>
                <Button
                  active={this.state.activeTab === 0}
                  onClick={() => {
                    this.props.resetAdjudicationList();
                    this.setState({ activeTab: 0, activePage: 1 });
                    const { accessToken } = this.props.user;
                    this.props.getAjudicateClaims({
                      accessToken,
                      greenZone: false,
                      orangeZone: false,
                      amberZone: false,
                      redZone: false,
                      offSet: 0,
                    });
                  }}
                >
                  All
                </Button>
                <Button
                  active={this.state.activeTab === 1}
                  onClick={() => {
                    this.props.resetAdjudicationList();
                    this.setState({ activeTab: 1, activePage: 1 });
                    const { accessToken } = this.props.user;
                    this.props.getAjudicateClaims({
                      accessToken,
                      greenZone: true,
                      orangeZone: false,
                      amberZone: false,
                      redZone: false,
                      offSet: 0,
                    });
                  }}
                >
                  Green (
                  {this.props.claimsZoneSummary[0]
                    ? this.props.claimsZoneSummary[0].count
                    : 0}
                  )
                </Button>
                <Button
                  active={this.state.activeTab === 2}
                  onClick={() => {
                    this.props.resetAdjudicationList();
                    this.setState({ activeTab: 2, activePage: 1 });
                    const { accessToken } = this.props.user;
                    this.props.getAjudicateClaims({
                      accessToken,
                      greenZone: false,
                      orangeZone: true,
                      amberZone: false,
                      redZone: false,
                      offSet: 0,
                    });
                  }}
                >
                  Orange (
                  {this.props.claimsZoneSummary[1]
                    ? this.props.claimsZoneSummary[1].count
                    : 0}
                  )
                </Button>
                <Button
                  active={this.state.activeTab === 3}
                  onClick={() => {
                    this.props.resetAdjudicationList();
                    this.setState({ activeTab: 3, activePage: 1 });
                    const { accessToken } = this.props.user;
                    this.props.getAjudicateClaims({
                      accessToken,
                      greenZone: false,
                      orangeZone: false,
                      amberZone: true,
                      redZone: false,
                      offSet: 0,
                    });
                  }}
                >
                  AMBER (
                  {this.props.claimsZoneSummary[2]
                    ? this.props.claimsZoneSummary[2].count
                    : 0}
                  )
                </Button>
                <Button
                  active={this.state.activeTab === 4}
                  onClick={() => {
                    this.props.resetAdjudicationList();
                    this.setState({ activeTab: 4, activePage: 1 });
                    const { accessToken } = this.props.user;
                    this.props.getAjudicateClaims({
                      accessToken,
                      greenZone: false,
                      orangeZone: false,
                      amberZone: false,
                      redZone: true,
                      offSet: 0,
                    });
                  }}
                >
                  Red (
                  {this.props.claimsZoneSummary[3]
                    ? this.props.claimsZoneSummary[3].count
                    : 0}
                  )
                </Button>
              </Button.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {this.props.totalClaim && this.props.totalClaim.length > 0 ? (
                <React.Fragment>
                  <AdjudicateClaimTable
                    claims={this.props.claims}
                    offSet={this.state.activePage - 1}
                  />
                  {/* <Pagination
                    defaultActivePage={1}
                    activePage={this.state.activePage}
                    onPageChange={this.onPageChange}
                    totalPages={Math.ceil(totalClaim / 10)}
                  /> */}
                </React.Fragment>
              ) : (
                <Dimmer active inverted>
                  <Loader inverted>Loading</Loader>
                </Dimmer>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    claims: state.adjudicate.claims,
    totalClaim: state.adjudicate.totalClaim,
    claimsZoneSummary: state.adjudicate.claimsZoneSummary,
  };
};

export default connect(mapStateToProps, {
  getAjudicateClaims,
  getAjudicateClaimsZoneSummary,
  getEobPaymentOptions,
  getEobRemarkOptions,
  getBenefitSchemeItems,
  getStatusRemarkOptions,
  getSchoolPaymentOptions,
  resetAdjudicationList,
})(AdjudicateRecordPage);

/*

<Grid.Column>
    <Statistic>
        <Statistic.Value>12</Statistic.Value>
        <Statistic.Label>Days away to breach SLA</Statistic.Label>
    </Statistic>
</Grid.Column>
<Grid.Column>
    <Statistic>
        <Statistic.Value>12344_1</Statistic.Value>
        <Statistic.Label>Oldest Claim</Statistic.Label>
    </Statistic>
</Grid.Column>
<Grid.Column>
</Grid.Column>
*/
