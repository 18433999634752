import React from 'react';
import {Segment, Message} from 'semantic-ui-react';

import ClaimEobItemsControl from '../claim_eob_items_control';
import ClaimRejectedActionControl from './claim_rejected_action_control';
import ClaimPendingDocActionControl from './claim_pending_doc_action_control';
import ClaimClosedActionControl from './claim_closed_action_control';
// import ClaimPendingOthersActionControl from './claim_pending_others_action_control';

const ClaimStatusActionControl = (props) => {
    // console.log("[adjNewStatus", props.adjNewStatus);
    /** Claim status: Pre Approved */
    if (props.adjNewStatus === 70) {
        return <ClaimEobItemsControl  {...props} />
    }
    if (props.adjNewStatus === 2) {
        return <ClaimEobItemsControl  {...props} />
    }
     /** Claim status: Pre Rejected */
    if (props.adjNewStatus === 71) {
        return <ClaimRejectedActionControl {...props} rejectionOptions={props.statusRemarkOptions["-1"]} />
    }
    // if (props.adjNewStatus === -1) {
    if (props.adjNewStatus === 54) {
        return <ClaimRejectedActionControl {...props} rejectionOptions={props.statusRemarkOptions["-1"]} />
    }
    // if (props.adjNewStatus === 3 || props.adjNewStatus === 4) {
    if (props.adjNewStatus === 53) {
        return <ClaimPendingDocActionControl {...props} pendingReviewOptions={props.statusRemarkOptions["53"]} pendingOptions={props.statusRemarkOptions["3"]} />
    }
    // if (props.adjNewStatus === 51 || props.adjNewStatus === 52) {
    //     return <ClaimPendingOthersActionControl {...props} />
    // }
    if (props.adjNewStatus === 5) {
        return <ClaimClosedActionControl {...props} />
    }
    return (
        <Segment>
            <Message info>
                <p>
                    Please change the claim status above to show a appropriate update control.
                </p>
            </Message>
        </Segment>
    );
}

export default ClaimStatusActionControl;