import React from 'react';
import { Table, Message } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Moment from 'moment';

import ClaimsStatusLabel from '../../claims/status_label';

const ReportTypeOneResultsDisplayItem = (props) => {
    const {
        id,
        case_id,
        claim_id,
        created_at,
        by_username,
        remark,
        from_status,
        to_status,
    } = props.data;
    return (
        <Table.Row key={id}>
            <Table.Cell>
                {id}
            </Table.Cell>
            <Table.Cell>
                {claim_id}
            </Table.Cell>
            <Table.Cell>
                <Link to={`/app/database/case/${case_id}`}>
                    {case_id}
                </Link>
            </Table.Cell>
            <Table.Cell>
                <ClaimsStatusLabel status={from_status} />
            </Table.Cell>
            <Table.Cell>
                <ClaimsStatusLabel status={to_status} />
            </Table.Cell>
            <Table.Cell>
                {remark}
            </Table.Cell>
            <Table.Cell>
                {by_username}
            </Table.Cell>
            <Table.Cell>
                {Moment(created_at).format('DD/MM/YYYY HH:mm')}
            </Table.Cell>
        </Table.Row>
    );
}

const ReportTypeOneResultsDisplay = (props) => {
    if (!props.reportData || props.reportData.type !== 1 || props.reportData.reportResultsList.length === 0) {
        return (
            <Message warning>
                Please select options and click generate report.
            </Message>
        );
    }
    return (
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>ID</Table.HeaderCell>
                    <Table.HeaderCell>Claim ID</Table.HeaderCell>
                    <Table.HeaderCell>Case ID</Table.HeaderCell>
                    <Table.HeaderCell>Status From</Table.HeaderCell>
                    <Table.HeaderCell>Status To</Table.HeaderCell>
                    <Table.HeaderCell>Remark</Table.HeaderCell>
                    <Table.HeaderCell>Changed By</Table.HeaderCell>
                    <Table.HeaderCell>Created At</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
            {
                _.map(props.reportData.reportResultsList, (data, index) => {
                    return <ReportTypeOneResultsDisplayItem data={data} key={index} />
                })
            }
            </Table.Body>
        </Table>
    );
}

export default ReportTypeOneResultsDisplay;