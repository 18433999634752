import React, { Component } from 'react';
import { Header, Segment, Button, Input, Grid, Message } from 'semantic-ui-react';

class RecordClaimViewClaimChangeCase extends Component {
    state = {
        isSubmitting: false,
        isSuccess: false,
        errorMessage: null,
        newCaseId: ''
    }
    submitFormToProcess = () => {
        const {accessToken} = this.props.user;
        if (this.state.newCaseId && this.state.newCaseId !== '') {
            this.setState({isSubmitting: true, isSuccess: false, errorMessage: null});
            this.props.submitRecordClaimChangeCaseId({
                data: {
                    currentClaimId: this.props.claimData.claim_id,
                    currentCaseId: this.props.claimData.case_id,
                    newCaseId: this.state.newCaseId,
                },
                accessToken
            }, (result, error) => {
                console.log(result, error);
                if (result) {
                    this.setState({isSubmitting: true, isSuccess: true, errorMessage: null});
                }
                if (error) {
                    this.setState({isSubmitting: false, errorMessage: error.errorMessage});
                }
            });
        } else {
            console.log('clicked');
        }
    }
    handleChange = (e, { name, value }) => this.setState({ newCaseId: value })
    renderSubmissionForm() {
        if (this.state.isSuccess === true) {
            return (
                <Message positive>
                    Claim has been re-tagged successfully. Please refresh this page for changes to take effect.
                </Message>
            )
        } else if (this.state.errorMessage) {
            return (
                <Message negative>
                    Error! {this.state.errorMessage}
                </Message>
            );
        }
    }
    render() {
        const { claim_status } = this.props.claimData;
        const isAllowedToChangeCaseId = (claim_status === "0" || claim_status === "3" || claim_status === "61" || claim_status === "62") ? true : false;
        if (isAllowedToChangeCaseId === false) {
            return (
                <Message negative>
                    <p>
                        Claim must be in "NEW" or "Pending" status to be able to re-tag case ID
                    </p>
                </Message>
            );
        }
        return (
            <div>
                <Header as='h4' attached="top" block>
                    Retag Claim to Another Case
                </Header> 
                <Segment attached>
                    <Grid columns={1}>
                        <Grid.Column>
                            <Input 
                                focus
                                label='New Case ID'
                                fluid 
                                onChange={this.handleChange}
                                value={this.state.newCaseId}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Button
                                fluid
                                color="red"
                                onClick={this.submitFormToProcess}
                                loading={this.state.isSubmitting}
                                disabled={this.state.isSubmitting}
                            >
                                RETAG NOW
                            </Button>
                        </Grid.Column>
                        <Grid.Column>
                            {this.renderSubmissionForm()}
                        </Grid.Column>
                    </Grid>
                </Segment>
            </div>
        );
    }
}

export default RecordClaimViewClaimChangeCase;