import React, { Component } from 'react';

import ClaimImageDisplayReceipt from './claim_image_display_receipt';
import ClaimImageDisplaySupport from './claim_image_display_support';

class ClaimImageDisplay extends Component {
    render() {
        // console.log('ClaimImageDisplay', this.props);
        return (
            <div>
                <ClaimImageDisplayReceipt receipts={this.props.receipts} />
                <ClaimImageDisplaySupport supportDocuments={this.props.supportDocuments} />
            </div>
        );
    }
}

export default ClaimImageDisplay;