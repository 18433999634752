import { 
    GET_RECORD_SEARCH_RESULT_SUCCESS,
    // GET_RECORD_SEARCH_RESULT_ERROR,
    GET_RECORD_CASE_DETAILS_SUCCESS,
    // GET_RECORD_CASE_DETAILS_ERROR,
    GET_RECORD_CLAIM_DETAILS_SUCCESS,
    // GET_RECORD_CLAIM_DETAILS_ERROR,
} from '../actions';

const initialState = {
    searchList: [],
    selectedCase: null,
    selectedClaim: null,
    selectedItem: null,
    isFetching: false,
};

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_RECORD_SEARCH_RESULT_SUCCESS:
            return Object.assign({}, state, {searchList: action.payload.data.records});
        case GET_RECORD_CASE_DETAILS_SUCCESS:
            return Object.assign({}, state, {selectedCase: action.payload.data.case});
        case GET_RECORD_CLAIM_DETAILS_SUCCESS:
            return Object.assign({}, state, {selectedClaim: action.payload.data.claim});
        default:
            return state;
    }
}