import React, { Component } from "react";
import { Formik, Form, Field, FieldArray, FastField } from "formik";
import { Button, Grid, Message } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Moment from "moment";
import * as JSZip from "jszip";
import FS from "file-saver";
import _ from "lodash";

// import FormsInputsText from '../../inputs/text';
import FormsInputDate from "../../inputs/date";
import FormsInputAllDate from "../../inputs/selectalldate";
import FormsInputsSingleCheckbox from "../../inputs/singlecheckbox";
import FormsInputsSelectAllCheckbox from "../../inputs/selectallcheckbox";
import PaymentCompleteFormSchema from "./validate";
import { asyncForEach } from "../../../../utils/async_for_each";
import { imageBolb } from "../../../../utils/get_image_blob";

const getBatchClaimsInitialValue = (batchClaims, createdAt) => {
  const results = [];

  batchClaims.forEach(claim => {
    if (claim.include_in_batch === 1) {
      const hasPaid = claim.payment_date ? true : false;
      results.push({
        toUpdateAsPaid: hasPaid,
        caseId: claim.case_id,
        claimId: claim.claim_id,
        hasPaid,
        paymentDate: hasPaid === true ? Moment(claim.payment_date) : Moment(createdAt),
        claimProcessingEndDate: Moment(claim.claim_processing_end_date),
        claimStatus: claim.claim_status
      });
    }
  });
  return results;
};

const getPaidClaims = batchClaims => {
  return batchClaims.filter(claim => {
    return claim.claim_status === "7";
  });
};

class FormsBatchPaymentCompleteForm extends Component {
  state = {
    isDownloading: false,
    isDownloadSuccess: false,
    hasDownloadError: false,
    isRegenerating: false,
    isRegenerateSuccess: false,
    hasRegenerateError: false,
    isSuccess: false,
    hasError: false,
    errorMessage: null
  };

  renderFormSubmissionResultMessage = () => {
    if (this.state.isSuccess === true) {
      return (
        <Message positive>
          Processed Successfully. Please refresh this page to see the changes.
        </Message>
      );
    }
    if (this.state.hasError === true) {
      return (
        <Message negative>
          There was an error processing. {this.state.errorMessage}
        </Message>
      );
    }
    return null;
  };

  renderDownloadEOBFilesMessage = () => {
    if (this.state.isDownloadSuccess === true) {
      return <Message positive>Download Successfully.</Message>;
    }
    if (this.state.hasDownloadError === true) {
      return <Message negative>Unable to download file.</Message>;
    }
    return null;
  };

  renderRegeneratePaymentFilesMessage = () => {
    if (this.state.isRegenerateSuccess === true) {
      return <Message positive>Regenerate Payment Files Successfully. Please refresh this page to see the changes.</Message>;
    }
    if (this.state.hasRegenerateError === true) {
      return <Message negative>Unable to Regenerate Payment Files.</Message>;
    }
    return null;
  };

  downloadEOB = (paidClaims, e) => {
    e.preventDefault();

    let zipFile: JSZip = new JSZip();

    this.setState({ isDownloading: true }, () => {
      this.props.getEobFilesList(paidClaims, (res, err) => {
        if (res) {
          const start = async () => {
            await asyncForEach(res.data.fileURLs, async each => {
              await imageBolb("GET", each.fileUrl, each.claimId).then(
                function(response) {
                  zipFile.file(response.fileName, response.blob, {
                    binary: true
                  });
                },
                function(e) {
                  console.log(e);
                }
              );
            });
            this.setState({ isDownloading: false }, () => {
              if (!_.isEmpty(zipFile.files)) {
                this.setState({ isDownloadSuccess: true });
                zipFile.generateAsync({ type: "blob" }).then(
                  function callback(blob) {
                    FS.saveAs(blob, "EOB.zip");
                  },
                  function(e) {
                    console.log(e);
                  }
                );
              } else {
                this.setState({
                  hasDownloadError: true
                });
              }
            });
          };
          start();
        }
      });
    });
  };

  regeneratePaymentFiles = (e) => {
    e.preventDefault();
    this.setState({isRegenerating: true}, () => {
      const { accessToken } = this.props.user;
      let data = {};
      data.batchId = this.props.batchId;
      data.batchName = this.props.batchName;

      this.props.regeneratePaymentFiles({accessToken, data}, (result, error) => {
        if(result) {
          this.setState({isRegenerating: false, isRegenerateSuccess: true, hasRegenerateError: false}, () => {
            this.props.getBatchDetail({accessToken, batchId: this.props.batchId})
          })
        }

        if(error) {
          this.setState({isRegenerating: false, isRegenerateSuccess: false, hasRegenerateError: true})
        }
      });
    })

  }

  render() {
    return (
      <Formik
        initialValues={{
          checkAll: false,
          allPaymentDate: Moment(this.props.createdAt),
          paidClaims: getPaidClaims(this.props.batchClaims),
          updateBatchClaimsData: getBatchClaimsInitialValue(
            this.props.batchClaims, this.props.createdAt
          )
        }}
        onSubmit={(values, actions) => {
          this.setState({
            isSuccess: false,
            hasError: false,
            errorMessage: null
          });
          // console.log(values, actions);
          values.batchName = this.props.batchName;
          values.batchId = this.props.batchId;
          const { accessToken } = this.props.user;
          this.props.submitPaidClaimsResults(
            { data: values, accessToken },
            (result, error) => {
              console.log(result, error);
              if (result) {
                this.props.getBatchDetail({accessToken, batchId: this.props.batchId})
                this.setState({
                  isSuccess: true,
                  hasError: false,
                  errorMessage: null
                });
              }
              if (error) {
                console.log(error);
                actions.setSubmitting(false);
                this.setState({
                  isSuccess: false,
                  hasError: true,
                  errorMessage: error.message
                });
              }
            }
          );
        }}
        validationSchema={PaymentCompleteFormSchema}
        render={({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => (
          <Form>
            <Grid>
              <Grid.Row>
                <Grid.Column width={3}>Update Payment Date</Grid.Column>
                <Grid.Column width={2}>Case Id</Grid.Column>
                <Grid.Column width={2}>Claim Id</Grid.Column>
                <Grid.Column width={9}>Payment Date</Grid.Column>
              </Grid.Row>
            </Grid>
            <FieldArray
              name="updateBatchClaimsData"
              render={arrayHelpers => (
                <Grid>
                  <Grid.Row verticalAlign="middle">
                    <Grid.Column width={3} textAlign="center">
                      <Field
                        name={`checkAll`}
                        label=""
                        component={FormsInputsSelectAllCheckbox}
                        targetList={values.updateBatchClaimsData}
                        targetListName="updateBatchClaimsData"
                        targetListAttr="toUpdateAsPaid"
                      />
                    </Grid.Column>
                    <Grid.Column width={2} />
                    <Grid.Column width={2} />
                    <Grid.Column width={9}>
                      <Field
                        name={`allPaymentDate`}
                        component={FormsInputAllDate}
                        targetList={values.updateBatchClaimsData}
                        targetListName="updateBatchClaimsData"
                        targetListAttr="paymentDate"
                        disabled={true}
                        // maxDate={Moment()}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  {values.updateBatchClaimsData.map((claim, index) => (
                    <Grid.Row key={index} verticalAlign="middle">
                      <Grid.Column width={3} textAlign="center">
                        {claim.hasPaid ? null : (
                          <Field
                            name={`updateBatchClaimsData[${index}].toUpdateAsPaid`}
                            label=""
                            component={FormsInputsSingleCheckbox}
                            secondaryLabel=""
                            disabled={claim.hasPaid}
                          />
                        )}
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Link
                          to={`/app/database/case/${claim.caseId}`}
                          target="_blank"
                        >
                          {claim.caseId}
                        </Link>
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Link
                          to={`/app/database/claim/${claim.claimId}`}
                          target="_blank"
                        >
                          {claim.claimId}
                        </Link>
                      </Grid.Column>
                      <Grid.Column width={9}>
                        <Field
                          name={`updateBatchClaimsData[${index}].paymentDate`}
                          component={FormsInputDate}
                          label=""
                          secondaryLabel=""
                          // maxDate={Moment()}
                          disabled={true}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  ))}
                </Grid>
              )}
            />

            {values.paidClaims.length > 0 ? (
              <Grid columns={1}>
                <Grid.Column>
                  <Button
                    color="green"
                    disabled={this.state.isDownloading}
                    fluid
                    loading={this.state.isDownloading}
                    onClick={this.downloadEOB.bind(this, values.paidClaims)}
                  >
                    Download all EOBs of Paid Claims with Paid Status
                  </Button>
                </Grid.Column>
                <Grid.Column>
                  {this.renderDownloadEOBFilesMessage()}
                </Grid.Column>
              </Grid>
            ) : null}

            <Grid columns={1}>
              <Grid.Column>
                <Button
                  type="submit"
                  color="blue"
                  disabled={isSubmitting}
                  fluid
                  loading={this.state.isRegenerating}
                  onClick={this.regeneratePaymentFiles.bind(this)}
                >
                  Regenerate CPF/Cheque/Giro CSV Files
                </Button>
              </Grid.Column>

              <Grid.Column>
                {this.renderRegeneratePaymentFilesMessage()}
              </Grid.Column>
            </Grid>

            <Grid columns={1}>
              <Grid.Column>
                <Button
                  type="submit"
                  color="red"
                  disabled={isSubmitting}
                  fluid
                  loading={isSubmitting}
                >
                  Update Payment Date for Selected Claims
                </Button>
              </Grid.Column>
              <Grid.Column>
                {this.renderFormSubmissionResultMessage()}
              </Grid.Column>
            </Grid>
          </Form>
        )}
      />
    );
  }
}

export default FormsBatchPaymentCompleteForm;
