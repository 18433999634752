import React from 'react';
import { Checkbox } from 'semantic-ui-react'

const FormsInputsSingleCheckbox = ({field, form: {touched, errors}, ...props}) => {
    const hasError = (errors[field.name] && [field.name]) ? true : false;
    // console.log('FormsInputsSingleCheckbox', props, field)

    return (
        <Checkbox
            name={field.name}
            id={field.name}
            label={props.label}
            checked={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            disabled={(props.disabled) ? props.disabled : false}
        />
    );
}

export default FormsInputsSingleCheckbox;

/*

        <div className="form-input-group">
            <div>
                <Header sub>{props.label}</Header>
                {(props.secondaryLabel) ? <Header.Subheader>{props.secondaryLabel}</Header.Subheader>: ''}
            </div>
            {radioButtonOptions}
            {hasError && <div>{errors[field.name]}</div>}
        </div>
*/