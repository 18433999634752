import React, { Component } from 'react';
import { Container, Grid, Loader, Button, Segment, Tab } from 'semantic-ui-react';
import { connect } from 'react-redux';

import {
    getRecordClaimDetails,
    submitRecordClaimChangeCaseId,
    submitRecordClaimUpdatePaidStatus,
    submitRecordClaimResetAdjData,
    regenerateEOBData
} from '../../actions';
import RecordClaimViewClaimDetails from '../../component/record/claim_view/claim_details';
import RecordClaimViewClaimDocuments from '../../component/record/claim_view/claim_documents';
import RecordClaimViewClaimReceipts from '../../component/record/claim_view/claim_receipts';
import RecordClaimViewClaimChangeCase from '../../component/record/claim_view/claim_change_case';
import RecordClaimViewClaimUpdateClaim from '../../component/record/claim_view/claim_update_claim';
import RecordClaimViewClaimNotification from '../../component/record/claim_view/claim_notification';
import RecordClaimViewClaimStatusLog from '../../component/record/claim_view/claim_status_log';

class RecordDatabaseClaimViewPage extends Component {
    componentDidMount() {
        const { claimId } = this.props.match.params;
        // fetch case details (include assiocated claims from API)
        this.props.getRecordClaimDetails({claimId});
    }
    render() {
        const { claimId } = this.props.match.params;
        if (!this.props.claimData) { return <Loader active />; }
        if (this.props.claimData && this.props.claimData.claim_id != claimId) { return <Loader active />; }
        const {
            supportDocuments,
            receipts,
            notificationItems,
            statusChangeItems,
        } = this.props.claimData;
        const panes = [
            { menuItem: 'Claim Details', render: () => <Tab.Pane><RecordClaimViewClaimDetails claimData={this.props.claimData} /></Tab.Pane> },
            { menuItem: `Receipts (${receipts.length})`, render: () => <Tab.Pane><RecordClaimViewClaimReceipts receipts={receipts} /></Tab.Pane> },
            { menuItem: `Supporting Documents (${supportDocuments.length})`, render: () => <Tab.Pane><RecordClaimViewClaimDocuments supportDocuments={supportDocuments} /></Tab.Pane> },
            { menuItem: `Notifications (${notificationItems.length})`, render: () => <Tab.Pane><RecordClaimViewClaimNotification notificationData={notificationItems} /></Tab.Pane> },
            { menuItem: `Status Log (${statusChangeItems.length})`, render: () => <Tab.Pane><RecordClaimViewClaimStatusLog statusChangeItems={statusChangeItems} /></Tab.Pane> },
            { menuItem: 'Re-tag Case ID', render: () => (
                <Tab.Pane>
                    <RecordClaimViewClaimChangeCase
                        claimData={this.props.claimData}
                        submitRecordClaimChangeCaseId={this.props.submitRecordClaimChangeCaseId}
                        user={this.props.user}
                    />
                </Tab.Pane>
            ) },
            { menuItem: 'Update Claim', render: () => (
                <Tab.Pane>
                    <RecordClaimViewClaimUpdateClaim
                        claimData={this.props.claimData}
                        submitRecordClaimUpdatePaidStatus={this.props.submitRecordClaimUpdatePaidStatus}
                        submitRecordClaimResetAdjData={this.props.submitRecordClaimResetAdjData}
                        regenerateEOBData={this.props.regenerateEOBData}
                        user={this.props.user}
                    />
                </Tab.Pane>
            ) },
        ]
        return (
            <Container>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment raised>
                                <h3>Details For Claim# {claimId}</h3>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Tab panes={panes} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        claimData: state.records.selectedClaim,
        isFetching: state.records.isFetching,
        user: state.user.data,
    };
}

export default connect(mapStateToProps, {
    getRecordClaimDetails,
    submitRecordClaimChangeCaseId,
    submitRecordClaimUpdatePaidStatus,
    submitRecordClaimResetAdjData,
    regenerateEOBData
})(RecordDatabaseClaimViewPage);