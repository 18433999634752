import React from 'react';
import { Input, Header } from 'semantic-ui-react'

const FormsInputsTextNormal = (props) => {
    return (
        <div className="form-input-group">
            <div>
                <Header sub>{props.label}</Header>
                {(props.secondaryLabel) ? <Header.Subheader>{props.secondaryLabel}</Header.Subheader>: ''}
            </div>
            <Input fluid disabled={(props.disabled) ? props.disabled : false} value={props.value}/>
        </div>
    );
}

export default FormsInputsTextNormal;