import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, Grid } from 'semantic-ui-react'
import moment from 'moment';
import _ from 'lodash';
import uuidv1 from 'uuid/v1';

import FormsInputsText from '../../inputs/text';
import FormsInputDate from '../../inputs/date';
import FormsInputDropdown from '../../inputs/dropdown';
import FormsInputsTextNormal from '../../inputs/text_normal';
import FormsInputsNumberCustom from '../../inputs/number_custom';
import FormsInputsDropdownAddition from '../../inputs/dropdownaddition';

const prepareBenefitSelection = (list) => {
    return _.map(list, (item) => {
        return { text: item.description, value: item.id };
    });
};

const prepareRemarksSelection = (list) => {
    return _.map(list, (item) => {
        return { text: `${item.identifier}--${item.description}`, value: `${item.identifier}--${item.description}` };
    })
}

const validateInvoiceNo = (value) => {
    let error;
    if (value.length > 30) {
      error = 'Invoice Number cannot more than 30 characters!';
    }
    return error;
}

class FormsEobAddToList extends Component {
    state = {
        totalIncurredAmount: 0,
        totalPayableAmount: 0,
        payableUnitPrice: 0,
        payableQuantity: 0,
        amountDeclined: 0,
        quantity: 0,
        isCalculated: false,
        isEniligible: false
    }
    reinitializeStateValues() {
        this.setState({
            totalIncurredAmount: 0,
            totalPayableAmount: 0,
            payableUnitPrice: 0,
            payableQuantity: 0,
            amountDeclined: 0,
            quantity: 0,
            isCalculated: false,
            isEniligible: false
        });
    }
    renderCalculatedValues(values) {
        return (
            <Grid.Row>
                {values.benefitId === 13 ?
                <Grid.Column width={1}>
                     <Field
                        name="quantity"
                        component={FormsInputsText}
                        invalid={values.quantity.length === 0 || !/^-?\d+\.?\d*$/.test(values.quantity) }
                        label=""
                        secondaryLabel="Qty"
                    />
                </Grid.Column>
                : <Grid.Column width={1}>
                <FormsInputsTextNormal
                    value={this.state.quantity}
                    disabled={true}
                    label=""
                    secondaryLabel="Qty"
                />
            </Grid.Column>
                }
                <Grid.Column width={2}>
                    {
                        this.state.isEniligible ?
                        // <FormsInputsNumberCustom
                        //     value={this.state.totalIncurredAmount}
                        //     disabled={false}
                        //     label=""
                        //     secondaryLabel="Total Incurred Amt"
                        //     onChange={e => {
                        //         this.setState({
                        //             totalIncurredAmount: e.target.value
                        //         });
                        //     }}
                        // />
                        <Field 
                            name="totalIncurredAmount" 
                            label="" 
                            component={FormsInputsText} 
                            secondaryLabel="Total Incurred Amt"
                        />

                        // <Field
                        //     name="totalIncurredAmount"
                        //     label=""
                        //     secondaryLabel="Total Incurred Amt"
                        //     component={FormsInputsText}
                        //     value={this.state.totalIncurredAmount}
                        // />
                        :
                        <FormsInputsTextNormal
                            value={this.state.totalIncurredAmount}
                            disabled={true}
                            label=""
                            secondaryLabel="Total Incurred Amt"
                        />
                    }

                    {/* <FormsInputsTextNormal
                        value={this.state.totalIncurredAmount}
                        disabled={true}
                        label=""
                        secondaryLabel="Total Incurred Amt"
                    /> */}
                </Grid.Column>
                <Grid.Column width={1}>
                    <FormsInputsTextNormal
                        value={this.state.payableQuantity}
                        disabled={true}
                        label=""
                        secondaryLabel="Pay Qty"
                    />
                </Grid.Column>
                <Grid.Column width={2}>
                    <FormsInputsTextNormal
                        value={this.state.payableUnitPrice}
                        disabled={true}
                        label=""
                        secondaryLabel="Payable Unit Price"
                    />
                </Grid.Column>
                <Grid.Column width={2}>
                    <FormsInputsTextNormal
                        value={this.state.totalPayableAmount}
                        disabled={true}
                        label=""
                        secondaryLabel="Total Payable Amt"
                    />
                </Grid.Column>
                <Grid.Column width={2}>
                    <FormsInputsTextNormal
                        value={this.state.amountDeclined}
                        disabled={true}
                        label=""
                        secondaryLabel="Amt Declined"
                    />
                </Grid.Column>
            </Grid.Row>
        );
    }
    calculateValues(setFieldValue, values) {
        // console.log("calculateValues: ", values);
        // form.setFieldValue("houseNo", filterHDB_BLK_NO);
        
        let newQty = 0;

        if (values.benefitId === 17) {
            this.setState({
                isEniligible: true
            });
        }

        if(values.benefitId === 13) {
            if(values.quantity === '') {
                return false;
            }
            newQty = parseFloat(values.quantity);
        } else {
            newQty = values.toDate.diff(values.fromDate, 'days') + 1;
        }
        // if (newQty < 1) { error }
        const selectedScheme = _.find(this.props.benefitSchemeItems, (option) => option.id === values.benefitId);
        // console.log(selectedScheme);
        // let benefitEntitlement = 0;
        const {
            total_payable_limit,
            total_qty_limit,
            unit_payable_limit,
            // unit_qty_limit,
            payable_item
        } = selectedScheme;
        let totalIncurredAmount = +(newQty * values.incurredAmount).toFixed(2);
        setFieldValue("totalIncurredAmount", totalIncurredAmount);

        let totalPayableAmount = totalIncurredAmount;
        let payableUnitPrice = values.incurredAmount;
        let payableQuantity = newQty;
        if (total_qty_limit > 0 && newQty > total_qty_limit) {
            payableQuantity = total_qty_limit;
            totalPayableAmount = +(payableQuantity * values.incurredAmount).toFixed(2);
        }
        if (unit_payable_limit > 0 && values.incurredAmount > unit_payable_limit) {
            payableUnitPrice = unit_payable_limit;
            totalPayableAmount = +(payableQuantity * payableUnitPrice).toFixed(2);
        }
        if (total_payable_limit > 0 && totalPayableAmount > total_payable_limit) {
            totalPayableAmount = total_payable_limit;
        }
        let amountDeclined = 0;
        if (totalIncurredAmount > totalPayableAmount) {
            amountDeclined = +(totalIncurredAmount - totalPayableAmount).toFixed(2);
        }
        if (payable_item === 0) {
            totalPayableAmount = 0;
            payableUnitPrice = 0;
            payableQuantity = 0;
            amountDeclined = totalIncurredAmount;
        } else {
            // continue checking for overall case limits
            /*
            benefitLimitsAggregated={props.benefitLimitsAggregated}
            adjEobItems={props.adjEobItems}
            relatedEobItemsData={props.relatedEobItemsData}
            */
            // Start Calculating New Payable EOB
            const tempNewEOBItemObj = {
                id: selectedScheme.id,
                parent_id: selectedScheme.parent_id,
                totalIncurredAmount,
                totalPayableAmount,
                payableUnitPrice,
                payableQuantity,
                amountDeclined,
                quantity: newQty,
            };
            // console.log(this.props.relatedEobItemsData[0], this.props.adjEobItems)
            const reformatedCurrentEOBItems = _.map(this.props.adjEobItems, (eobItem) => {
                const {
                    benefitId,
                    totalIncurredAmount,
                    totalPayableAmount,
                    payableUnitPrice,
                    payableQuantity,
                    amountDeclined,
                    quantity
                } = eobItem;
                return {
                    id: benefitId,
                    parent_id: eobItem.schemeData.parent_id,
                    totalPayableAmount,
                    totalIncurredAmount,
                    payableUnitPrice,
                    payableQuantity,
                    amountDeclined,
                    quantity
                };
            })
            const tempNewEOBItemArray = [...this.props.relatedEobItemsData, ...reformatedCurrentEOBItems, tempNewEOBItemObj];
            const combinedBenefitItemByType = _.reduce(tempNewEOBItemArray, (accumulator, eobItem) => {
                const {
                    id,
                    parent_id,
                    payableQuantity,
                    payableUnitPrice,
                    quantity,
                    totalPayableAmount,
                    totalIncurredAmount,
                    amountDeclined
                } = eobItem;
                if (!accumulator[id]) {
                    accumulator[id] = {};
                    accumulator[id].id = id;
                    accumulator[id].parent_id = parent_id;
                    accumulator[id].payableQuantity = payableQuantity;
                    accumulator[id].payableUnitPrice = payableUnitPrice;
                    accumulator[id].quantity = quantity;
                    accumulator[id].totalPayableAmount = totalPayableAmount;
                    accumulator[id].totalIncurredAmount = totalIncurredAmount;
                    accumulator[id].amountDeclined = amountDeclined;
                } else {
                    // accumulator[id].payableQuantity += payableQuantity;
                    accumulator[id].payableUnitPrice += payableUnitPrice;
                    // accumulator[id].quantity += quantity;
                    // accumulator[id].totalIncurredAmount += totalIncurredAmount;
                    // accumulator[id].totalPayableAmount += totalPayableAmount;
                    // accumulator[id].amountDeclined += amountDeclined;
                    accumulator[id].payableQuantity += +(payableQuantity).toFixed(2);
                    // accumulator[id].payableUnitPrice += +(payableUnitPrice).toFixed(2);
                    accumulator[id].quantity += quantity;
                    accumulator[id].totalPayableAmount += +(totalPayableAmount).toFixed(2);
                    accumulator[id].totalIncurredAmount += +(totalIncurredAmount).toFixed(2);
                    accumulator[id].amountDeclined += +(amountDeclined).toFixed(2);
                }
                return accumulator;
            }, {});
            let aggregatedKeyResults = {};
            for (let i = 0; i < this.props.benefitSchemeItems.length; i++) {
                let schemeItem = this.props.benefitSchemeItems[i];
                const {
                    id
                } = schemeItem;
                let tempRootArray = [];
                let secondarykeyArray = [];
                let thirdkeyArray = [];
                for (let y = 0; y < this.props.benefitSchemeItems.length; y++) {
                    if (this.props.benefitSchemeItems[y].parent_id === id) {
                        secondarykeyArray.push(this.props.benefitSchemeItems[y].id);
                    }
                }
                for (let y = 0; y < this.props.benefitSchemeItems.length; y++) {
                    if (secondarykeyArray.indexOf(this.props.benefitSchemeItems[y].parent_id) > -1) {
                        thirdkeyArray.push(this.props.benefitSchemeItems[y].id);
                    }
                }
                tempRootArray = [...secondarykeyArray, ...thirdkeyArray, id];
                aggregatedKeyResults[id] = tempRootArray;
            }
            const aggregatedAmountResults = _.reduce(aggregatedKeyResults, (accumulator, keyArray, objKey) => {
                let tempAggPayableQuantity = 0;
                let tempAggPayableUnitPrice = 0;
                let tempAggQuantity = 0;
                let tempAggTotalIncurredAmount = 0;
                let tempAggTotalPayableAmount = 0;
                let tempAggAmountDeclined = 0;

                // console.log("combinedBenefitItemByType: ", combinedBenefitItemByType);
                for (let i = 0; i < keyArray.length; i++) {
                    if (combinedBenefitItemByType[keyArray[i]]) {
                        // tempAggPayableQuantity += combinedBenefitItemByType[keyArray[i]].payableQuantity;
                        tempAggPayableUnitPrice += combinedBenefitItemByType[keyArray[i]].payableUnitPrice;
                        // tempAggQuantity += combinedBenefitItemByType[keyArray[i]].quantity;
                        // tempAggTotalIncurredAmount += combinedBenefitItemByType[keyArray[i]].totalIncurredAmount;
                        // tempAggTotalPayableAmount += combinedBenefitItemByType[keyArray[i]].totalPayableAmount;
                        // tempAggAmountDeclined += combinedBenefitItemByType[keyArray[i]].amountDeclined;
                        tempAggPayableQuantity += +(combinedBenefitItemByType[keyArray[i]].payableQuantity).toFixed(2);
                        // tempAggPayableUnitPrice += +(combinedBenefitItemByType[keyArray[i]].payableUnitPrice).toFixed(2);
                        tempAggQuantity += +(combinedBenefitItemByType[keyArray[i]].quantity).toFixed(2);
                        tempAggTotalIncurredAmount += +(combinedBenefitItemByType[keyArray[i]].totalIncurredAmount).toFixed(2);
                        tempAggTotalPayableAmount += +(combinedBenefitItemByType[keyArray[i]].totalPayableAmount).toFixed(2);
                        tempAggAmountDeclined += +(combinedBenefitItemByType[keyArray[i]].amountDeclined).toFixed(2);
                    }
                }

                accumulator[objKey] = {
                    aggPayableQuantity: tempAggPayableQuantity,
                    aggPayableUnitPrice: tempAggPayableUnitPrice,
                    aggQuantity: tempAggQuantity,
                    aggTotalIncurredAmount: tempAggTotalIncurredAmount,
                    aggTotalPayableAmount: tempAggTotalPayableAmount,
                    aggAmountDeclined: tempAggAmountDeclined
                };
                return accumulator;
            }, {});

            // proceed to check total limits based on array of benefits
            // step 1 - check current selected to insert benefit
            let schemeItemToCheckAgainst = selectedScheme;
            let aggregatedAmountResultToCheck = aggregatedAmountResults[schemeItemToCheckAgainst.id];

            const aggTotalPayableAmount = aggregatedAmountResultToCheck.aggTotalPayableAmount;
            const totalPayableLimit = schemeItemToCheckAgainst.total_payable_limit;

            // Check current benefit schema
            if (totalPayableLimit > 0 && aggTotalPayableAmount > totalPayableLimit) {
               const differentAmount = aggTotalPayableAmount - totalPayableLimit;

               if(differentAmount >= totalPayableAmount) {
                    totalPayableAmount = 0;
                    amountDeclined = totalIncurredAmount;
               } else {
                    totalPayableAmount = +(totalPayableAmount - differentAmount).toFixed(2);
                    amountDeclined = +(amountDeclined + differentAmount).toFixed(2);
               }
            }

            let parentId = schemeItemToCheckAgainst.parent_id

            if(parentId > 0) {
                schemeItemToCheckAgainst = _.find(this.props.benefitSchemeItems, (option) => option.id === parentId);
                const aggTotalPayableAmount = aggregatedAmountResults[parentId].aggTotalPayableAmount - tempNewEOBItemArray[tempNewEOBItemArray.length -1].totalPayableAmount + totalPayableAmount;
                const totalPayableLimit = schemeItemToCheckAgainst.total_payable_limit;

                if (totalPayableLimit > 0 && aggTotalPayableAmount > totalPayableLimit) {
                    const differentAmount = aggTotalPayableAmount - totalPayableLimit;

                    if(differentAmount >= totalPayableAmount) {
                        totalPayableAmount = 0;
                        amountDeclined = totalIncurredAmount;
                   } else {
                        console.log(totalPayableAmount, '-', differentAmount, '=', +(totalPayableAmount - differentAmount).toFixed(2));
                        totalPayableAmount = +(totalPayableAmount - differentAmount).toFixed(2);
                        amountDeclined = +(amountDeclined + differentAmount).toFixed(2);
                   }
                }
            }

            parentId = schemeItemToCheckAgainst.parent_id

            if(parentId > 0) {
                schemeItemToCheckAgainst = _.find(this.props.benefitSchemeItems, (option) => option.id === parentId);
                const aggTotalPayableAmount = aggregatedAmountResults[parentId].aggTotalPayableAmount;
                const totalPayableLimit = schemeItemToCheckAgainst.total_payable_limit;

                if (totalPayableLimit > 0 && aggTotalPayableAmount > totalPayableLimit) {
                    const differentAmount = aggTotalPayableAmount - totalPayableLimit;

                    if(differentAmount >= totalPayableAmount) {
                        totalPayableAmount = 0;
                        amountDeclined = totalIncurredAmount;
                   } else {
                        totalPayableAmount = +(totalPayableAmount - differentAmount).toFixed(2);
                        amountDeclined = +(amountDeclined + differentAmount).toFixed(2);
                   }
                }
            }

        }
        this.setState({
            totalIncurredAmount,
            totalPayableAmount,
            payableUnitPrice,
            payableQuantity,
            amountDeclined,
            quantity: newQty,
            isCalculated: true
        });
    }
    render() {
        // console.log(this.state);
        // console.log(this.props)
        const benefitOptions = prepareBenefitSelection(this.props.benefitSchemeItems);
        const remarkOptions = prepareRemarksSelection(this.props.remarkOptions);
        const remarksRequired = (this.state.amountDeclined === 0) ? true : false;
        return (
            <Formik
                initialValues={{
                    fromDate: moment(),
                    toDate: moment(),
                    benefitId: benefitOptions[0].value,
                    incurredAmount: '',
                    invoiceNo: '',
                    quantity: '',
                    remark: '',
                    totalIncurredAmount: ''
                }}
                onSubmit={(values, actions) => {
                    console.log("[Add EOB Item\n", actions);
                    values.uuid = uuidv1();
                    values.schemeData = _.find(this.props.benefitSchemeItems, (option) => option.id === values.benefitId);
                    // values.totalIncurredAmount = this.state.totalIncurredAmount;
                    values.totalIncurredAmount = parseFloat(values.totalIncurredAmount);
                    values.totalPayableAmount = this.state.totalPayableAmount;
                    values.payableUnitPrice = this.state.payableUnitPrice;
                    values.payableQuantity = this.state.payableQuantity;
                    values.amountDeclined = this.state.amountDeclined;
                    values.quantity = this.state.quantity;

                    console.log("[values\n]", values);

                    this.props.addEobItemLocally(values, this.props.claimId);
                    this.reinitializeStateValues();
                    actions.resetForm();
                    actions.setSubmitting(false);
                }}
                // validationSchema={LoginSchema}
                render={({
                    form,
                    values,
                    errors,
                    status,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                }) => (
                    <Form>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={2}>
                                    <Field
                                        name="fromDate"
                                        component={FormsInputDate}
                                        label=""
                                        secondaryLabel="From Date"
                                        maxDate={moment()}
                                    />
                                </Grid.Column>
                                <Grid.Column width={2}>
                                    <Field
                                        name="toDate"
                                        component={FormsInputDate}
                                        label=""
                                        secondaryLabel="To Date"
                                        maxDate={moment()}
                                    />
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Field
                                        name="benefitId"
                                        options={benefitOptions}
                                        label=""
                                        secondaryLabel="Benefit"
                                        component={FormsInputDropdown}
                                    />
                                </Grid.Column>
                                <Grid.Column width={2}>
                                    <Field name="incurredAmount" label="" component={FormsInputsText} secondaryLabel="Unit Incurred Amt"/>
                                </Grid.Column>
                                <Grid.Column width={2}>
                                    <Field name="invoiceNo" label="" component={FormsInputsText} secondaryLabel="Invoice No." validate={validateInvoiceNo} />
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Field
                                        name="remark"
                                        options={remarkOptions}
                                        label=""
                                        secondaryLabel="Remark"
                                        component={FormsInputsDropdownAddition}
                                        disabled={remarksRequired}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {this.renderCalculatedValues(values)}
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <Button
                                        type="button"
                                        secondary
                                        fluid
                                        disabled={this.state.isCalculated || values.incurredAmount === 0 || values.incurredAmount.length === 0 || !/^-?\d+\.?\d*$/.test(values.incurredAmount) || (values.benefitId === 13 && !/^-?\d+\.?\d*$/.test(values.quantity)) || values.invoiceNo.length === 0 || values.invoiceNo.length > 30}
                                        onClick={() => {
                                            this.calculateValues(setFieldValue, values);   
                                            console.log('totalIncurredAmount', this.state.totalIncurredAmount)
                                            // form.setFieldValue("totalIncurredAmount", this.state.totalIncurredAmount);
                                        }}
                                    >
                                        Calculate
                                    </Button>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Button
                                        type="submit" primary disabled={!this.state.isCalculated} fluid loading={isSubmitting}
                                    >
                                        Add EOB Item
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                )}
            />
        );
    }
}

export default FormsEobAddToList;