import React, {Component} from 'react';
import { Container, Grid, Segment, Table } from 'semantic-ui-react';
import {connect} from 'react-redux';
import _ from 'lodash';
import Moment from 'moment';

import {
    getAdminUserList
} from '../../actions';

class UserListPage extends Component {
    componentDidMount() {
        const {accessToken} = this.props.user;
        this.props.getAdminUserList(accessToken);
    }
    render() {
        return (
            <Container>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment>
                                User List
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Table celled>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>#</Table.HeaderCell>
                                        <Table.HeaderCell>Name</Table.HeaderCell>
                                        <Table.HeaderCell>Email</Table.HeaderCell>
                                        <Table.HeaderCell>Created</Table.HeaderCell>
                                        <Table.HeaderCell>Updated</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {
                                        _.map(this.props.adminUserList, (user, index) => {
                                            const {
                                                username,
                                                person_name,
                                                created_at,
                                                updated_at
                                            } = user;
                                            return (
                                                <Table.Row key={index}>
                                                    <Table.Cell>
                                                        {index + 1}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {person_name}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {username}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {Moment(created_at).format('DD/MM/YYYY HH:mm')}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {Moment(updated_at).format('DD/MM/YYYY HH:mm')}
                                                    </Table.Cell>
                                                </Table.Row>
                                            );
                                        })
                                    }
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        adminUserList: state.user.adminUserList
    };
}

export default connect(mapStateToProps, {
    getAdminUserList
})(UserListPage);