import React from 'react';
import { Header, Segment, Table } from 'semantic-ui-react';
import Moment from 'moment';

const RecordClaimViewClaimDetailsEndorsement = (props) => {
    const {
        certified_by_admin,
        certified_by_admin_email,
        certified_by_admin_home_no,
        certified_by_admin_mobile_no,
        certified_by_school_address,
        certified_by_school_name,
        certified_by_school_zon,
        endorse_date,
        endorsement_status,
    } = props.claimData;
    return (
        <div>
            <Header as='h4' attached="top">
                Endorsement Information
            </Header>
            <Segment attached>
                <Table basic compact="very" fixed>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                Endorsement Status
                            </Table.Cell>
                            <Table.Cell>
                                {endorsement_status}
                            </Table.Cell>
                            <Table.Cell>
                                Endorsement Date
                            </Table.Cell>
                            <Table.Cell>
                                {Moment(endorse_date).format('DD/MM/YYYY')}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Certified by Admin
                            </Table.Cell>
                            <Table.Cell>
                                {certified_by_admin}
                            </Table.Cell>
                            <Table.Cell>
                                Admin Email
                            </Table.Cell>
                            <Table.Cell>
                                {certified_by_admin_email}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Admin Home#
                            </Table.Cell>
                            <Table.Cell>
                                {certified_by_admin_home_no}
                            </Table.Cell>
                            <Table.Cell>
                                Admin Mobile#
                            </Table.Cell>
                            <Table.Cell>
                                {certified_by_admin_mobile_no}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                School Name
                            </Table.Cell>
                            <Table.Cell>
                                {certified_by_school_name}
                            </Table.Cell>
                            <Table.Cell>
                                School Address
                            </Table.Cell>
                            <Table.Cell>
                                {certified_by_school_address}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                School Zone
                            </Table.Cell>
                            <Table.Cell>
                                {certified_by_school_zon}
                            </Table.Cell>
                            <Table.Cell>
                                
                            </Table.Cell>
                            <Table.Cell>
                                
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
        </div>
    );
}

export default RecordClaimViewClaimDetailsEndorsement;