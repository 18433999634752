import React, {Component} from 'react';
import { Container, Grid, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';

import {
    submitCreateNewUser
} from '../../actions';
import FormsUserAdd from '../../component/forms/user';

class AddUserPage extends Component {
    render() {
        return (
            <Container>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment>
                                Add User
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <FormsUserAdd
                                user={this.props.user}
                                submitCreateNewUser={this.props.submitCreateNewUser}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
    };
}

export default connect(mapStateToProps, {
    submitCreateNewUser
})(AddUserPage);