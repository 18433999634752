import * as Yup from 'yup';

const LoginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Email address is not valid.')
        .required('Email address is required.'),
    password: Yup.string()
        .min(8, 'Too short')
        .max(30, 'Too long')
        .required('Password is required')
});

export default LoginSchema;