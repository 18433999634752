import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid, Button, Message } from 'semantic-ui-react';
import _ from 'lodash';

import FormsInputsDropdownSearch from '../../inputs/dropdownsearch';
import FormsInputsDropdown from '../../inputs/dropdown';
import FormsInputsText from '../../inputs/text';
import ClaimRejectedStatusSchema from './validate';

const prepareDropdownSelection = (list) => {
    return _.map(list, (item) => {
        return { text: item.description, value: item.description };
    })
}

const moeRejectionReasonList = [
    { text: 'Accident not related to school/school activities', value: 'Accident not related to school/school activities' },
    { text: 'Duplicate claim submitted', value: 'Duplicate claim submitted' },
    // { text: 'Chiropractor not in policy terms', value: 'Chiropractor not in policy terms' },
    { text: 'Exceeded claim limit', value: 'Exceeded claim limit' },
    { text: 'Late submission', value: 'Late submission' },
    { text: 'Medical illness/Non-infectious disease', value: 'Medical illness/Non-infectious disease' },
    { text: 'Paid by third party', value: 'Paid by third party' },
    // { text: 'Physiotherapy not in policy terms', value: 'Physiotherapy not in policy terms' },
    { text: 'Private physiotherapy treatment', value: 'Private physiotherapy treatment' },
    { text: 'Treatment incurred more than 365 days from date of accident', value: 'Treatment incurred more than 365 days from date of accident' },
    { text: 'Expenses not in policy terms', value: 'Expenses not in policy terms' },
];

const FormsClaimsRejected = (props) => {
    const rejectionReasons = prepareDropdownSelection(props.rejectionOptions);
    const findMoreRejectionDescription = (selectedId) => {
        if (selectedId) {
            const selectedRejectionReason = _.find(props.rejectionOptions, (option) => option.description === selectedId)
            if (selectedRejectionReason) {
                return selectedRejectionReason.description2;
            } else {
                return 'N/A';
            }
        } else {
            return 'N/A';
        }
    };
    return (
        <Formik
            initialValues={{
                rejectionReason: '',
                rejectionReasonFreeText: '',
                moeRejectionReason: 'Accident not related to school/school activities'
            }}
            validationSchema={ClaimRejectedStatusSchema}
            onSubmit={(values, actions) => {
                console.log("Reject Claim and Inform Claimant ---> ", values);
                const selectedRejectionDescription = findMoreRejectionDescription(values.rejectionReason);
                console.log('selectedRejectionDescription', selectedRejectionDescription);
                if (selectedRejectionDescription && selectedRejectionDescription !== 'N/A') {
                    values.rejectionReason = selectedRejectionDescription;
                    // console.log(values);
                    props.submitAdjudicationResults(values);
                } else if (selectedRejectionDescription === 'N/A' && values.rejectionReasonFreeText && values.rejectionReasonFreeText.trim().length > 0){
                    values.rejectionReason = '';
                    props.submitAdjudicationResults(values);
                }
            }}
            render={({
                values,
                errors,
                status,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
            }) => (
                <Form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Field
                                    name="rejectionReason"
                                    label=""
                                    component={FormsInputsDropdownSearch}
                                    secondaryLabel="Rejection Reason"
                                    options={rejectionReasons}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Message negative>
                                    <Message.Header>
                                        Actual Rejection Description Seen By User
                                    </Message.Header>
                                    <p>
                                        {findMoreRejectionDescription(values.rejectionReason)}
                                    </p>
                                </Message>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Field
                                    name="rejectionReasonFreeText"
                                    label=""
                                    component={FormsInputsText}
                                    secondaryLabel="Additional Rejection Reasons (Free Text)"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Field
                                    name="moeRejectionReason"
                                    label=""
                                    component={FormsInputsDropdown}
                                    secondaryLabel="MOE Rejection Reason"
                                    options={moeRejectionReasonList}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Button
                                    color='red'
                                    type="submit"
                                    fluid
                                    disabled={props.isSubmitting}
                                    loading={props.isSubmitting}
                                >
                                    Reject Claim and Inform Claimant
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            )}
        />
    );
}

export default FormsClaimsRejected;