import React from 'react'
import { Grid, Segment, Header, Tab, Table } from 'semantic-ui-react'

import ClaimCasePanePOI from './claim_case_pane_poi';
import ClaimCasePaneDOA from './claim_case_pane_doa';
// import ClaimCasePaneOI from './claim_case_pane_oi';

const ClaimCasePane = (props) => {
    return (
        <Tab.Pane>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Header as='h4' attached='top'>
                            Similar Cases (based on NRIC)
                        </Header>
                        <Segment attached>
                            <Table basic compact="very" fixed>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>
                                            # of Other Cases
                                        </Table.Cell>
                                        <Table.Cell>
                                            {props.caseData.similarCasesData.numberOfCases}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            Same Accident Date
                                        </Table.Cell>
                                        <Table.Cell>
                                            {props.caseData.similarCasesData.hasSimilarAccidentDate ? 'Yes' : 'No'}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>            
                <Grid.Row>
                    <Grid.Column>
                        <Header as='h4' attached='top'>
                            Particulars of Insured
                        </Header>
                        <Segment attached>
                            <ClaimCasePanePOI caseData={props.caseData} />
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Header as='h4' attached='top'>
                            Details of Accident
                        </Header>
                        <Segment attached>
                            <ClaimCasePaneDOA caseData={props.caseData} />
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Tab.Pane>
    );
}

export default ClaimCasePane;