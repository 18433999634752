import React, { Component } from 'react';
import { HashRouter as Router, Route } from "react-router-dom";

import LoginPage from './page/login/login_page';
import IndexPage from './page/app/index_page';
// import RegisterPage from './page/register/register_page';

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Route exact path="/" component={LoginPage} />
          <Route path="/app" component={IndexPage} />
        </div>
      </Router>
    );
  }
}

export default App;