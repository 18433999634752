import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, Grid } from 'semantic-ui-react'
import _ from 'lodash';
import uuidv1 from 'uuid/v1';
import moment from 'moment';

import FormsInputsText from '../../inputs/text';
import FormsInputDropdown from '../../inputs/dropdown';
import FormsInputsDropdownSearch from '../../inputs/dropdownsearch';
import FormsInputDate from '../../inputs/date';
// import FormsInputsTextNormal from '../../inputs/text_normal';
// import FormsInputsDropdownAddition from '../../inputs/dropdownaddition';

const patientIdentificationSourceOptions = [
    { text: 'Local CPF Holder', value: 'C' },
    { text: 'Foreigner', value: 'F' }
];

const preparePaymentItemSelection = (list) => {
    return _.map(list, (item) => {
        return { text: item.description, value: item.id };
    });
};

const prepareCPFHospitalListOptions = (list) => {
    return _.map(list, (item) => {
        return { text: item.description, value: item.code };
    });
};

const prepareSchoolPaymentOptions = (list) => {
    return _.map(list, (item, index) => {
        return { text: item.school_name, value: item.school_name, key: index };
    })
}

class FormsEobAddToPayment extends Component {
    renderAdditionalOtherInputs = (paymentItemId) => {
        if (paymentItemId) {
            const selectedPaymentItem = _.find(this.props.paymentOptions, (paymentOption) => paymentOption.id === paymentItemId);
            const {description} = selectedPaymentItem;
            if (description === 'OTHERS') {
                return (
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Field
                                name="otherPaymentName"
                                label=""
                                component={FormsInputsText}
                                secondaryLabel="Input payee name"
                            />
                        </Grid.Column>
                    </Grid.Row>
                );
            }
        }
    }
    renderAdditionalSchoolInputs = (paymentItemId) => {
        if (paymentItemId) {
            const selectedPaymentItem = _.find(this.props.paymentOptions, (paymentOption) => paymentOption.id === paymentItemId);
            const {description} = selectedPaymentItem;
            if (description === 'SCHOOL') {
                const schoolPaymentSelection = prepareSchoolPaymentOptions(this.props.schoolPaymentOptions);
                return (
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Field
                                name="schoolPaymentName"
                                label=""
                                options={schoolPaymentSelection}
                                component={FormsInputsDropdownSearch}
                                secondaryLabel="Select School Name to Make Payment To"
                            />
                        </Grid.Column>
                    </Grid.Row>
                );
            }
        }
    }
    renderAdditionalCPFInputs = (paymentItemId) => {
        if (paymentItemId) {
            const selectedPaymentItem = _.find(this.props.paymentOptions, (paymentOption) => paymentOption.id === paymentItemId);
            const {description} = selectedPaymentItem;
            if (description.indexOf('CPF-') !== -1) {
                const cpfHospitalListSelection = prepareCPFHospitalListOptions(this.props.cpfHospitalListOptions);
                return (
                    <Grid.Row>
                        <Grid.Column width={2}>
                            <Field
                                name="patientIdentificationSource"
                                options={patientIdentificationSourceOptions}
                                label=""
                                component={FormsInputDropdown}
                                secondaryLabel="Patient Identification Source"
                            />
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <Field
                                name="hrnNumber"
                                label=""
                                component={FormsInputsText}
                                secondaryLabel="HRN #"
                            />
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <Field
                                name="admissionDate"
                                label=""
                                component={FormsInputDate}
                                secondaryLabel="Admission Date"
                                maxDate={moment()}
                            />
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <Field
                                name="dischargeDate"
                                label=""
                                component={FormsInputDate}
                                secondaryLabel="Discharge Date"
                                maxDate={moment()}
                            />
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Field
                                name="hospitalCode"
                                label=""
                                options={cpfHospitalListSelection}
                                component={FormsInputsDropdownSearch}
                                secondaryLabel="Hospital"
                            />
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Field
                                name="payerCPFNric"
                                label=""
                                component={FormsInputsText}
                                secondaryLabel="Payer CPF Nric"
                            />
                        </Grid.Column>
                    </Grid.Row>
                );
            }
        }

        return null;
    }
    render() {
        const paymentOptions = preparePaymentItemSelection(this.props.paymentOptions);
        const findPreviousCPFPaymentItem = _.find(this.props.adjPaymentItems, (adjPaymentItem) => adjPaymentItem.schemeData && adjPaymentItem.schemeData.description.indexOf('CPF-') !== -1) ;
        const hasPreviousCPFPaymentItem = (findPreviousCPFPaymentItem) ? true : false;

        return (
            <Formik
                initialValues={{
                    paymentItemId: '',
                    remarks: '',
                    amount: 0,
                    patientIdentificationSource: (hasPreviousCPFPaymentItem) ? findPreviousCPFPaymentItem.patientIdentificationSource : 'C',
                    hrnNumber: (hasPreviousCPFPaymentItem) ? findPreviousCPFPaymentItem.hrnNumber : '',
                    admissionDate: (hasPreviousCPFPaymentItem) ? moment(findPreviousCPFPaymentItem.admissionDate) : moment(),
                    dischargeDate: (hasPreviousCPFPaymentItem) ? moment(findPreviousCPFPaymentItem.dischargeDate) : moment(),
                    hospitalCode: (hasPreviousCPFPaymentItem) ? findPreviousCPFPaymentItem.hospitalCode : '',
                    payerCPFNric: '',
                    schoolPaymentName: '',
                    otherPaymentName: '',
                }}
                onSubmit={(values, actions) => {
                    console.log(values,actions);
                    values.uuid = uuidv1();
                    values.schemeData = _.find(this.props.paymentOptions, (option) => option.id === values.paymentItemId);
                    this.props.addEobPaymentItemLocally(values, this.props.claimId);
                    actions.setSubmitting(false);
                    actions.resetForm();
                }}
                // validationSchema={LoginSchema}
                render={({
                    values,
                    errors,
                    status,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <Form>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={5}>
                                    <Field
                                        name="paymentItemId"
                                        options={paymentOptions}
                                        label=""
                                        secondaryLabel="Payment Item"
                                        component={FormsInputDropdown}
                                    />
                                </Grid.Column>
                                <Grid.Column width={2}>
                                    <Field
                                        name="amount"
                                        label=""
                                        component={FormsInputsText}
                                        secondaryLabel="Payment Amount"
                                    />
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <Field
                                        name="remarks"
                                        label=""
                                        component={FormsInputsText}
                                        secondaryLabel="Remarks"
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            { this.renderAdditionalCPFInputs(values.paymentItemId) }
                            { this.renderAdditionalSchoolInputs(values.paymentItemId) }
                            { this.renderAdditionalOtherInputs(values.paymentItemId) }
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Button
                                        disabled={!/^-?\d+\.?\d*$/.test(values.amount)}
                                        type="submit" primary fluid loading={isSubmitting}
                                    >
                                        Add Payment Item
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                )}
            />
        );
    }
}

export default FormsEobAddToPayment;