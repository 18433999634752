import React from 'react';
import { Grid, Header, Segment } from 'semantic-ui-react'

import FormsBatchMhcReviewForm from '../../../forms/batch/mhc_review';

const BatchUpdateClaimsTableMhcCheck = (props) => {
    return (
        <Grid.Row>
            <Grid.Column>
                <Header attached="top" block as="h4">
                    Claims To Review
                </Header>
                <Segment attached>
                    <FormsBatchMhcReviewForm
                        batchClaims={props.batchClaims}
                        user={props.user}
                        batchId={props.batchId}
                        batchName={props.batchName}
                        submitMhcReviewBatchResults={props.submitMhcReviewBatchResults}
                        getEobFilesList={props.getEobFilesList}
                    />
                </Segment>
            </Grid.Column>
        </Grid.Row>
    );
}

export default BatchUpdateClaimsTableMhcCheck;