import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
import ReduxPromise from 'redux-promise';

import rootReducer from '../reducers'
 
const persistConfig = {
  key: 'root',
  storage,
}
 
const persistedReducer = persistReducer(persistConfig, rootReducer)

let store = createStore(persistedReducer, compose(applyMiddleware(ReduxPromise)));
let persistor = persistStore(store);

export { store, persistor };