import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, Grid } from 'semantic-ui-react'
// import moment from 'moment';
// import _ from 'lodash';
// import uuidv1 from 'uuid/v1';

import FormsInputsText from '../../inputs/text';
// import FormsInputDate from '../../inputs/date';
import FormsInputDropdown from '../../inputs/dropdown';
// import FormsInputsTextNormal from '../../inputs/text_normal';
// import FormsInputsDropdownAddition from '../../inputs/dropdownaddition';

class FormsRecordSearchForm extends Component {
    render() {
        const searchTypeOptions = [
            { text: 'Case ID', value: 0 },
            { text: 'Claim ID', value: 1 },
            { text: 'NRIC', value: 2 },
        ];
        return (
            <Formik
                initialValues={{ 
                    searchType: 0,
                    searchText: ''
                }}
                onSubmit={(values, actions) => {
                    // console.log(values, actions);
                    const {accessToken} = this.props.user;
                    this.props.getRecordSearchResults({data: values, accessToken}, (result, error) => {
                        actions.setSubmitting(false);
                    });
                }}
                // validationSchema={LoginSchema}
                render={({
                    values,
                    errors,
                    status,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <Form>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={4}>
                                    <Field
                                        name="searchType" 
                                        options={searchTypeOptions} 
                                        label="" 
                                        secondaryLabel="Search Type"
                                        component={FormsInputDropdown}
                                    />
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Field name="searchText" label="" component={FormsInputsText} secondaryLabel="Search For Value"/>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Button 
                                        type="submit" 
                                        secondary 
                                        disabled={isSubmitting} 
                                        fluid 
                                        loading={isSubmitting}
                                        size="massive"
                                    >
                                        Search Database
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                )}
            />
        );
    }
}

export default FormsRecordSearchForm;