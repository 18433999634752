import React, {Component} from 'react';
import { Grid, Segment, Header } from 'semantic-ui-react';

import FormsReportsTypeOne from '../../forms/reports/type_one';
import ReportTypeOneResultsDisplayItem from './results_display';;

class ReportTypeOne extends Component {
    render() {
        return (
            <Grid.Row>
                <Grid.Column>
                    <Header attached="top" block as="h5">
                        Report Options
                    </Header>
                    <Segment attached>
                        <FormsReportsTypeOne 
                            submitGetReportOneData={this.props.submitGetReportOneData}
                            user={this.props.user}
                        />
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Header attached="top" block as="h5">
                        Results
                    </Header>
                    <Segment attached>
                        <ReportTypeOneResultsDisplayItem 
                            reportData={this.props.reportData}
                        />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        );
    }
}

export default ReportTypeOne;