import React from 'react';
import {Table} from 'semantic-ui-react';
import _ from 'lodash';

const RecordCaseViewCaseLimitsTable = (props) => {
    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        s
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Claim ID
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Description
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Qty
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        P.Qty
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        P.U-P$
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Tot.Incurr.Amt
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Tot.Payable.Amt
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Amt Dec
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Remarks
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
            {
                _.map(props.eobItems, (item, index) => {
                    const {
                        status,
                        claim_id,
                        description,
                        quantity,
                        payableQuantity,
                        payableUnitPrice,
                        totalIncurredAmount,
                        totalPayableAmount,
                        amountDeclined,
                        remarkCode,
                        remarkText
                    } = item;
                    return (
                        <Table.Row key={index}>
                            <Table.Cell>
                                {status}
                            </Table.Cell>
                            <Table.Cell>
                                {claim_id}
                            </Table.Cell>
                            <Table.Cell>
                                {description}
                            </Table.Cell>
                            <Table.Cell>
                                {quantity}
                            </Table.Cell>
                            <Table.Cell>
                                {payableQuantity}
                            </Table.Cell>
                            <Table.Cell>
                                {payableUnitPrice}
                            </Table.Cell>
                            <Table.Cell>
                                {totalIncurredAmount}
                            </Table.Cell>
                            <Table.Cell>
                                {totalPayableAmount}
                            </Table.Cell>
                            <Table.Cell>
                                {amountDeclined}
                            </Table.Cell>
                            <Table.Cell>
                                {`${remarkCode} - ${remarkText}`}
                            </Table.Cell>
                        </Table.Row>
                    );
                })
            }
            </Table.Body>
        </Table>
    );
}

export default RecordCaseViewCaseLimitsTable;