import React from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Grid, Button } from 'semantic-ui-react';
import _ from 'lodash';

import FormsInputsDropdownSearch from '../../inputs/dropdownsearch';
import FormsInputsText from '../../inputs/text';
import FormsInputsTextArea from '../../inputs/textarea';
import ClaimPendingDocumentStatusSchema from './validate';

const prepareDropdownSelection = (list) => {
    return _.map(list, (item) => {
        return { text: item.description, value: item.description };
    })
}

const prepareDropdownPending = (list) => {
    const fixList = [];
    // console.log(list);

    for (let li of list) {
        // console.log(li);
        // console.log(li.description);
        const text = 'Medisave Transaction for Medical Expenses';
        const regex = new RegExp(text, 'gi');
        if (li.description.match(regex)) {
            // console.log(li.description);
            fixList.push({
                text: text, value: li.description2
            });
        } else {
            fixList.push({
                text: li.description, value: li.description
            });
        }
    }

    return fixList;
}

const FormsClaimsPendingDocument = (props) => {
    const pendingOptions = prepareDropdownSelection(props.pendingOptions)
    const pendingReviewOptions = prepareDropdownPending(props.pendingReviewOptions)
    return (
        <Formik
            initialValues={{ 
                pendingReason: '',
                pendingReasons: []
            }}
            onSubmit={(values, actions) => {
                // console.log(values, actions);
                props.submitAdjudicationResults(values);
            }}
            validationSchema={ClaimPendingDocumentStatusSchema}
            render={({
                values,
                errors,
                status,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
            }) => (
                <Form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Field
                                    name="pendingReason"
                                    label=""
                                    component={FormsInputsDropdownSearch}
                                    secondaryLabel="Pending Reason"
                                    options={pendingReviewOptions}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Field 
                                    name="pendingReason" 
                                    label="" 
                                    component={FormsInputsTextArea} 
                                    secondaryLabel="Additional Pending Reason Free Text"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid columns={1}>
                                <FieldArray
                                    name="pendingReasons"
                                    render={arrayHelpers => (
                                        <Grid.Column>
                                            {
                                                values.pendingReasons && values.pendingReasons.length > 0 ? (
                                                    values.pendingReasons.map((pendingReason, index) => (
                                                        <div key={index}>
                                                            <Field 
                                                                name={`pendingReasons.${index}`} 
                                                                label="" 
                                                                component={FormsInputsDropdownSearch} 
                                                                secondaryLabel="Document To Request"
                                                                options={pendingOptions}
                                                            />
                                                                <Button
                                                                    size="mini"
                                                                    type="button"
                                                                    onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                                >
                                                                    -
                                                                </Button>
                                                                <Button
                                                                    size="mini"
                                                                    type="button"
                                                                    onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                                                >
                                                                    +
                                                                </Button>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <Button type="button" onClick={() => arrayHelpers.push('')}>
                                                        {/* show this when user has removed all friends from the list */}
                                                        Add a document to request for
                                                    </Button>
                                                )
                                            }
                                        </Grid.Column>
                                    )}
                                />
                            </Grid>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Button
                                    color='yellow'
                                    type="submit"
                                    fluid
                                    disabled={props.isSubmitting}
                                    loading={props.isSubmitting}
                                >
                                    Put Claim on Hold and Inform Claimant
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            )}
        />
    );
}

export default FormsClaimsPendingDocument;