import React from 'react';
import {
    Segment,
    Image,
    Table,
    Loader
} from 'semantic-ui-react';
import _ from 'lodash';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const ClaimImageDisplaySupport = (props) => {
    return (
        <div>
            {
                _.map(props.supportDocuments, (document) => {
                    const {
                        id,
                        viewUrl,
                        file_name,
                        attachment_token,
                        loadingFileUrl,
                        fileUrl
                    } = document
                    return (
                        <Segment key={id}>
                            <Table>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>
                                            {attachment_token ?
                                                loadingFileUrl ?
                                                    (
                                                        <Loader active size='small' inline></Loader>
                                                    )
                                                    :
                                                    fileUrl ?
                                                        (
                                                            <a href={fileUrl} target="_blank">
                                                                Open File
                                                            </a>
                                                        )
                                                        :
                                                        (
                                                            <a
                                                                href="#"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    MySwal.fire({
                                                                        icon: "error",
                                                                        title: "Error",
                                                                        text: "Unable to open!",
                                                                    });
                                                                }}
                                                            >
                                                                Open File
                                                            </a>
                                                        )
                                                :
                                                (
                                                    <a href={viewUrl} target="_blank">
                                                        Open File
                                                    </a>
                                                )
                                            }
                                        </Table.Cell>
                                        <Table.Cell>
                                            {file_name}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                                { attachment_token ?
                                    loadingFileUrl ?
                                        (
                                            <Loader active size='small' inline='centered'></Loader>
                                        )
                                        :
                                        (
                                            <Image ui={true} src={fileUrl} fluid />
                                        )
                                    :
                                    (
                                        <Image src={viewUrl} fluid />
                                    )
                                }
                        </Segment>
                    )
                })
            }
        </div>
    );
}

export default ClaimImageDisplaySupport;