import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, Grid } from 'semantic-ui-react'
import moment from 'moment';
// import _ from 'lodash';
// import uuidv1 from 'uuid/v1';

// import FormsInputsText from '../../inputs/text';
import FormsInputDate from '../../inputs/date';
// import FormsInputDropdown from '../../inputs/dropdown';
// import FormsInputsTextNormal from '../../inputs/text_normal';
// import FormsInputsDropdownAddition from '../../inputs/dropdownaddition';

class FormsReportsTypeOne extends Component {
    render() {
        return (
            <Formik
                initialValues={{ 
                    fromDate: moment().subtract(7, 'days'),
                    toDate: moment(),
                }}
                onSubmit={(values, actions) => {
                    console.log(values, actions);
                    const {accessToken} = this.props.user;
                    this.props.submitGetReportOneData({accessToken, data: values}, (result, error) => {
                        actions.setSubmitting(false);
                    });
                }}
                // validationSchema={LoginSchema}
                render={({
                    values,
                    errors,
                    status,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <Form>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={3}>
                                    <Field
                                        name="fromDate" 
                                        label="" 
                                        secondaryLabel="From Date"
                                        component={FormsInputDate}
                                    />
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <Field
                                        name="toDate" 
                                        label="" 
                                        secondaryLabel="To Date"
                                        component={FormsInputDate}
                                    />
                                </Grid.Column>
                                <Grid.Column width={10} textAlign="right" verticalAlign="middle">
                                    <Button 
                                        type="submit" 
                                        secondary 
                                        disabled={isSubmitting} 
                                        fluid 
                                        loading={isSubmitting}
                                        size="medium"
                                    >
                                        Generate Report
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                )}
            />
        );
    }
}

export default FormsReportsTypeOne;