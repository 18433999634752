import React from "react";
import { Table } from "semantic-ui-react";
import _ from "lodash";

import AdjudicateClaimRow from "./adjudicate_claim_row";

const AdjudicateClaimTable = props => {
   return (
      <Table celled>
         <Table.Header>
            <Table.Row>
               <Table.HeaderCell>No.</Table.HeaderCell>
               <Table.HeaderCell>Claim ID</Table.HeaderCell>
               <Table.HeaderCell>Case ID</Table.HeaderCell>
               <Table.HeaderCell>Status</Table.HeaderCell>
               <Table.HeaderCell>Type</Table.HeaderCell>
               <Table.HeaderCell>Created At</Table.HeaderCell>
            </Table.Row>
         </Table.Header>
         <Table.Body>
            {props.claims.length > 0 ? (
               _.map(props.claims, (claim, index) => {
                  return <AdjudicateClaimRow claim={claim} key={claim.id} index={props.offSet * 10 + index}/>;
               })
            ) : (
               <Table.Row>
                  <Table.Cell>No Claims To Display</Table.Cell>
               </Table.Row>
            )}
         </Table.Body>
      </Table>
   );
};

export default AdjudicateClaimTable;
