import React from "react";
import { Segment, Grid, Tab, Button, Message } from "semantic-ui-react";

import EobBenefitItemsControl from "./eob_benefit_items_control";
import EobPaymentItemsControl from "./eob_payment_items_control";

const ClaimEobItemsControl = (props) => {
  const panes = [
    {
      menuItem: { key: "1", icon: "dolly flatbed", content: "Benefit Items" },
      render: () => {
        return (
          <EobBenefitItemsControl
            adjEobItems={props.adjEobItems}
            removeEobItemLocally={props.removeEobItemLocally}
            claimId={props.claimId}
          />
        );
      },
    },
    {
      menuItem: { key: "2", icon: "dollar sign", content: "Payment Items" },
      render: () => {
        return (
          <EobPaymentItemsControl
            adjPaymentItems={props.adjPaymentItems}
            removePaymentEobItemLocally={props.removePaymentEobItemLocally}
            claimId={props.claimId}
          />
        );
      },
    },
  ];
  const isReadyToSubmit =
    props.adjEobPayableAmount === props.adjEobPaymentAmount &&
    props.adjEobIncurredAmount > 0 && props.adjPaymentItems.length > 0;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Segment>
            <div style={{display: 'flex', justifyContent: 'space-evenly', marginBottom: '2%'}}>
              <div>(T_I) ${props.adjEobIncurredAmount}</div>
              <div>(T_B) ${props.adjEobPayableAmount}</div>
              <div>(T_P) ${props.adjEobPaymentAmount}</div>
            </div>
            <Button
              secondary
              fluid
              disabled={props.isSubmitting === true ? true : !isReadyToSubmit}
              onClick={() => props.submitAdjudicationResults()}
              loading={props.isSubmitting}
            >
              Approve Claim and Generate EOB Letter
            </Button>
            {props.adjError && (
              <Message negative>
                <Message.Header>Unable to proceed</Message.Header>
                <p>
                  {props.adjErrorObject && props.adjErrorObject.errorMessage}
                </p>
              </Message>
            )}
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Tab panes={panes} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ClaimEobItemsControl;
