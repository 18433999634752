import {
    GET_BATCH_ALL_LIST_SUCCESS,
    // GET_BATCH_ALL_LIST_ERROR,
    GET_BATCH_DETAIL_SUCCESS,
    // GET_BATCH_DETAIL_ERROR,
    GET_BATCH_PENDING_REJECTED_CLAIMS_TO_PROCESS_SUCCESS,
    // GET_BATCH_PENDING_REJECTED_CLAIMS_TO_PROCESS_ERROR,
    GET_BATCH_ALL_MISSION_EMAIL_CASE_SUCCESS,
    GENERATE_DBS_PAYMENT_FILES_SUCCESS
} from '../actions';

const initialState = {
    listData: [],
    viewData: null,
    claimsToProcess: [],
    claimsToProcessDateTimeStamp: null,
    viewDataToProcessDateTimeStamp: null,
};

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_BATCH_PENDING_REJECTED_CLAIMS_TO_PROCESS_SUCCESS:
            return Object.assign({}, state, {claimsToProcess: action.payload.data.claimsToProcess, claimsToProcessDateTimeStamp: action.payload.data.claimsToProcessDateTimeStamp});
        case GET_BATCH_ALL_LIST_SUCCESS:
            return Object.assign({}, state, {listData: action.payload.data.batches});
        case GET_BATCH_DETAIL_SUCCESS:
            return Object.assign({}, state, {viewData: action.payload.data.batch, viewDataToProcessDateTimeStamp: action.payload.data.viewDataToProcessDateTimeStamp});
        case GET_BATCH_ALL_MISSION_EMAIL_CASE_SUCCESS:
            return Object.assign({}, state, {caseList: action.payload.data.batches});
        case GENERATE_DBS_PAYMENT_FILES_SUCCESS:
            return Object.assign({}, state, {DBSPaymentFile: action.payload.data.DBSPaymentFile});
        default:
        return state;
    }
}