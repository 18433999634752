import React from "react";
import { Grid, Tab } from "semantic-ui-react";

import ClaimCasePane from "./claim_case_pane";
import ClaimClaimPane from "./claim_detail_pane";

const ClaimCaseDataDisplay = (props) => {
  const panes = [
    {
      menuItem: {
        key: "claim",
        icon: "file alternate outline",
        content: "Claim",
      },
      render: () => <ClaimClaimPane claimData={props.claimData} />,
    },
    {
      menuItem: { key: "case", icon: "briefcase", content: "Case" },
      render: () => <ClaimCasePane caseData={props.caseData} />,
    },
  ];
  return <Tab panes={panes}/>;
};

export default ClaimCaseDataDisplay;
