import React from 'react';
import { Grid, Header, Segment } from 'semantic-ui-react'

import FormsBatchIncomeReviewForm from '../../../forms/batch/income_review';

const BatchUpdateClaimsTableIncomeCheck = (props) => {
    return (
        <Grid.Row>
            <Grid.Column>
                <Header attached="top" block as="h4">
                    Claims To Pay
                </Header>
                <Segment attached>
                    <FormsBatchIncomeReviewForm
                        batchClaims={props.batchClaims}
                        user={props.user}
                        batchId={props.batchId}
                        batchName={props.batchName}
                        submitIncomeReviewBatchResults={props.submitIncomeReviewBatchResults}
                        getEobFilesList={props.getEobFilesList}
                    />
                </Segment>
            </Grid.Column>
        </Grid.Row>
    );
}

export default BatchUpdateClaimsTableIncomeCheck;