import React from "react";
import { Formik, Form, Field } from "formik";

import FormsInputDropdown from "../../inputs/dropdown";
import { Button, Grid } from "semantic-ui-react";
const injuryOptions = [
   { value: "Burns (Incl. Contact with chemical)", text: "Burns (Incl. Contact with chemical)" },
   { value: "Cuts/Laceration/Abrasions", text: "Cuts/Laceration/Abrasions" },
   { value: "Dental-related Injuries", text: "Dental-related Injuries" },
   { value: "Food Poisoning", text: "Food Poisoning" },
   { value: "Fracture", text: "Fracture" },
   { value: "Infectious Diseases (eg. Dengue Fever, HFMD)", text: "Infectious Diseases (eg. Dengue Fever, HFMD)" },
   { value: "Insect Bites", text: "Insect Bites" },
   { value: "Sprain/Twist/Tear/Swelling/Dislocation", text: "Sprain/Twist/Tear/Swelling/Dislocation" },
   { value: "Swallowing Foreign Object", text: "Swallowing Foreign Object" }
];

const FormsInjuryType = props => {
   return (
      <Formik
         initialValues={{
            injuryType: ""
         }}
         onSubmit={(values, actions) => {
            props.submitInjuryType(values.injuryType);
         }}
         render={({ values, errors, status, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
            <Form>
               <Grid>
                  <Grid.Row>
                     <Grid.Column>
                        <Field
                           name="injuryType"
                           options={injuryOptions}
                           label=""
                           secondaryLabel=""
                           component={FormsInputDropdown}
                        />
                     </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                     <Grid.Column>
                        <Button type="submit" primary>
                           Save
                        </Button>
                        <Button onClick={props.cancelInjuryModal}>
                           Cancel
                        </Button>
                     </Grid.Column>
                  </Grid.Row>
               </Grid>
            </Form>
         )}
      />
   );
};

export default FormsInjuryType;
