module.exports = {
   getActivityTypeCCATypes() {
      return [
         {
            text: "Clubs &amp; Societies (e.g. Chess/Debate/Library/Photography)",
            value: "Clubs &amp; Societies (e.g. Chess/Debate/Library/Photography)"
         },
         {
            text: "Physical Sports (e.g. Basketball/Floorball/Football)",
            value: "Physical Sports (e.g. Basketball/Floorball/Football)"
         },
         { text: "Uniformed Groups (e.g. NCC/NPCC/Red Cross)", value: "Uniformed Groups (e.g. NCC/NPCC/Red Cross)" },
         {
            text: "Visual and Performing Arts (e.g. Band/Choir/Dance)",
            value: "Visual and Performing Arts (e.g. Band/Choir/Dance)"
         }
      ];
   },
   getActivityTypes() {
      return [
         { text: "Accidental", value: "Accidental" },
         { text: "CCA / Sports", value: "CCA / Sports" },
         { text: "Physical Education (PE)", value: "Physical Education (PE)" },
         { text: "School Events", value: "School Events" },
         { text: "Sick (Incl. Food Poisoning)", value: "Sick (Incl. Food Poisoning)" },
         { text: "Student Misbehaviour (Incl. Fight/Bully)", value: "Student Misbehaviour (Incl. Fight/Bully)" },
         { text: "To and From School", value: "To and From School" }
      ];
   },
   getInjuryTypeCCATypes() {
      return [
         { text: "-", value: "-" },
         { text: "Burns (Incl. Contact with chemical)", value: "Burns (Incl. Contact with chemical)" },
         { text: "Cuts/Laceration/Abrasions", value: "Cuts/Laceration/Abrasions" },
         { text: "Dental-related Injuries", value: "Dental-related Injuries" },
         { text: "Food Poisoning", value: "Food Poisoning" },
         { text: "Fracture", value: "Fracture" },
         {
            text: "Infectious Diseases (E.g. Dengue Fever, HFMD)",
            value: "Infectious Diseases (E.g. Dengue Fever, HFMD)"
         },
         { text: "Insect Bites", value: "Insect Bites" },
         { text: "Sprain/Twist/Tear/Swelling/Dislocation", value: "Sprain/Twist/Tear/Swelling/Dislocation" },
         { text: "Swallowing Foreign Object", value: "Swallowing Foreign Object" }
      ];
   },
   getBanks() {
      return [
         { value: "7171-DBS", text: "DBS" },
         { value: "7339", text: "OCBC" },
         { value: "7171-POSB", text: "POSB" },
         { value: "7375-UOB", text: "UOB" },
         { value: "7931", text: "Australia and New Zealand Banking Group Ltd" },
         { value: "7047", text: "Bangkok Bank Public Company Ltd", branch: "702" },
         { value: "7065", text: "Bank of America, NA", branch: "212" },
         { value: "7083", text: "Bank of China Limited" },
         { value: "7108", text: "Bank of India", branch: "001" },
         { value: "7418", text: "BNP Paribas" },
         { value: "7986", text: "CIMB Bank Berhad", branch: "001" },
         { value: "7214", text: "Citibank NA" },
         { value: "8606", text: "Commerzbank AG", branch: "001" },
         { value: "7135", text: "Credit Agricole Corporate and Investment Bank", branch: "001" },
         { value: "9353", text: "CTBC Bank Co., Ltd", branch: "001" },
         { value: "7463", text: "Deutsche Bank AG", branch: "001" },
         { value: "7737", text: "DnB NOR Bank ASA", branch: "001" },
         { value: "7764", text: "First Commercial", branch: "001" },
         { value: "7287", text: "HL", branch: "001" },
         { value: "7232", text: "HSBC" },
         { value: "9186", text: "ICICI Bank Limited" },
         { value: "7241", text: "Indian", branch: "001" },
         { value: "7250", text: "Indian Overseas", branch: "001" },
         { value: "7375", text: "Industrial &amp; Commercial Bank of China" },
         { value: "8350", text: "Intesa Sanpaolo S.P.A.", branch: "001" },
         { value: "7153", text: "JPMorgan Chase Bank, N.A." },
         { value: "7490", text: "Korea Exchange", branch: "001" },
         { value: "7302", text: "Malayan Banking Berhad" },
         { value: "7621", text: "Mizuho Corporate Bank, Ltd" },
         { value: "8077", text: "National Australia Bank Ltd", branch: "001" },
         { value: "8518", text: "Nordea Bank Finland Plc", branch: "001" },
         { value: "7366", text: "RHB Bank Berhad" },
         { value: "8527", text: "Skandinaviska Enskilda Banken AB (publ)", branch: "001" },
         { value: "7852", text: "Societe Generale", branch: "001" },
         { value: "7144", text: "Standard Chartered" },
         { value: "7791", text: "State Bank of India" },
         { value: "7472", text: "Sumitomo Mitsui Banking Corporation", branch: "806" },
         { value: "8493", text: "Svenska Handelsbanken AB (publ)", branch: "001" },
         { value: "7092", text: "The Bank of East Asia Ltd", branch: "001" },
         { value: "7126", text: "The Bank of Tokyo-Mitsubishi UFJ, Ltd", branch: "001" },
         { value: "7685", text: "UBS AG" },
         { value: "7357", text: "UCO" }
      ];
   }
}

      // let initialValues = {
      //    caseId: this.props.caseId,
      //    claimId: this.props.claimId,
      //    insuredName: "dummy",
      //    insuredNRIC: "dummy",
      //    insuredGender: "Male",
      //    insuredDOB: moment(),
      //    insuredCountry: "Singapore",
      //    school: "dummy",
      //    level: "dummy",
      //    class: "dummy",
      //    postcode: "dummy",
      //    houseNo: "dummy",
      //    streetName: "dummy",
      //    unitNo: "dummy",
      //    buildingName: "dummy",
      //    parentEmail: "dummy",
      //    parentMobileNo: "123",
      //    parentHomeNo: "123",
      //    accidentDate: moment(),
      //    accidentTime: moment(),
      //    accidentPlace: "dummy",
      //    activityType: "Accidental",
      //    activityTypeCCAType: "dummy",
      //    activityTypeCCAName: "dummy",
      //    injuryType: "Fracture",
      //    injuryTypeDescription: "dummy",
      //    accidentDescription: "dummy",
      //    claimFromOther: "0",
      //    remarks: "dummy",
      //    paymentMode: "Cheque",
      //    relationshipToInsured: "Parent",
      //    relationshipToInsuredOther: "dummy",
      //    bankName: "dummydummy",
      //    bankCode: "",
      //    accountNo: "dummy",
      //    bankBranch: "dummy",
      //    payeeName: "dummy",
      //    payeeNRIC: "dummy",
      //    payeeDOB: moment(),
      //    payeeGender: "Male",
      //    payeeCountryBirth: "Singapore",
      //    parentName: "dummy",
      //    parentNRIC: "dummy",
      //    parentRelationshipToInsured: "dummy",
      //    stampDate: moment(),
      //    schoolName: "dummy",
      //    schoolAddress: "dummy",
      //    schoolZone: "dummy",
      //    adminName: "dummy",
      //    adminEmail: "dummy",
      //    adminMobileNo: "123",
      //    adminHomeNo: "123",
      //    endorsementDate: moment(),
      //    endorsementRemarks: "dummy",
      //    radioEndorseDecline: "0",
      //    receipts: getReceipts(this.props.claimData.receipts),
      //    createdBy: this.props.user.id
      // };