import React from 'react';

import FormsRecordSearchForm from '../../forms/record/search';

const RecordSearchBar = (props) => {
    return (
        <FormsRecordSearchForm 
            getRecordSearchResults={props.getRecordSearchResults}
            user={props.user}
        />
    );
}

export default RecordSearchBar;