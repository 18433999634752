import React from 'react';
import { Table, Button } from 'semantic-ui-react'
import Moment from 'moment';
import { Link } from 'react-router-dom';

const BatchResultListItem = (props) => {
    const {
        id,
        batch_name,
        num_of_claims,
        created_at,
        status,
    } = props.batch;

    return (
        <Table.Row key={id}>
            <Table.Cell>
                {id}
            </Table.Cell>
            <Table.Cell>
                <Link to={`/app/batches/${id}`}>
                    {batch_name}
                </Link>
            </Table.Cell>
            <Table.Cell>
                {status}
            </Table.Cell>
            <Table.Cell>
                {Moment(created_at).format('DD/MM/YYYY HH:mm')}
            </Table.Cell>
            <Table.Cell>
                {num_of_claims}
            </Table.Cell>
            <Table.Cell>
                {status === 'closed' ? <Button primary fluid loading={props.generatingDBSPaymentFiles && props.generatingId === id} onClick={props.generateDBSPaymentFile}>Generate DBS Payment File</Button> : null}
            </Table.Cell>
        </Table.Row>
    );
}

export default BatchResultListItem;