import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { doLogout, getTotalCheckClaims } from "../../../actions";
import { Container, Dropdown, Menu } from "semantic-ui-react";
import { PERMISSION_PROCESS_ADJUDICATION, PERMISSION_PROCESS_BATCH, PERMISSION_VIEW_KPI_REPORTS } from '../../../utils/constant_permission_list';

class NavTopbar extends Component {
   componentDidMount() {
      const { accessToken } = this.props.user;
      this.props.getTotalCheckClaims({ accessToken, claimType: "New,Follow-Up".split(",") });
   }

   render() {
      return (
         <Menu fixed="top" inverted>
            <Container>
               <Menu.Item header>MIGHTY-adj</Menu.Item>
               <Link to="/app">
                  <Menu.Item>Home</Menu.Item>
               </Link>
               <Link to="/app/database">
                  <Menu.Item>Database</Menu.Item>
               </Link>
               {this.props.user.type === 'ADJ' || this.props.user.permission.map(p => p).includes(PERMISSION_PROCESS_ADJUDICATION) ?
                  <Link to="/app/adjudicate">
                     <Menu.Item>Adjudicate</Menu.Item>
                  </Link>
               : null}
               <Link to="/app/checknewclaims">
                  <Menu.Item>Check New Claims ({this.props.claimCount})</Menu.Item>
               </Link>
               {this.props.user.type === 'ADJ' || this.props.user.permission.map(p => p).includes(PERMISSION_PROCESS_BATCH) ?
                  <Dropdown item text="Process Batch">
                     <Dropdown.Menu>
                        <Link to="/app/batches">
                           <Dropdown.Item style={{ color: "#000" }}>View / Search Batch</Dropdown.Item>
                        </Link>
                        <Link to="/app/batchesupdatependingrejected">
                           <Dropdown.Item style={{ color: "#000" }}>Pending / Rejected Cases</Dropdown.Item>
                        </Link>
                     </Dropdown.Menu>
                  </Dropdown>
               : null}
               <Dropdown item text="Reports">
                  <Dropdown.Menu>
                     <Link to="/app/reports/statuschangereport">
                        <Dropdown.Item style={{ color: "#000" }}>Adjudication Status Change Report</Dropdown.Item>
                     </Link>
                     <Link to="/app/reports/billingreport">
                        <Dropdown.Item style={{ color: "#000" }}>Billing Report</Dropdown.Item>
                     </Link>
                     {this.props.user.permission.map(p => p).includes(PERMISSION_VIEW_KPI_REPORTS) ?
                        <Link to="/app/reports/kpireport">
                           <Dropdown.Item style={{ color: "#000" }}>KPI Report</Dropdown.Item>
                        </Link>
                     : null}
                  </Dropdown.Menu>
               </Dropdown>

               <Dropdown item text="Users">
                  <Dropdown.Menu>
                     <Link to="/app/users">
                        <Dropdown.Item style={{ color: "#000" }}>List Users</Dropdown.Item>
                     </Link>
                     <Link to="/app/adduser">
                        <Dropdown.Item style={{ color: "#000" }}>Add User</Dropdown.Item>
                     </Link>
                  </Dropdown.Menu>
               </Dropdown>
               <Link to="/">
                  <Menu.Item as="div" onClick={() => this.props.doLogout()}>
                     Log-Out
                  </Menu.Item>
               </Link>
            </Container>
         </Menu>
      );
   }
}

const mapStateToProps = state => {
   return {
      user: state.user.data,
      claimCount: state.checkclaim.claimCount
   };
};

export default connect(
   mapStateToProps,
   {
      doLogout,
      getTotalCheckClaims
   }
)(NavTopbar);

/*
<Menu.Item as='a' header>
    <Image size='mini' src='/logo.png' style={{ marginRight: '1.5em' }} />
    MIGHTY-adj
</Menu.Item>

<Dropdown item simple text='Dropdown'>
    <Dropdown.Menu>
        <Dropdown.Item>List Item</Dropdown.Item>
        <Dropdown.Item>List Item</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Header>Header Item</Dropdown.Header>
        <Dropdown.Item>
            <i className='dropdown icon' />
            <span className='text'>Submenu</span>
            <Dropdown.Menu>
                <Dropdown.Item>List Item</Dropdown.Item>
                <Dropdown.Item>List Item</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown.Item>
        <Dropdown.Item>List Item</Dropdown.Item>
    </Dropdown.Menu>
</Dropdown>
*/
