import React, { Component } from 'react';
import { Container, Grid, Loader, Button, Segment, Tab } from 'semantic-ui-react';
import { connect } from 'react-redux';

import {
    getRecordCaseDetails
} from '../../actions';
import RecordCaseViewDetails from '../../component/record/case_view/case_details';
import RecordCaseViewCaseNotifications from '../../component/record/case_view/case_details_notifications';
import RecordCaseViewClaimList from '../../component/record/case_view/case_claim_list';
import RecordCaseViewCaseLimits from '../../component/record/case_view/case_limits';
import RecordCaseViewCaseEobFiles from '../../component/record/case_view/case_eob_files';

class RecordDatabaseCaseViewPage extends Component {
    componentDidMount() {
        const { caseId } = this.props.match.params;
        // fetch case details (include assiocated claims from API)
        this.props.getRecordCaseDetails({caseId});
    }
    render() {
        const { caseId } = this.props.match.params;
        if (!this.props.caseData) { return <Loader active />; }
        if (this.props.caseData && this.props.caseData.case_id.toString() !== caseId.toString()) { return <Loader active />; }
        const panes = [
            { menuItem: 'Case Details', render: () => <Tab.Pane><RecordCaseViewDetails caseData={this.props.caseData} /></Tab.Pane> },
            { menuItem: 'Related Claims', render: () => <Tab.Pane><RecordCaseViewClaimList claims={this.props.caseData.relatedClaims}/></Tab.Pane> },
            { menuItem: 'Notifications', render: () => <Tab.Pane><RecordCaseViewCaseNotifications notificationData={this.props.caseData.relatedNotifications} /></Tab.Pane> },
            { menuItem: 'Benefit Limits', render: () => <Tab.Pane><RecordCaseViewCaseLimits eobItems={this.props.caseData.relatedEobItemsData} benefitSchemeItems={this.props.benefitSchemeItems} benefitScheme={this.props.benefitScheme} benefitLimitsAggregated={this.props.caseData.benefitLimitsAggregated}/></Tab.Pane> },
            { menuItem: 'EOB Files', render: () => <Tab.Pane><RecordCaseViewCaseEobFiles eobFiles={this.props.caseData.relatedEobFilesData}/></Tab.Pane> },
        ];
        return (
            <Container>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment raised>
                                <h3>Details For Case# {caseId}</h3>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Tab panes={panes} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        caseData: state.records.selectedCase,
        isFetching: state.records.isFetching,
        benefitSchemeItems: state.config.benefitSchemeItems,
        benefitScheme: state.config.benefitScheme,
    };
}

export default connect(mapStateToProps,{
    getRecordCaseDetails
})(RecordDatabaseCaseViewPage);

/*
<Segment>
    <Button secondary>
        Refund EOB Set Status to NEW
    </Button>
</Segment>
*/