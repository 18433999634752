import React from 'react';

import FormsBatchSearchForm from '../../forms/batch/search';

const RecordSearchBar = (props) => {
    return (
        <FormsBatchSearchForm 
            getSearchBatchList={props.getSearchBatchList}
            user={props.user}
        />
    );
}

export default RecordSearchBar;