import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, Message } from 'semantic-ui-react'

import FormsInputsText from '../inputs/text';
import FormsInputPassword from '../inputs/password';
import LoginSchema from './validate';

class FormsLogin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: '',
            otp: '',
            otpPrefix: '',
            otpSent: false,
            error: false,
            errorMessage: ''
        }

        this.backToLogin = this.backToLogin.bind(this);
        this.otpSignIn = this.otpSignIn.bind(this);
    }

    backToLogin(values) {
        values.otp = "";
        this.setState({otpSent: false, error: false, errorMessage: ''});
    }

    otpSignIn(values) {
        const id = this.state.user.id;
        const otp = this.state.otpPrefix + values.otp;

        this.props.doOTPLogin({id, otp}, (result, error) => {
            if(error) {
                this.setState({error: true, errorMessage: error.errorMessage});
            }
        })
    }

    renderFormSubmissionResult() {
        if (this.state.error === true) {
            return (
                <Message negative>
                    <Message.Header>We're sorry - there was an issue with your credentials</Message.Header>
                    <p>
                        { this.state.errorMessage }
                    </p>
                </Message>
            );
        }
    }
    render() {
        return (
            <React.Fragment>
                {this.state.otpSent ?
                <Formik
                initialValues={{ otp: '' }}
                onSubmit={(values, actions) => {
                    // console.log(values, actions);
                    this.setState({error: false, errorMessage: ''});

                    this.otpSignIn(values)
                }}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={LoginSchema}
                render={({
                    values,
                    errors,
                    status,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <Form>
                         <React.Fragment>
                            <Message positive>
                                <Message.Header>Check your Email!</Message.Header>
                                <p>
                                    An OTP verification has been sent to <b>{values.email}</b>.
                                </p>
                            </Message>
                            <Field name="otp" label='' inputLabel={this.state.otpPrefix} component={FormsInputsText} secondaryLabel="One Time Password"/>
                            <p></p>
                            <Button type="button" primary fluid onClick={() => {this.backToLogin(values)}}>Back to Login</Button>
                            <p></p>
                            <Button type="submit" primary fluid>Sign In</Button>
                        </React.Fragment>

                        {this.renderFormSubmissionResult()}
                    </Form>
                )}
            />
            :
            <Formik
               initialValues={{ email: '', password: ''}}
               onSubmit={(values, actions) => {
                   // console.log(values, actions);
                   this.setState({error: false, errorMessage: ''});

                   if(process.env.REACT_APP_LOGIN_WITH_OTP === "1") {
                       this.props.sendOTP(values, (result, error) => {
                           actions.setSubmitting(false);

                           if(error) {
                               this.setState({error: true, errorMessage: error.errorMessage});
                           } else {
                               this.setState({otpSent: true, otpPrefix: result.data.user.otp, user: result.data.user});
                           }
                       })


                   } else {
                       this.props.doLogin(values, (result, error) => {
                           if (error) {
                               actions.resetForm()
                               actions.setSubmitting(false);
                               this.setState({error: true, errorMessage: error.errorMessage});
                           }
                       });
                   }


               }}
               validateOnBlur={false}
               validateOnChange={false}
               validationSchema={LoginSchema}
               render={({
                   values,
                   errors,
                   status,
                   touched,
                   handleBlur,
                   handleChange,
                   handleSubmit,
                   isSubmitting,
               }) => (
                   <Form>
                       <React.Fragment>
                           <Field name="email" label="" component={FormsInputsText} secondaryLabel="Email Address"/>
                           <p></p>
                           <Field name="password" label="" component={FormsInputPassword} secondaryLabel="Password"/>
                           <p></p>
                           <Button type="submit" primary disabled={isSubmitting} fluid loading={isSubmitting}>Sign In</Button>
                       </React.Fragment>

                       {this.renderFormSubmissionResult()}
                   </Form>
               )}
           />
               }
            </React.Fragment>
        );
    }
}

export default FormsLogin;