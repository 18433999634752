import React from 'react'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'

const ClaimResultMessageModal = (props) => {
    return (
        <Modal
            closeOnDimmerClick={false}
            open={props.show}
            onClose={() => console.log('close modal')}
            basic
            size='small'
        >
            <Header icon='browser' content='Success!' />
            <Modal.Content>
                <h3>Claim was updated successfully.</h3>
            </Modal.Content>
            <Modal.Actions>
                <Button color='green' inverted onClick={() => props.history.push('/app/adjudicate')}>
                    <Icon name='checkmark' /> Got it!
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ClaimResultMessageModal;
