import React, { Component } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Input, Header, Icon, Label } from "semantic-ui-react";

import "react-datepicker/dist/react-datepicker.css";

class FormsInputsAllDate extends Component {
  state = {
    startDate: this.props.field ? this.props.field.value : moment()
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    // // console.log(this.props.field.name, '==>', prevProps.field.value, this.props.field.value)
    // if (this.props.field.value.isSame(prevProps.field.value)) {
    //   // console.log(this.props.field.name, '==> SAME (PROPS NO CHANGE)')
    // } else {
    //   if (this.state.startDate.isSame(prevState.startDate)) {
    //     console.log(
    //       this.props.field.name,
    //       "==> SAME (PROPS CHANGE BUT STATE NO CHHANGE)"
    //     );
    //     this.setState({ startDate: this.props.field.value }); // why did I uncomment this before? 2 Jan 2018
    //   }
    //   // console.log(this.props.field.name, '==> DIFF')
    // }
  }

  handleChange(date) {
    this.setState({
      startDate: date
    });
    this.props.form.setFieldValue(this.props.field.name, date);

    this.props.targetList.forEach((row, index) => {
      if (!row.hasPaid) {
        this.props.form.setFieldValue(
          `${this.props.targetListName}[${index}].${this.props.targetListAttr}`,
          date
        );
      }
    });
  }

  render() {
    const { label, secondaryLabel, minDate, maxDate, disabled } = this.props;
    return (
      <div className="form-input-group">
        <div style={{ paddingBottom: 5 }}>
          <Header sub>{label}</Header>
          <Header.Subheader>{secondaryLabel || ""}</Header.Subheader>
        </div>
        <DatePicker
          dateFormat="DD/MM/YYYY"
          customInput={<Input />}
          selected={this.state.startDate}
          onChange={this.handleChange.bind(this)}
          minDate={minDate ? minDate : null}
          maxDate={maxDate ? maxDate : null}
          disabled={disabled ? disabled : false}
        />
      </div>
    );
  }
}

export default FormsInputsAllDate;
