import Mime from "mime";

const reSetFileNameOnDownloadFile = (fileName, mimeExtension) => {
  let finalFileName = fileName;

  if (mimeExtension) {
    finalFileName = `${fileName}.${mimeExtension}`;

    let splitFileName = fileName.split(".");
    if (splitFileName[splitFileName.length - 1]) {
      if (
          splitFileName[splitFileName.length - 1].toLowerCase() === mimeExtension.toLowerCase() ||
          (splitFileName[splitFileName.length - 1].toLowerCase() === "jpg" && mimeExtension.toLowerCase() === "jpeg")
      ) {
        finalFileName = fileName;
      }
    }
  }

  return finalFileName;
}

export function imageBolb(method, url, rawFileName) {
  return new Promise(function(resolve, reject) {
    let xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    xhr.responseType = "arraybuffer";
    xhr.onload = () => {
      if (xhr.status === 200) {
        let blob = new Blob([xhr.response], {
          type: "application/octet-stream"
        });
        let headers = xhr
          .getAllResponseHeaders()
          .trim()
          .split(/[\r\n]+/);
        let headerMap = {};
        headers.forEach(function(line) {
          let parts = line.split(": ");
          let header = parts.shift();
          let value = parts.join(": ");
          headerMap[header] = value;
        });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          const mime = Mime.getExtension(xhr.getResponseHeader("content-type"));
          const fileName = reSetFileNameOnDownloadFile(rawFileName, mime);
          window.navigator.msSaveOrOpenBlob(blob, fileName);
          return;
        }
        const mime = Mime.getExtension(headerMap["content-type"]);
        const fileName = reSetFileNameOnDownloadFile(rawFileName, mime)

        resolve({ fileName: fileName, blob: blob });
      } else {
        reject({ statusText: xhr.statusText });
      }
    };
    xhr.send();
  });
}
