import React from 'react';
import { Grid, Header, Segment } from 'semantic-ui-react'

import FormsBatchPaymentCompleteForm from '../../../forms/batch/payment_complete';

const BatchUpdateClaimsTablePaymentComplete = (props) => {
    return (
        <Grid.Row>
            <Grid.Column>
                <Header attached="top" block as="h4">
                    Update Claims That Has Completed Payment
                </Header>
                <Segment attached>
                    <FormsBatchPaymentCompleteForm
                        batchClaims={props.batchClaims}
                        user={props.user}
                        batchId={props.batchId}
                        batchName={props.batchName}
                        createdAt={props.createdAt}
                        submitPaidClaimsResults={props.submitPaidClaimsResults}
                        getEobFilesList={props.getEobFilesList}
                        regeneratePaymentFiles={props.regeneratePaymentFiles}
                        getBatchDetail={props.getBatchDetail}
                    />
                </Segment>
            </Grid.Column>
        </Grid.Row>
    );
}

export default BatchUpdateClaimsTablePaymentComplete;