import React from 'react';
import { Label } from 'semantic-ui-react';

const ClaimsStatusLabel = (props) => {
    const statusMapping = {
        "62": "Review MHC Comments-R",
        "61": "Review MHC Comments-P",
        "54": "Rejected MHC Review",
        "53": "Pending Document MHC Review",
        "52": "Pending MHC Reject Review",
        "51": "Pending Income Reject Review",
        "7": "Paid",
        "6": "Pending Payment",
        "5": "Closed",
        "4": "Pending Contact Details",
        "3": "Pending Documents",
        "2": "Processing (Wait for Batch)",
        "1": "Approved",
        "0": "New",
        "70": "Pre-Approved", // Pre-approved
        "71": "Pre-Rejected", // Pre-rejected
        "80": "New", // Required backend check
        "81": "New", // Required backend check
        "-1": "Rejected",
        // "-2": "Rejected Pending Income Review"
    };

    return (
        <Label>
            {statusMapping[props.status]}
        </Label>
    );
}

export default ClaimsStatusLabel;