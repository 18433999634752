import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, Grid, Message } from 'semantic-ui-react'

import FormsInputsText from '../../inputs/text';

class FormsEobAddToRemarks extends Component {
    render() {
        return (
            <Formik
                initialValues={{ 
                    eobAdditionalRemarks: (this.props.adjAdditionalRemarks) ? this.props.adjAdditionalRemarks : ''
                }}
                onSubmit={(values, actions) => {
                    // console.log(values,actions);
                    // values.uuid = uuidv1();
                    // values.schemeData = _.find(this.props.paymentOptions, (option) => option.id === values.paymentItemId);
                    // this.props.addEobPaymentItemLocally(values);
                    // actions.setSubmitting(false);
                    // actions.resetForm();
                    this.props.addEobRemarksLocally(values.eobAdditionalRemarks, this.props.claimId);
                    actions.setSubmitting(false);
                }}
                // validationSchema={LoginSchema}
                render={({
                    values,
                    errors,
                    status,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <Form>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <Message info>
                                        <Message.Header>
                                            EOB Additional Remarks: 
                                        </Message.Header>
                                        {this.props.adjAdditionalRemarks}
                                    </Message>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Field 
                                        name="eobAdditionalRemarks" 
                                        label="" 
                                        component={FormsInputsText} 
                                        secondaryLabel="Additional EOB Remarks"
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Button 
                                        type="submit" primary fluid loading={isSubmitting}
                                    >
                                        Update EOB Additional Remarks
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                )}
            />
        );
    }
}

export default FormsEobAddToRemarks;