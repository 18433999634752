import React from "react";
import { Dropdown, Header, Message } from "semantic-ui-react";

const FormsInputsDropdownSearch = props => {
   const { field, form } = props;

   const handleChange = (e, { value }) => {
      form.setFieldValue(field.name, value);
   };
   const hasError = form.errors[field.name] && form.touched[field.name] ? true : false;

   return (
      <div className="form-input-group">
         <div>
            <Header sub>{props.label}</Header>
            {props.secondaryLabel ? <Header.Subheader>{props.secondaryLabel}</Header.Subheader> : ""}
         </div>
         <Dropdown
            fluid
            search
            selection
            options={props.options}
            onChange={handleChange}
            value={field.value}
            disabled={props.disabled ? props.disabled : false}
         />
         {hasError && <Message negative>{props.form.errors[props.field.name]}</Message>}
      </div>
   );
};

export default FormsInputsDropdownSearch;
