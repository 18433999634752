import React from 'react';
import { Header, Segment, Table } from 'semantic-ui-react';
import Moment from 'moment';

const RecordCaseViewCaseDetailsDOA = (props) => {
    const {
        date_of_accident,
        time_of_accident,
        place_of_accident,
        activity_type,
        injury_type,
        injury_description,
        accident_description,
    } = props.caseData;
    return (
        <div>
            <Header as='h4' attached="top">
                Details of Accident
            </Header>
            <Segment attached>
                <Table basic compact="very" fixed>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                Date of Accident
                            </Table.Cell>
                            <Table.Cell>
                                {Moment.utc(date_of_accident).format('DD/MM/YYYY')}
                            </Table.Cell>
                            <Table.Cell>
                                Time of Accident
                            </Table.Cell>
                            <Table.Cell>
                                {time_of_accident}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Place of Accident
                            </Table.Cell>
                            <Table.Cell>
                                {place_of_accident}
                            </Table.Cell>
                            <Table.Cell>
                                Activity Type
                            </Table.Cell>
                            <Table.Cell>
                                {activity_type}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Injury Type
                            </Table.Cell>
                            <Table.Cell>
                                {injury_type}
                            </Table.Cell>
                            <Table.Cell>
                            </Table.Cell>
                            <Table.Cell>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan={4}>
                                Describe the injuries sustained and the part(s) of the body injured
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan={4}>
                                {injury_description}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan={4}>
                                Describe how the accident happened.
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan={4}>
                                {accident_description}
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
        </div>
    );
}

export default RecordCaseViewCaseDetailsDOA;