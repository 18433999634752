import {
   GET_CHECK_CLAIMS_SUCCESS,
   GET_TOTAL_CHECK_CLAIMS_SUCCESS,
   GET_COUNTRIES_SUCCESS,
   GET_SCHOOLS_SUCCESS
} from "../actions";

const initialState = {
   claims: [],
   allClaims: [],
   countries: [],
   schools: [],
   claimCount: 0
};

export default function(state = initialState, action) {
   switch (action.type) {
      case GET_CHECK_CLAIMS_SUCCESS:
         return Object.assign({}, state, { claims: action.payload.data.claims });
      case GET_COUNTRIES_SUCCESS:
         return Object.assign({}, state, { countries: action.payload });
      case GET_SCHOOLS_SUCCESS:
         return Object.assign({}, state, { schools: action.payload.data.schools });
      case GET_TOTAL_CHECK_CLAIMS_SUCCESS:
         return Object.assign({}, state, {
            claimCount: action.payload.data.claims.length,
            allClaims: action.payload.data.claims
         });
      default:
         return state;
   }
}
