import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid, Button } from 'semantic-ui-react';
import _ from 'lodash';

import FormsInputsText from '../../inputs/text';
import ClaimClosedStatusSchema from './validate';

const FormsClaimsClosed = (props) => {
    return (
        <Formik
            initialValues={{ 
                closedReasonFreeText: '',
            }}
            validationSchema={ClaimClosedStatusSchema}
            onSubmit={(values, actions) => {
                console.log(values);
                props.submitAdjudicationResults(values);
            }}
            render={({
                values,
                errors,
                status,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
            }) => (
                <Form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Field 
                                    name="closedReasonFreeText" 
                                    label="" 
                                    component={FormsInputsText} 
                                    secondaryLabel="Reason For Closure"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Button
                                    color='red'
                                    type="submit"
                                    fluid
                                    disabled={props.isSubmitting}
                                    loading={props.isSubmitting}
                                >
                                    Close This Claim
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            )}
        />
    );
}

export default FormsClaimsClosed;