import React, { Component } from 'react';
import { Container, Grid, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';

import {
    getRecordSearchResults
} from '../../actions';
import RecordSearchBar from '../../component/record/search_bar';
import RecordResultListTable from '../../component/record/result_list_table';

class RecordDatabasePage extends Component {
    render() {
        return (
            <Container>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment>
                                <RecordSearchBar 
                                    getRecordSearchResults={this.props.getRecordSearchResults}
                                    user={this.props.user}
                                />
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <RecordResultListTable 
                                records={this.props.records}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        records: state.records.searchList,
        user: state.user.data,
    };
}

export default connect(mapStateToProps, {
    getRecordSearchResults
})(RecordDatabasePage);