import React from 'react';
import { Grid, Header, Segment, Message } from 'semantic-ui-react'

import FormsBatchPendingRejectedForm from '../../../forms/batch/pending_rejected';

const BatchUpdateClaimsTablePendingRejected = (props) => {
    return (
        <Grid.Row>
            <Grid.Column>
                <Header attached="top" block as="h4">
                    Pending / Rejected Claims To Review
                </Header>
                <Segment attached>
                {
                    (props.claimsToProcess.length > 0) ?
                    (
                        <FormsBatchPendingRejectedForm
                            caseList={props.caseList}
                            batchClaims={props.claimsToProcess}
                            user={props.user}
                            submitPendingRejectedResults={props.submitPendingRejectedResults}
                        />
                    )
                    :
                    (
                        <Message info>
                            There are no pending or rejected claims to review.
                        </Message>
                    )
                }
                </Segment>
            </Grid.Column>
        </Grid.Row>
    );
}

export default BatchUpdateClaimsTablePendingRejected;