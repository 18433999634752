import React from 'react';
import { Header, Segment, List } from 'semantic-ui-react';
import _ from 'lodash';
import Moment from 'moment';

const typeOfEmailMapping = {
    '3': 'Pending Document Reminder',
    '-1': 'Rejection',
    '1': 'Approved / Paid',
}

const RecordClaimViewClaimNotification = (props) => {
    return (
        <div>
            <Header as='h3' block attached="top">
                Notfications
            </Header>
            <Segment attached>
                <List divided relaxed>
                    {
                        _.map(props.notificationData, (item) => {
                            return (
                                <List.Item key={item.id}>
                                    <List.Icon name='mail' />
                                    <List.Content>
                                        <List.Header>
                                            {item.claim_id} - {Moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')}
                                        </List.Header>
                                        <List.Description>
                                            Type of Email: {typeOfEmailMapping[item.type]}
                                            <br />
                                            To: {item.email_address} 
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                            );
                        })
                    }
                    {
                        (props.notificationData.length === 0)
                        ?
                        (
                            <p>
                                No Notifications Sent
                            </p>
                        )
                        :
                        null
                    }
                </List>
            </Segment>
        </div>
    );
}

export default RecordClaimViewClaimNotification;