import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import { Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';

import NavTopbar from '../../component/navigation/topbar';
import MainPage from './main_page';
// import ClaimRecordPage from './claim_record_page';
// import CaseRecordPage from './case_record_page';
import RecordDatabasePage from './record_database_page';
import AdjudicateRecordPage from './adjudicate_record_page';
import AdjudicateClaimPage from './adjudicate_claim_page';
import RecordDatabaseCaseViewPage from './record_database_case_view_page';
import RecordDatabaseClaimViewPage from './record_database_claim_view_page';
import BatchListPage from './batch_list_page';
import BatchViewPage from './batch_view_page';
import AddUserPage from './add_user_page';
import UserListPage from './user_list_page';
import AdjucationStatusChangeReportsPage from './adjucation_status_change_reports_page';
import BillingReportsPage from './billing_report_page';
import KPIReportsPage from './kpi_report_page';
import BatchPendingRejectedListPage from './batch_pending_rejected_list_page';
import CheckNewClaimPage from './check_new_claims_page';
import CheckNewClaimsViewPage from './check_new_claims_view_page';

import {
    getBenefitSchemeItems,
    getBenefitScheme,
    getCfgCpfHospitalListOptions
} from '../../actions';
import { stat } from 'fs';
import { PERMISSION_PROCESS_ADJUDICATION, PERMISSION_PROCESS_BATCH, PERMISSION_VIEW_KPI_REPORTS } from '../../utils/constant_permission_list';
// import { stat, access } from 'fs';

class IndexPage extends Component {
    componentDidMount() {
        // kick user out if there is no user object - Unauthorized access
        if (!this.props.user) {
            this.props.history.push('/');
        } else {
            // check if all data is loaded?
            // to run all loads on config data before displaying UI.
            // claims.data , claimOptions.inpatientClaimOptions, claimOptions.outpatientClaimOptions , user.profilePhoto
            const { benefitSchemeItems, benefitScheme, cpfHospitalListOptions } = this.props;
            const { accessToken } = this.props.user;
            if (benefitSchemeItems || benefitSchemeItems.length === 0) {
                this.props.getBenefitSchemeItems({accessToken});
            }
            if (!benefitScheme) {
                this.props.getBenefitScheme({accessToken});
            }
            if (!cpfHospitalListOptions || cpfHospitalListOptions.length === 0) {
                this.props.getCfgCpfHospitalListOptions({accessToken});
            }
        }
    }
    render() {
        const { benefitSchemeItems, benefitScheme, cpfHospitalListOptions } = this.props;
        if (!benefitSchemeItems || !benefitScheme || !cpfHospitalListOptions || cpfHospitalListOptions.length === 0 || !benefitSchemeItems || benefitSchemeItems.length === 0) {
            return <Loader active />;
        }
        return (
            <div>
                <NavTopbar />
                <div style={{paddingTop: 50}}>
                    <Switch>
                        <Route exact path="/app" component={MainPage} />
                        <Route path="/app/database/claim/:claimId" component={RecordDatabaseClaimViewPage} />
                        <Route path="/app/database/case/:caseId" component={RecordDatabaseCaseViewPage} />
                        <Route path="/app/database" component={RecordDatabasePage} />
                        <Route path="/app/adjudicate/:caseId/:claimId" render={props =>  { return this.props.user.type === "ADJ" || this.props.user.permission.map(p => p).includes(PERMISSION_PROCESS_ADJUDICATION) ? <AdjudicateClaimPage {...props}/> : <MainPage/>}} />
                        <Route path="/app/adjudicate" render={props => { return this.props.user.type === "ADJ" || this.props.user.permission.map(p => p).includes(PERMISSION_PROCESS_ADJUDICATION) ? <AdjudicateRecordPage {...props}/> : <MainPage/>}} />
                        <Route path="/app/batchesupdatependingrejected" render={props => { return this.props.user.type === "ADJ" || this.props.user.permission.map(p => p).includes(PERMISSION_PROCESS_BATCH) ? <BatchPendingRejectedListPage {...props}/> : <MainPage/>}}/>
                        <Route path="/app/batches/:batchId" render={props => { return this.props.user.type === "ADJ" || this.props.user.permission.map(p => p).includes(PERMISSION_PROCESS_BATCH) ? <BatchViewPage {...props}/> : <MainPage/>}}/>
                        <Route path="/app/batches" render={props => { return this.props.user.type === "ADJ" || this.props.user.permission.map(p => p).includes(PERMISSION_PROCESS_BATCH) ? <BatchListPage {...props}/> : <MainPage/>}} />
                        <Route path="/app/adduser" component={AddUserPage} />
                        <Route path="/app/users" component={UserListPage} />
                        <Route path="/app/reports/statuschangereport" component={AdjucationStatusChangeReportsPage} />
                        <Route path="/app/reports/billingreport" component={BillingReportsPage} />
                        <Route path="/app/reports/kpireport" render={props => { return this.props.user.permission.map(p => p).includes(PERMISSION_VIEW_KPI_REPORTS) ? <KPIReportsPage {...props}/> : <MainPage/>}}/>
                        <Route path="/app/checknewclaims/claim/:claimId/:claimType" component={CheckNewClaimsViewPage} />
                        <Route path="/app/checknewclaims" component={CheckNewClaimPage} />
                    </Switch>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        benefitSchemeItems: state.config.benefitSchemeItems,
        benefitScheme : state.config.benefitScheme,
        cpfHospitalListOptions: state.config.cpfHospitalListOptions,
    };
}

export default connect(mapStateToProps, {
    getBenefitSchemeItems,
    getBenefitScheme,
    getCfgCpfHospitalListOptions,
})(IndexPage);