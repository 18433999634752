import React from 'react';
import { Segment, Grid, Message } from 'semantic-ui-react';

import FormsClaimsRejected from '../../../forms/claim/rejected';

const ClaimRejectedActionControl = (props) => {
    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Segment>
                        <Message negative>
                            <p>
                                The claim is going to be rejected and a rejection email will be sent to the claimant.
                            </p>
                        </Message>
                        <FormsClaimsRejected {...props} />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default ClaimRejectedActionControl;