import React from 'react';
import { Header, Segment, Table } from 'semantic-ui-react';
import Moment from 'moment';

const RecordClaimViewClaimDetailsOI = (props) => {
    const {
        claim_from_other,
        remarks,
        payment_mode,
        relationship_to_insured,
        payee_name,
        payee_nric,
        payee_dob,
        payee_gender,
        payee_country_birth,
        bank_name,
        bank_code,
        bank_branch,
        account_no,
    } = props.claimData;
    return (
        <div>
            <Header as='h4' attached="top">
                Other Information
            </Header>
            <Segment attached>
                <Table basic compact="very" fixed>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                Claim from Other Insurer
                            </Table.Cell>
                            <Table.Cell>
                                {(claim_from_other === '0') ? 'No' : 'Yes'}
                            </Table.Cell>
                            <Table.Cell>
                                Relationship to the Insured
                            </Table.Cell>
                            <Table.Cell>
                                {relationship_to_insured}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Payment Mode
                            </Table.Cell>
                            <Table.Cell>
                                {payment_mode}
                            </Table.Cell>
                            <Table.Cell>
                                Payee Nationality
                            </Table.Cell>
                            <Table.Cell>
                                {payee_country_birth}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Name of Payee
                            </Table.Cell>
                            <Table.Cell>
                                {payee_name}
                            </Table.Cell>
                            <Table.Cell>
                                Payee NRIC, FIN or Passport#
                            </Table.Cell>
                            <Table.Cell>
                                {payee_nric}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Payee Date of Birth
                            </Table.Cell>
                            <Table.Cell>
                                {Moment(payee_dob).format('DD/MM/YYYY')}
                            </Table.Cell>
                            <Table.Cell>
                                Payee Gender
                            </Table.Cell>
                            <Table.Cell>
                                {payee_gender}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Bank Name
                            </Table.Cell>
                            <Table.Cell>
                                {bank_name}
                            </Table.Cell>
                            <Table.Cell>
                                Bank Code
                            </Table.Cell>
                            <Table.Cell>
                                {bank_code}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Bank Branch
                            </Table.Cell>
                            <Table.Cell>
                                {bank_branch}
                            </Table.Cell>
                            <Table.Cell>
                                Account No
                            </Table.Cell>
                            <Table.Cell>
                                {account_no}
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
        </div>
    );
}

export default RecordClaimViewClaimDetailsOI;