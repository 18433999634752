import React from 'react';
import { Dropdown } from 'semantic-ui-react'
import { PERMISSION_VIEW_KPI_REPORTS } from '../../../utils/constant_permission_list';

const searchTypeOptions = [
    { text: 'Adjudication Status Change Report by Date Range', value: 1 },
    { text: 'Billing Report by Date Range', value: 2 }
    // { text: 'Fake Report Selection', value: 2 },
];

const ReportTypeSelection = (props) => {
    if(props.user.permission.map(p => p).includes(PERMISSION_VIEW_KPI_REPORTS)) {
        if(searchTypeOptions.filter(item => item.value === 3).length === 0) {
            searchTypeOptions.push({ text: 'KPI Report', value: 3 })
        }
    } else {
        if(searchTypeOptions.filter(item => item.value === 3).length > 0) {
            searchTypeOptions.pop();
        }
    }

    return (
        <Dropdown
            onChange={props.handleReportTypeChange}
            placeholder='Select Type Of Report To Generate'
            fluid
            selection
            options={searchTypeOptions}
            value={props.reportTypeId}
        />
    );
}

export default ReportTypeSelection;