import React from 'react';
import {
    Header,
    Segment,
    Table
} from 'semantic-ui-react';
import _ from 'lodash';

import RecordCaseViewClaimListItem from './case_claim_list_item';

const RecordCaseViewClaimList = (props) => {
    console.log('RecordCaseViewClaimList', props);
    return (
        <div>
            <Header as='h3' block attached="top">
                Related Claims ({props.claims.length})
            </Header>
            <Segment attached>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                Claim ID
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Status
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Endorsed
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Status Remarks
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            _.map(props.claims, (claim) => {
                                return (
                                    <RecordCaseViewClaimListItem key={claim.id} data={claim} />
                                );
                            })
                        }
                    </Table.Body>
                </Table>
            </Segment>
        </div>
    );
}

export default RecordCaseViewClaimList;