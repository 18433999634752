import React from 'react';
import { Container, Grid, Loader, Header, Segment } from 'semantic-ui-react';

import RecordCaseViewCaseDetailsPOI from './case_details_poi';
import RecordCaseViewCaseDetailsDOA from './case_details_doa';
// import RecordCaseViewCaseDetailsOI from './case_details_oi';

const RecordCaseViewCaseDetails = (props) => {
    return (
        <div>
            <Header as='h3' block attached="top">
                Case Details
            </Header>
            <Segment attached>
                <RecordCaseViewCaseDetailsPOI caseData={props.caseData} />
                <RecordCaseViewCaseDetailsDOA caseData={props.caseData} />
            </Segment>
        </div>
    );
}

export default RecordCaseViewCaseDetails;