import React from 'react';
import {Table} from 'semantic-ui-react';

const ClaimCasePanePOI = (props) => {
    const {
        name,
        nric,
        gender,
        dob,
        school,
        level,
        // class,
        house_no,
        road_name,
        unit_no,
        building_name,
        postcode,
        parent_email,
        parent_mobile_no,
        parent_home_no
    } = props.caseData;
    return (
        <Table basic compact="very" fixed>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        Insured Name
                    </Table.Cell>
                    <Table.Cell>
                        {name}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Insured NRIC, FIN or BNumber
                    </Table.Cell>
                    <Table.Cell>
                        {nric}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Gender
                    </Table.Cell>
                    <Table.Cell>
                        {gender}
                    </Table.Cell>
                    </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Insured DOB
                    </Table.Cell>
                    <Table.Cell>
                        {dob}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Name Of School/Centre
                    </Table.Cell>
                    <Table.Cell>
                        {school}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Level
                    </Table.Cell>
                    <Table.Cell>
                        {level}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Class
                    </Table.Cell>
                    <Table.Cell>
                        {props.caseData.class}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Block/House Number
                    </Table.Cell>
                    <Table.Cell>
                        {house_no}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Street Name
                    </Table.Cell>
                    <Table.Cell>
                        {road_name}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Unit Number
                    </Table.Cell>
                    <Table.Cell>
                        {unit_no}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Building Name
                    </Table.Cell>
                    <Table.Cell>
                        {building_name}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Postal Code
                    </Table.Cell>
                    <Table.Cell>
                        {postcode}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Email@
                    </Table.Cell>
                    <Table.Cell>
                        {parent_email}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Mobile#
                    </Table.Cell>
                    <Table.Cell>
                        {parent_mobile_no}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Home#
                    </Table.Cell>
                    <Table.Cell>
                        {parent_home_no}
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
}

export default ClaimCasePanePOI;